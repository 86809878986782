import { createStyles } from '@material-ui/core';

export default () => {
  const itemWidth = 20;

  return createStyles({
    root: {
      padding: '6px 18px',
      background: '#ffffff'
    },
    legendWrapper: {
      display: 'flex',
      paddingTop: 20
    },
    legendItem: {
      flexGrow: 1,
      height: 30,
      width: itemWidth,
      position: 'relative'
    },
    value: {
      fontSize: '0.7em',
      position: 'absolute',
      top: '-1.6em',
      width: itemWidth,
      textAlign: 'center'
    },
    minValue: {
      left: -1 * itemWidth / 2
    },
    maxValue: {
      right: -1 * itemWidth / 2
    }
  });
};
