import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  socialMediaContainer: {
    width: 500,
    padding: theme.spacing(6, 2),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  socialMediaItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  socialMediaItemHeader: {
    marginTop: theme.spacing(1)
  },
  copyLink: {
    background: theme.palette.grey[300]
  }
});
