import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3)
  },
  search: {
    marginBottom: theme.spacing(2)
  },
  filter: {
    marginTop: theme.spacing(3)
  },
  facetLabel: {
    marginBottom: theme.spacing(1)
  }
});
