import React, { Component, SyntheticEvent } from 'react';
import { Box, IconButton, WithStyles } from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import classNames from 'classnames';

export interface IProps extends WithStyles<typeof styles> {
}

interface IState {
  expanded: boolean;
}

class ControlsWrapper extends Component<IProps, IState> {
  state: IState = {
    expanded: false
  }

  onExpand = (ev: SyntheticEvent) => {
    ev.preventDefault();

    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    const { classes, children } = this.props;
    const { expanded } = this.state;

    return (
      <Box className={classes.root}>
        <Box className={classes.showMoreWrapper} onClick={this.onExpand}>
          {
            expanded ? 'Hide options' : 'Show more options'
          }

          <IconButton className={classes.expansionIcon}>
            {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          </IconButton>
        </Box>

        <Box className={classNames(classes.expansionWrapper, expanded && classes.expanded)}>
          <Box className={classes.content}>
            {children}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default decorate(ControlsWrapper);
