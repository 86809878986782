import * as StoreIndex from '../utils/storeIndex';
import { TAppState } from '../store';
import { TGroupSubState } from '../reducers/groups';

const selectGroup = (state: TAppState, packageName: string, groupName: string): TGroupSubState | undefined => {
  const key = StoreIndex.group(packageName, groupName);

  return state.groups[key];
};

export {
  selectGroup
};
