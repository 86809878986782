import { withStyles } from '@material-ui/core';
import { ComponentType } from 'react';
import { IProps } from './index';
import styles from './styles';
import { connect } from 'react-redux';
import { displayFlashMessage } from '../../../../../actions/flashMessage';
import { addRecentLocation } from '../../../../../actions/recentLocations';


export const mapDispatchToProps = {
  displayFlashMessage,
  addRecentLocation
};

export default (component: ComponentType<IProps>) =>
  connect(null, mapDispatchToProps)(
    withStyles(styles)(component)
  );

