import { createStyles } from '@material-ui/core';

export default () => createStyles({
  root: {
    padding: '6px 18px',
    background: '#ffffff'
  },
  legendWrapper: {
    display: 'flex',
    paddingTop: 8
  },
  labelsWrapper: {
    position: 'relative',
    height: '1.2em',
    width: '100%'
  },
  gradient: {
    height: 30,
    width: '100%'
  },
  value: {
    fontSize: '0.7em',
    position: 'absolute',
    transform: 'translateX(-50%)'
  }
});
