import { ComponentType } from 'react';
import { IProps } from './index';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { addProductToCollection, removeProductFromCollection } from '../../../actions/productCollection';

export const mapDispatchToProps = {
  addProductToCollection,
  removeProductFromCollection
};

export default (component: ComponentType<IProps>) =>
  flow(
    connect(null, mapDispatchToProps),
    withStyles(styles)
  )(component);
