import { createStyles, Theme, fade } from '@material-ui/core';

export default ((theme: Theme) => {
  const bgColor = fade(theme.palette.secondary.main, 0.1);

  return createStyles({
    root: {
      '&$selected > $content': {
        color: theme.palette.secondary.main,
        backgroundColor: bgColor,

        '& > $label': {
          background: 'none'
        }
      },
      '&$selected > $content $labelIcon': {
        color: 'inherit'
      }
    },
    selected: {},
    expanded: {},
    content: {
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.25),
      paddingTop: theme.spacing(0.25),
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium
    },
    group: {
      marginLeft: theme.spacing(1)
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
      background: 'none !important',
      '&:hover': {
        color: theme.palette.secondary.main,

        '&:focus $labelIcon, &:hover $labelIcon': {
          color: theme.palette.secondary.main
        }
      }
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
      opacity: 0.6
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1
    }
  });
});
