import React, { ChangeEvent, Component, Fragment } from 'react';
import { Backdrop, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { PlayerDimensionDataItem } from '../Player';
import Api from '../../api';
import { DimensionSelectionMapping } from '../../containers/Product';
import decorate from './decorator';
import { isEqual } from 'lodash';
import ProductFeatureContent from './ProductFeatureContent';
import Epsgram from './Epsgram';
import { Nullable } from '../../utils/types';
import { TFeatureLocation, TFeatureLocationLatLon } from '../../api/feature';
import ProductFeatureDialog from './ProductFeatureDialog';

export type TProductFeature = {
  value: string;
  url: string;
  text: string;
}

export interface IProductFeatureSpec {
  item: PlayerDimensionDataItem;
  x: number;
  y: number;
  productValues: DimensionSelectionMapping
}

export interface IProps {
  packageName: string;
  productName: string;
  spec: Nullable<IProductFeatureSpec>;
  onClose: () => void;
  classes: {
    backdrop: string;
    dialogTitle: string;
    dialogContent: string;
    loadingWrapper: string;
    closeButton: string;
  }
}

interface IState {
  feature: Nullable<TProductFeature>;
  customLocation: Nullable<TFeatureLocation>;
  isLoading: boolean;
  error: boolean;
}

class ProductFeature extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      feature: null,
      customLocation: null,
      isLoading: false,
      error: false
    };
  }

  loadFeature(featureValue?: string) {
    const { packageName, productName, spec } = this.props;
    const { customLocation } = this.state;

    if (!spec) {
      return Promise.reject();
    }

    const { x, y, item, productValues } = spec;

    const { base_time, valid_time, expver } = productValues;

    if (!item?.click) {
      return Promise.reject('Missing data in order to load the feature.');
    }

    const axis = item.click.axis[0];
    const featureName = axis.name;
    const value = featureValue ?? axis.values[0].value;

    const location = customLocation ?? {
      type: 'projection' as const,
      x,
      y,
      crs: item.click.navigation.crs
    };

    return Api.Feature.fetch(featureName, {
      packageName,
      productName,
      location: location,
      [featureName]: value,
      axis: productValues,
      ...(valid_time && { valid_time }),
      ...(base_time && { base_time }),
      ...(expver && { expver })
    }).then(response => {
      return {
        ...response.result,
        value
      };
    });
  }

  onFeatureChange = (event: ChangeEvent<unknown>) => {
    const { value } = event.target as HTMLSelectElement;
    const { feature } = this.state;

    this.setState({
      feature: {
        ...feature!,
        value
      }
    });
  }

  onCustomLocationChange = (location: TFeatureLocationLatLon) => {
    this.setState({
      customLocation: location
    });
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { spec } = this.props;
    const { feature, customLocation } = this.state;

    if (!isEqual(prevProps.spec, spec)) {
      if (!spec) {
        this.setState({
          feature: null,
          error: false,
          customLocation: null
        });
      } else {
        this.loadFeature().then(feature => {
          this.setState({
            feature,
            error: false
          });
        }).catch(() => {
          this.setState({
            isLoading: false,
            error: true
          });
        });
      }
    }

    if (
      prevState.feature &&
      feature &&
      (
        (prevState.feature.value !== feature.value) ||
        !isEqual(prevState.customLocation, customLocation)
      )
    ) {
      this.setState({
        isLoading: true
      }, () => {
        this.loadFeature(feature.value).then(feature => {
          this.setState({
            feature,
            isLoading: false,
            error: false
          });
        }).catch(() => {
          this.setState({
            isLoading: false,
            error: true
          });
        });
      });
    }
  }

  renderFeatureSelect() {
    const { spec } = this.props;
    const { feature } = this.state;
    const axis = spec?.item?.click?.axis[0];

    if (!axis || !feature) {
      return null;
    }


    return (
      <FormControl>
        <InputLabel id="feature-select">Product</InputLabel>
        <Select
          labelId="feature-select"
          value={feature.value}
          onChange={this.onFeatureChange}
        >
          {
            axis.values.map(feature => <MenuItem key={feature.value} value={feature.value}>{feature.label}</MenuItem>)
          }
        </Select>
      </FormControl>
    );
  }

  renderLoading() {
    const { classes } = this.props;

    return (
      <Box className={classes.loadingWrapper}>
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  renderFeatureContent() {
    const { spec } = this.props;
    const { feature, error } = this.state;
    const widget = spec?.item?.click?.config.widget;

    if (!feature) {
      return null;
    }

    switch (widget) {
      case 'epsgrams':
        return <Epsgram
          feature={feature}
          error={error}
          controls={this.renderFeatureSelect()}
          onLocationChange={this.onCustomLocationChange}
        />;
      default:
        return <ProductFeatureContent
          feature={feature}
          controls={this.renderFeatureSelect()}
          error={error}
        />;
    }
  }

  renderTitle() {
    const { spec } = this.props;

    return spec?.item?.click?.config.title ?? '';
  }

  render() {
    const { spec, onClose, classes } = this.props;
    const { feature, isLoading } = this.state;
    const isOpen = Boolean(feature);

    const isFeatureLoading = !feature && Boolean(spec);

    return (
      <Fragment>
        <Backdrop open={isFeatureLoading} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ProductFeatureDialog
          title={this.renderTitle()}
          open={isOpen}
          loading={isLoading}
          onClose={onClose}
        >
          {
            feature &&
            this.renderFeatureContent()
          }
        </ProductFeatureDialog>
      </Fragment>
    );
  }
}


export default decorate(ProductFeature);
