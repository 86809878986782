import { TProductDetails } from '../../api/product';
import { Nullable } from '../types';
import config from '../../config';
import paths from '../../router/paths';

type BreadcrumbPathType = 'package' | 'product' | 'chart-set' | 'overview';

function buildPaths(type: Omit<BreadcrumbPathType, 'package'>, resource: TProductDetails) {
  const p: Array<Array<Nullable<string>>> = [];

  if (config.appHomeLink) {
    if (config.rootPackageId) {
      p.push([resource.package.title, paths.packageDetails(resource.package.name)]);
    } else {
      p.push(['Packages', paths.root]);
      p.push([resource.package.title, paths.packageDetails(resource.package.name)]);
    }
  } else {
    if (!config.rootPackageId) {
      p.push([resource.package.title, paths.packageDetails(resource.package.name)]);
    }
  }

  if (type === 'product') {
    p.push([resource.title, null]);
  }

  if (type === 'chart-set') {
    p.push([resource.package.title, paths.packageDetails(resource.package.name)]);
    p.push(['ChartSet', null]);
  }

  if (type === 'overview') {
    p.push([resource.title, paths.productDetails(resource.package.name, resource.name)]);
    p.push(['Overview', null]);
  }

  return Object.fromEntries(p);
}


const BreadcrumbHelpers = {
  buildPaths
};

export default BreadcrumbHelpers;
