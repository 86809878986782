import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

interface IProps extends SvgIconProps {
}

const GridViewIcon = (props: IProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <rect height="8" width="8" x="3" y="3" />
        <rect height="8" width="8" x="3" y="13" />
        <rect height="8" width="8" x="13" y="3" />
        <rect height="8" width="8" x="13" y="13" />
      </g>
    </SvgIcon>
  );
};

export default GridViewIcon;
