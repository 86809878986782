import {
  ISettingSetCompleteAction,
  SETTING_SET_COMPLETED
} from '../actions/settings';

export type TSettingId = 'PRESET_GRID_SIZE' | 'OVERVIEW_GRID_SIZE';
export type TSettingValue<T = string | number> = T;

interface ISettingsState extends Record<TSettingId, TSettingValue> {}

type TAllowedActions = ISettingSetCompleteAction;

const updateSetting = (state: ISettingsState, setting: TSettingId, value: TSettingValue) => {
  return {
    ...state,
    [setting]: value
  };
};

const initialState = {
  PRESET_GRID_SIZE: 3,
  OVERVIEW_GRID_SIZE: 3
};

export default (state: ISettingsState = initialState, action: TAllowedActions) => {
  switch (action.type) {
    case SETTING_SET_COMPLETED:
      return updateSetting(state, action.setting, action.value);

    default:
      return state;
  }
};
