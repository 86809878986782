import {
  Box,
  Button,
  Checkbox, Chip,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { ChangeEvent, Fragment, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { DimensionMultipleChoice } from '../../../index';
import { compact } from 'lodash';
import classNames from 'classnames';
import InputValueRenderer from "./ItemValueRenderer";

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.divider}`;

  return createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    list: {
      paddingBottom: 0,
      paddingTop: 4
    },
    listItem: {
      paddingLeft: 4,
      paddingRight: 4
    },
    listItemSelected: {
      '&.Mui-selected': {
        backgroundColor: 'transparent'
      }
    },
    checkbox: {
      padding: '4px 8px'
    },
    actionsMenuItem: {
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: 4,
      position: 'sticky',
      bottom: 0,
      left: 0,
      width: '100%',
      background: 'white',
      zIndex: theme.zIndex.tooltip + 100
    },
    actionsMenuItemWrapper: {
      width: '100%',
      background: theme.palette.background.paper,
      borderTop: border,
      display: 'flex',
      flexDirection: 'column'
    },
    actionButton: {
      borderRadius: 0
    },
    smallActionButton: {
      width: '50%'
    },
    smallActionButtonsWrapper: {
      width: '100%',
      borderTop: border,
      '& > *': {
        borderLeft: border,

        '&:first:child': {
          borderLeft: 'none'
        }
      }
    }
  });
});

interface Props {
  dimension: DimensionMultipleChoice;
  onChange: (dimension: DimensionMultipleChoice, values: Array<string>) => void;
  values: Array<string>;
  orientation: 'horizontal' | 'vertical'
}

const validate = (values: Array<string>) => {
  return values.length > 0;
};



const MultipleChoiceDimensionControl = (props: Props) => {
  const rootRef = useRef(null);
  const { dimension, values, onChange, orientation } = props;
  const [checked, setChecked] = useState<Array<string>>(values);
  const [isValid, setIsValid] = useState<boolean>(validate(values));
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(dimension.values.length === values.length);

  const classes = useStyles();

  const onSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    const values = event.target.value as Array<string>;

    setChecked(compact(values));
  };

  const onSelectClose = () => {
    setChecked(values);
    setMenuOpen(false);
  };

  const onSelectOpen = () => {
    setMenuOpen(true);
  };

  const onCancel = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setMenuOpen(false);
    setChecked(values);
  };

  const onApply = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onChange(dimension, checked);
    setMenuOpen(false);
  };

  const onSelectAll = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setAllSelected(true);
    setChecked(dimension.values.map(option => option.value));
  };

  const onUnselectAll = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setAllSelected(false);
    setChecked([]);
  };

  useEffect(() => {
    setIsValid(validate(checked));
  }, [checked]);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }} ref={rootRef}>
      <FormControl fullWidth>
        <InputLabel id="demo-mutiple-checkbox-label">{dimension.label}</InputLabel>
        <Select
          labelId={`multiple-choice-dimension-${dimension.name}`}
          id={`multiple-choice-dimension-${dimension.name}`}
          multiple
          value={checked}
          open={menuOpen}
          onOpen={onSelectOpen}
          onChange={onSelectChange}
          onClose={onSelectClose}
          input={<Input />}
          renderValue={() => {
            return <InputValueRenderer values={values} dimension={dimension} orientation={orientation} />;
          }}
          MenuProps={{
            classes: {
              list: classes.list
            },
            container: rootRef.current
          }}
        >
          {
            dimension.values.map((option) => (
              <MenuItem
                dense
                key={option.value}
                value={option.value}
                classes={{
                  root: classes.listItem,
                  selected: classes.listItemSelected
                }}
              >

                <Checkbox checked={checked.includes(option.value)} className={classes.checkbox} />
                <ListItemText
                  primary={option.label}
                  secondary={option.status === "disabled" ? "Not available" : ""}
                  primaryTypographyProps={option.status === "disabled" ? { color: "textSecondary", style: {textDecoration: "line-through"}} : {}}
                />
              </MenuItem>
            ))
          }

          <MenuItem
            dense
            disableGutters
            disableRipple
            className={classes.actionsMenuItem}
          >
            <Box className={classes.actionsMenuItemWrapper}>
              <Box>
                {
                  allSelected ?
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={onUnselectAll}
                      className={classes.actionButton}
                    >
                      Unselect all
                    </Button>
                    :
                    <Button
                      fullWidth
                      color="secondary"
                      onClick={onSelectAll}
                      className={classes.actionButton}
                    >
                      Select all
                    </Button>
                }
              </Box>

              <Box className={classes.smallActionButtonsWrapper}>
                <Button
                  className={classNames(classes.actionButton, classes.smallActionButton)}
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <Button
                  className={classNames(classes.actionButton, classes.smallActionButton)}
                  color="secondary"
                  onClick={onApply}
                  disabled={!isValid}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleChoiceDimensionControl;
