import client from './client';

export type TCycloneRegionOption = {
  value: string;
  label: string;
}

export type TCycloneYearOption = {
  value: string;
  label: string;
}

export type TCycloneOption = {
  value: string;
  label: string;
}

export type TCycloneFilterOptions = {
  regions: Array<TCycloneRegionOption>;
  years: Array<TCycloneYearOption>;
}

export type TCycloneSearchParams = {
  unique_id?: string;
  year?: TCycloneYearOption['value'];
  region?: TCycloneRegionOption['value']
}

export type TCycloneSearchResult = {
  cyclones: Array<TCycloneOption>;
}

const filterOptions = (): Promise<TCycloneFilterOptions> => {
  return client.get('/cyclones/filter_options/')
    .then(response => response.data);
};

const search = (params: TCycloneSearchParams): Promise<TCycloneSearchResult> => {
  return client.get('/cyclones/search/', { params })
    .then(response => response.data);
};

export {
  filterOptions,
  search
};
