import SearchInput from '../../../../components/SearchInput';
import React, { ChangeEvent } from 'react';
import { includes, map } from 'lodash';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  WithStyles,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle, Button, DialogActions
} from '@material-ui/core';
import { IPackageFiltersForm } from '../index';
import FilterListIcon from '@material-ui/icons/FilterList';
import decorate from './decorator';
import styles from './styles';

export interface IProps extends IPackageFiltersForm, WithStyles<typeof styles> {
}

interface IState {
  filtersOpen: boolean;
}

class FiltersSmallScreen extends React.Component<IProps, IState> {
  state = {
    filtersOpen: false
  }

  handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  };

  handleSearchValueReset = () => {
    this.props.onChange('');
  };

  handleFacetChange = (facet: string, value: string) => (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onFacetChange(facet, value, event.target.checked);
  };

  handleFiltersTrigger = (open: boolean) => () => {
    this.setState({
      filtersOpen: open
    });
  }

  render() {
    const { searchValue, facets, selectedFacets, classes } = this.props;
    const { filtersOpen } = this.state;

    return (
      <Box className={classes.root}>
        <SearchInput
          name="search"
          value={searchValue}
          onChange={this.handleSearchValueChange}
          onReset={this.handleSearchValueReset}
          placeholder="Search products..."
          fullWidth
          classes={{
            root: classes.search
          }}
        />

        <IconButton onClick={this.handleFiltersTrigger(!filtersOpen)}>
          <FilterListIcon />
        </IconButton>

        <Dialog open={filtersOpen} onClose={this.handleFiltersTrigger(false)}>
          <DialogTitle>
            Filter products
          </DialogTitle>
          <DialogContent>
            {
              map(facets, (values, facetKey) => {
                return (
                  <FormGroup key={facetKey} className={classes.filter}>
                    <FormLabel component="legend" className={classes.facetLabel}>{facetKey}</FormLabel>

                    <FormGroup className={classes.formGroup}>
                      {
                        map(values, value => {
                          const isSelected = includes(selectedFacets[facetKey], value);

                          return (
                            <FormControlLabel
                              className={classes.checkboxLabel}
                              key={value}
                              control={
                                <Checkbox
                                  checked={isSelected}
                                  onChange={this.handleFacetChange(facetKey, value)}
                                  value={value}
                                />
                              }
                              label={value}
                            />
                          );
                        })
                      }
                    </FormGroup>
                  </FormGroup>
                );
              })
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleFiltersTrigger(false)} fullWidth color="secondary">Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default decorate(FiltersSmallScreen);
