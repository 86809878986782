import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import decorate from './decorator';
import { TLegendJSONGradient } from '../../../../../api/axis';
import LegendTitle from '../../LegendTitle';

export interface IProps {
  item: TLegendJSONGradient,
  classes: {
    root: string;
    legendWrapper: string;
    labelsWrapper: string;
    gradient: string;
    value: string;
  }
}

interface IGradientSpecItem {
  value: number;
  percentage: number;
  colour: string;
}

type TGradientSpec = Array<IGradientSpecItem>

const parsePercentage = (value: number, min: number, max: number) => {
  const span = max - min;

  return (value - min) / span * 100;
};

const deriveGradientSpecFromLegend = (legend: TLegendJSONGradient) => {
  const entries = legend.data.entries;
  const min = parseInt(entries[0]['min-range'], 10);
  const max = parseInt(entries[entries.length - 1]['max-range'], 10);

  return legend.data.entries.reduce((acc: Array<{ value: number, colour: string, percentage: number }>, entry) => {
    const minRange = entry['min-range'];
    const maxRange = entry['max-range'];

    if (minRange || maxRange) {
      const value = parseInt(minRange || maxRange, 10);
      const percentage = parsePercentage(value, min, max);

      acc.push({
        value,
        percentage,
        colour: entry['colour']
      });
    }

    return acc;
  }, []);
};

const buildGradientStyle = (gradientSpec: TGradientSpec) => {
  const colours = gradientSpec.map(point => `${point.colour} ${point.percentage}%`);

  return `linear-gradient(90deg, ${colours.join(', ')})`;
};

class LegendJSONGradient extends Component<IProps> {
  render() {
    const { item, classes } = this.props;

    const spec = deriveGradientSpecFromLegend(item);
    const gradient = buildGradientStyle(spec);

    return (
      <Box className={classes.root}>
        <LegendTitle item={item} />

        <Box className={classes.legendWrapper} flexDirection="column">
          <div className={classes.labelsWrapper}>
            {
              spec.map(point =>
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.value}
                  style={{ left: `${point.percentage}%` }}
                >
                  {point.value}
                </Typography>
              )
            }
          </div>
          <div className={classes.gradient} style={{ background: gradient }} />
        </Box>
      </Box>
    );
  }
}

export default decorate(LegendJSONGradient);
