import client from './client';
import { DimensionSelectionMapping } from '../containers/Product';

interface IFeatureFetchResponse {
  result: {
    url: string;
    text: string;
  };
  feature: string;
}

export type TFeatureLocationProjection = {
  type: 'projection';
  x: number;
  y: number;
  crs: string;
};

export type TFeatureLocationLatLon = {
  type: 'latlon';
  lat: number;
  lon: number;
  station_name: string;
}

export type TFeatureLocation = TFeatureLocationProjection | TFeatureLocationLatLon;

type TFeatureFetchParams = {
  packageName: string;
  productName: string;
  location: TFeatureLocation;
  base_time?: number | string;
  valid_time?: number | string;
  expver?: number | string;
  axis: DimensionSelectionMapping
}

const fetch = (
  featureName: string,
  params: TFeatureFetchParams
): Promise<IFeatureFetchResponse> => {
  return client.post(`/features/${featureName}/`, {
    ...params
  })
    .then(response => response.data);
};

export {
  fetch
};
