import { flow } from 'lodash';
import { ComponentType } from 'react';
import * as ReactCookie from 'react-cookie';
import { connect } from 'react-redux';
import { fetchAnnouncements } from '../../actions/announcement';
import { TAppState } from "../../store";
import { Props } from "./index";

const mapStateToProps = (state: TAppState) => {
  const announcements = state.announcements.items;

  return {
    announcements
  };
};

const mapDispatchToProps = {
  fetchAnnouncements
};

export default (component: ComponentType<Props>) => flow(
  connect(mapStateToProps, mapDispatchToProps),
  ReactCookie.withCookies
)(component);
