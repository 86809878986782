import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  search: {
    flexGrow: 1
  },
  filter: {
    marginTop: theme.spacing(3)
  },
  formGroup: {
    [theme.breakpoints.up('sm')]: {
      columnCount: 2,
      display: 'block'
    }
  },
  facetLabel: {
    marginBottom: theme.spacing(1)
  },
  checkboxLabel: {
    display: 'block'
  }
});
