import client from './client';
import { DimensionType } from '../containers/Product';
import { Nullable } from '../utils/types';

type TLineStyles = 'solid' | 'dash' | 'dot';

export interface ILegend<Format, Data> {
  format: Format;
  data: Data
}

export type TLegendJSON = TLegendJSONColorbar

export type TLegendJSONColorbar = ILegend<'json', {
  units: string;
  type: 'colorbar',
  title: string;
  entries: Array<{
    colour: string;
    'min-range': string;
    'max-range': string;
  }>
}>

export type TLegendJSONGradient = ILegend<'json', {
  units: string;
  type: 'gradients',
  title: string;
  entries: Array<{
    colour: string;
    'min-range': string;
    'max-range': string;
  }>
}>

export type TLegendJSONDoubleLine = ILegend<'json', {
  units: string;
  type: 'double-line',
  title: string;
  entries: Array<{
    text: string;
    line1_thickness: string;
    line1_colour: string;
    line1_style: TLineStyles;
    line2_thickness: string;
    line2_colour: string;
    line2_style: TLineStyles;
  }>
}>

export type TLegendJSONArrow = ILegend<'json', {
  units: string;
  type: 'arrow',
  title: string;
  entries: Array<{
    text: string;
    colour: string;
  }>
}>

export type TLegendImage = ILegend<'image', {
  url: string;
  title: string;
}>

export type TLegend = TLegendImage | TLegendJSON;

export type TAxisValue = {
  value: string,
  label: string,
  animation?: boolean;
  linked_axis?: string,
  linked_values?: Array<{
    value: string,
    label: string
  }>
}

export type TAxis<T> = T & {
  name: string;
  title: string;
  dynamic?: boolean;
}

export type TLocationAxis = TAxis<{
  type: DimensionType.LOCATION;
  latitude: {
    name: 'lat';
    title: string;
  },
  longitude: {
    name: 'lon';
    title: string;
  },
  altitude: {
    name: 'height';
    title: string;
  }
}>

export type TChoiceAxis = TAxis<{
  type: DimensionType.CHOICE,
  animation: boolean,
  linked_axis?: string,
  linked_axis_title?: string,
  values: Array<TAxisValue>
}>

export type CycloneTargetArea = [number, number, number, number];
export type Cyclone = {
  unique_id: string;
  name: string;
  lat: number;
  lon: number;
}

export type CycloneDimensionOverview = {
  target_areas: Array<CycloneTargetArea>;
  cyclones: Array<Cyclone>
}

export type TCycloneAxis = TAxis<{
  type: DimensionType.CYCLONE;
  values: Array<TAxisValue>;
  overview: CycloneDimensionOverview
}>

export type TMultipleChoiceAxis = TAxis<{
  type: DimensionType.MULTIPLE_CHOICE;
  values: Array<{
    value: string;
    label: string;
    status: "disabled" | "enabled";
  }>;
}>

export type TProductAxis = TChoiceAxis | TLocationAxis | TCycloneAxis | TMultipleChoiceAxis;

export interface IAxisFetchResponseResult {
  url: string | null;
  value: string | number;
  label: string | number;
  legend: Array<TLegendImage | TLegendJSON>;
  info?: string;
  error?: string;
  click?: {
    url: string;
    tooltip: string;
    config: {
      widget: Nullable<'epsgrams'>;
      tooltip: string;
      title: string;
    };
    navigation: {
      crs: string;
      xmin: number;
      ymin: number;
      width: number;
      height: number;
    };
    axis: Array<{
      values: Array<{
        value: string;
        label: string;
      }>
      name: string;
      title: string;
    }>
  };
}

export interface IAxisFetchResponse {
  name: string;
  package: string;
  results: { [value: string]: IAxisFetchResponseResult };
  requested_axis: string;
  values: {
    [param: string]: string | number;
  };
  uid: string;
}

const fetch = (
  packageName: string,
  productName: string,
  axisParamName: string,
  params: { [param: string]: string | number }
): Promise<IAxisFetchResponse> => {
  return client.get(`/packages/${packageName}/products/${productName}/axis/${axisParamName}/`, {
    params
  })
    .then(response => response.data);
};

const preload = (
  packageName: string,
  productName: string,
  axisParamName: string,
  params: { [param: string]: string | number }
): Promise<void> => {
  return client.get(`/packages/${packageName}/products/${productName}/preload/${axisParamName}/`, {
    params
  })
    .then(response => response.data);
};

const list = (
  packageName: string,
  productName: string,
  params: { [param: string]: string | number }
): Promise<Array<TProductAxis>> => {
  return client.get(`/packages/${packageName}/products/${productName}/axis/`, {
    params
  })
    .then(response => response.data).then(response => response.axis);
};

export {
  fetch,
  list,
  preload
};


