import { withStyles } from '@material-ui/core';
import styles from './styles';
import { ComponentType } from 'react';
import { IProps } from './index';
import { connect } from 'react-redux';
import { flow, intersectionWith } from 'lodash';
import { TAppState } from '../../../store';
import { clearProductCollection } from '../../../actions/productCollection';

const mapStateToProps = (state: TAppState, props: IProps) => {
  const { packageName } = props;
  const selectedProducts = state.productCollectionsByPackage[packageName];
  const allProducts = state.productsByPackage[packageName]?.data?.products ?? [];

  const products = intersectionWith(allProducts, selectedProducts, (a, b) => a.name === b);

  return {
    products
  };
};

const mapDispatchToProps = {
  clearProductCollection
};

export default (component: ComponentType<IProps>):
  ComponentType<Omit<IProps, 'classes' | 'clearProductCollection' | 'products'>> =>
  flow(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(component);
