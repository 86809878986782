import PageWrapper from '../../components/PageWrapper';
import React, { Component, Fragment } from 'react';
import decorate, { mapDispatchToProps } from './decorator';
import { TProductDetails } from '../../api/product';
import PageLoading from '../../components/PageLoading';
import { Box, Grid, GridSize } from '@material-ui/core';
import { RouteChildrenProps } from 'react-router';
import { Dimension, DimensionSelectionMapping } from '../Product';
import DimensionSelectorForm from '../Product/DimensionSelector/DimensionSelectorForm';
import updateUrlSearchParams from '../../utils/updateUrlSearchParams';
import { TGroup } from '../../api/group';
import { isEqual, noop } from 'lodash';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import styles from './styles';
import Toolbar from '../../components/Toolbar';
import GridSelect from '../../components/GridSelect';
import Breadcrumbs from '../../components/Breadcrumbs';
import ChartSetItem from './ChartSetItem';
import PageError from '../../components/PageError';
import BreadcrumbHelpers from '../../utils/BreadcrumbHelpers';

export interface IProps
  extends ReturnType<typeof mapDispatchToProps>,
    WithStyles<typeof styles>,
    RouteChildrenProps<{ packageName: string, groupName: string }> {
  packageName: string;
  groupName: string;
  group: TGroup | null;
  groupError: string | null;
  productsToFetch: Array<{ name: string, package: string }>;
  products: Array<TProductDetails>;
  commonDimensions: Array<Dimension>;
  specificDimensions: Array<Dimension>;
  selectedDimensions: DimensionSelectionMapping;
  gridSize: GridSize
}

interface IState {
  loadedProducts: boolean
}

class ChartSet extends Component<IProps, IState> {
  state = {
    loadedProducts: false
  }

  onAxisChange = (values: DimensionSelectionMapping) => {
    updateUrlSearchParams(values, 'replace');
  }

  componentDidMount() {
    const { fetchGroup, packageName, groupName } = this.props;

    fetchGroup(packageName, groupName).catch(noop);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { group, fetchProduct, productsToFetch } = this.props;

    if (!isEqual(prevProps.group, group)) {
      const promises = productsToFetch.map((product) => fetchProduct(product.package, product.name));

      Promise.all(promises).then(() => {
        this.setState({ loadedProducts: true });
      });
    }
  }

  renderBreadcrumbs() {
    const { products } = this.props;

    if (products.length === 0) {
      return;
    }

    return (
      <Breadcrumbs paths={BreadcrumbHelpers.buildPaths('chart-set', products[0])} />
    );
  }

  renderDimensionSelector() {
    const { commonDimensions, specificDimensions, selectedDimensions, classes } = this.props;

    return (
      <Box display="flex" style={{ width: '100%' }}>
        <DimensionSelectorForm
          hideRecentLocations
          locationControlVariant="compact"
          dimensions={commonDimensions}
          selectedDimensions={selectedDimensions}
          onChange={this.onAxisChange}
          orientation="horizontal"
          classes={{
            root: classes.formRoot
          }}
        />

        {
          Boolean(specificDimensions.length) &&
          <DimensionSelectorForm
            dimensions={specificDimensions}
            selectedDimensions={selectedDimensions}
            onChange={this.onAxisChange}
            orientation="horizontal"
            classes={{
              root: classes.formRoot
            }}
          />
        }
      </Box>
    );
  }

  renderProduct(product: TProductDetails) {
    const { commonDimensions, selectedDimensions, gridSize } = this.props;

    return (
      <Grid item xs={12} sm={6} md={4} lg={gridSize} key={product.name}>
        <ChartSetItem
          product={product}
          selectedDimensions={selectedDimensions}
          commonDimensions={commonDimensions}
        />
      </Grid>
    );
  }

  render() {
    const { group, groupError, products, classes } = this.props;
    const { loadedProducts } = this.state;

    if (!group) {
      return <PageLoading />;
    }

    if (groupError) {
      return <PageError title="We're sorry. Chartset is currently unavailable." />;
    }

    if (!loadedProducts) {
      return <PageLoading />;
    }

    return (
      <Fragment>
        <PageWrapper classes={{
          root: classes.content
        }}>
          <Grid item>
            {this.renderBreadcrumbs()}
          </Grid>

          <Grid container spacing={2}>
            {
              products.map(product => this.renderProduct(product))
            }
          </Grid>
        </PageWrapper>

        <Toolbar>
          <Box display="flex" justifyContent="space-between">
            {
              this.renderDimensionSelector()
            }

            <GridSelect settingId="PRESET_GRID_SIZE" />
          </Box>
        </Toolbar>
      </Fragment>
    );
  }
}

export default decorate(ChartSet);
