import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import CycloneSearch from './CycloneSearch';
import SearchIcon from '@material-ui/icons/Search';
import PublicIcon from '@material-ui/icons/Public';
import { DimensionCyclone } from '../../../index';
import { TCycloneOption } from '../../../../../api/cyclone';
import Api from '../../../../../api';
import { useSelector } from 'react-redux';
import { TAppState } from '../../../../../store';
import * as StoreIndex from '../../../../../utils/storeIndex';
import { addRecentCyclone } from '../../../../../actions/recentCyclones';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import DialogTitle from '../../../../../components/DialogTitle';
import RecentCycloneSelect from './RecentCycloneSelect';

interface IProps {
  packageName?: string;
  productName?: string;
  dimension: DimensionCyclone;
  value: string;
  onChange: (dimension: DimensionCyclone, value: string) => void;
}

const CycloneDimensionControl = (props: IProps) => {
  const { value, dimension, onChange, packageName, productName } = props;
  const [searchDialogOpen, setSearchDialogOpen] = useState<boolean>(false);
  const [recentCycloneDialogOpen, setRecentCycloneDialogOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const recentSearches = useSelector((state: TAppState) => {
    if (!packageName || !productName) {
      return [];
    }

    return state.recentCyclonesByProduct[StoreIndex.recentCyclone(packageName, productName)] ?? [];
  });

  const onSearchClick = () => {
    setSearchDialogOpen(true);
  };

  const onMapButtonClick = () => {
    setRecentCycloneDialogOpen(true);
  };

  const onSearchChange = (cyclone: TCycloneOption) => {
    if (packageName && productName) {
      dispatch(addRecentCyclone(packageName, productName, cyclone));
    }
    onChange(dimension, cyclone.value);
    setSearchDialogOpen(false);
  };

  const onSelectChange = (event: ChangeEvent<unknown>) => {
    const { value } = event.target as HTMLSelectElement;

    onChange(dimension, value);
  };

  const onOverviewSelect = (cycloneId: string) => {
    onChange(dimension, cycloneId);
    setRecentCycloneDialogOpen(false);
  };

  const onCycloneSearchClose = () => {
    setSearchDialogOpen(false);
  };

  useEffect(() => {
    const exists = [
      ...dimension.values,
      ...recentSearches
    ].find(cycloneOption => cycloneOption.value === value);

    if (!exists) {
      Api.Cyclone.search({ unique_id: value }).then(response => {
        const cyclone = response.cyclones[0];
        if (cyclone && packageName && productName) {
          dispatch(addRecentCyclone(packageName, productName, cyclone));
        }
      });
    }
  }, []);

  return (
    <Box display="flex" alignItems="flex-end">
      <FormControl fullWidth>
        <InputLabel htmlFor="grouped-select">{dimension.label}</InputLabel>
        <Select value={value} id="grouped-select" label="Select cyclone" onChange={onSelectChange} MenuProps={{
          MenuListProps: {
            style: {
              padding: 0
            }
          }
        }}>
          <ListSubheader disableSticky>
            <Box display="flex" justifyContent="space-between">
              Latest cyclones

              <IconButton
                style={{ marginRight: -16 }}
                color="secondary"
                onClick={onMapButtonClick}
                title="Latest cyclones"
              >
                <PublicIcon />
              </IconButton>
            </Box>
          </ListSubheader>

          {
            dimension.values.map(option => {
              return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
            })
          }

          <ListSubheader>Recent searches</ListSubheader>

          {
            recentSearches.map(option => {
              return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
            })
          }

          <Divider />
          <MenuItem button onClick={onSearchClick}>
            <Typography
              component="div"
              color="secondary"
              style={{ display: 'flex', alignItems: 'center', width: '100%', textTransform: 'uppercase' }}
            >
              <SearchIcon style={{ marginRight: 4 }} />
              Search
            </Typography>
          </MenuItem>
        </Select>


      </FormControl>

      <IconButton color="secondary" onClick={onMapButtonClick} title="Latest cyclones" style={{ marginLeft: 8 }}>
        <PublicIcon fontSize="small" />
      </IconButton>

      <Dialog maxWidth="xl" open={recentCycloneDialogOpen} keepMounted onClose={() => {
        setRecentCycloneDialogOpen(false);
      }}>
        <DialogTitle>Recent cyclones</DialogTitle>
        <DialogContent>
          <Box style={{ height: '80vh' }}>
            <RecentCycloneSelect dimension={dimension} onSelect={onOverviewSelect} />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog keepMounted maxWidth="lg" open={searchDialogOpen} onClose={onCycloneSearchClose}>
        <DialogTitle onClose={onCycloneSearchClose}>Search for cyclones</DialogTitle>

        <DialogContent>
          <CycloneSearch onChange={onSearchChange} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CycloneDimensionControl;
