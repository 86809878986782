import { Link, LinkProps } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import decorate from './decorator';

export interface IProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const ReactRouterMuiLink = (props: IProps) => <Link {...props} component={RouterLink as any} />;

export default decorate(ReactRouterMuiLink);
