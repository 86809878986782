import React, { Component, Fragment } from 'react';
import {
  Dialog,
  DialogContent,
  ThemeProvider,
  Tab,
  Box,
  Typography,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core';
import CodeBlock from '../../CodeBlock';
import Api from '../../../api';
import { DimensionSelectionMapping } from '../../../containers/Product';
import { TProductExportSpec } from '../../../api/product';
import theme from '../../../theme';
import CodeIcon from '@material-ui/icons/Code';
import IconMenuItem from '../../IconMenuItem';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import ExternalLink from '../../ExternalLink';
import decorate from './decorator';

export interface IProps {
  packageName: string;
  productName: string;
  dimensionsSelection: DimensionSelectionMapping;
  onStart?: () => void;
  onError?: () => void;
  onComplete?: () => void;
  onDialogClose?: () => void;
  classes: {
    tabPanel: string;
  }
}

interface IState {
  isDialogOpen: boolean;
  activeTabIndex: string;
  exportSpec: {
    url: string;
    swaggerUrl: string;
    notebook?: {
      binder: string;
      github: string;
      colab: string;
    };
  } | null;
}

const FEEDBACK_PAGE_URL = 'https://confluence.ecmwf.int/display/FCST/Changes+on+web+charts+application';

const buildCurlCommand = (url: string) => {
  return `curl -X 'GET' '${url}' -H 'accept: application/json'`;
};

class ScriptButton extends Component<IProps, IState> {
  state: IState = {
    isDialogOpen: false,
    exportSpec: null,
    activeTabIndex: '0'
  };

  getExportSpec = async (): Promise<TProductExportSpec> => {
    const { packageName, productName, dimensionsSelection } = this.props;

    return Api.Product.exportSpec(packageName, productName, dimensionsSelection);
  }

  onScriptOpen = () => {
    const { onStart, onError, onComplete } = this.props;

    onStart && onStart();

    this.getExportSpec()
      .then((exportSpec) => {
        this.setState({
          exportSpec: {
            url: exportSpec.wget,
            swaggerUrl: exportSpec.swagger,
            notebook: exportSpec.notebook
          },
          isDialogOpen: true
        }, () => {
          onComplete && onComplete();
        });
      }).catch(() => {
        onError && onError();
      });
  }

  onScriptClose = () => {
    const { onDialogClose } = this.props;

    this.setState({
      isDialogOpen: false
    }, () => {
      onDialogClose && onDialogClose();
    });
  }

  onTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({
      activeTabIndex: newValue
    });
  }

  renderDialogContent() {
    const { classes } = this.props;
    const { activeTabIndex, exportSpec } = this.state;

    if (!exportSpec) {
      return null;
    }

    return (
      <Fragment>
        <TabContext value={activeTabIndex}>
          <TabList
            onChange={this.onTabChange}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label="Request URL" value="0" />
            <Tab label="cURL" value="1" />
          </TabList>

          <Box marginBottom={4}>
            <TabPanel value="0" className={classes.tabPanel}>
              <CodeBlock code={exportSpec.url} />
            </TabPanel>

            <TabPanel value="1" className={classes.tabPanel}>
              <CodeBlock code={buildCurlCommand(exportSpec.url)} />
            </TabPanel>
          </Box>
        </TabContext>

        <Box>
          <Typography>
            More documentation can be found <ExternalLink href={exportSpec.swaggerUrl}>here</ExternalLink>.
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Typography variant="body2">
            Please note that you are accessing <strong>Beta</strong> version of this feature.
          </Typography>
          <Typography variant="body2">
            Send us thoughts and suggestions using comments sections <ExternalLink
              href={FEEDBACK_PAGE_URL}>here</ExternalLink>.
          </Typography>
        </Box>
      </Fragment>
    );
  }

  render() {
    const { isDialogOpen } = this.state;

    return (
      <Fragment>
        <IconMenuItem onClick={this.onScriptOpen} icon={<CodeIcon />}>
          Script
        </IconMenuItem>

        <ThemeProvider theme={theme(false, 'light')}>
          <Dialog open={isDialogOpen} onClose={this.onScriptClose}>
            <DialogTitle>
              Script documentation

            </DialogTitle>

            <DialogContent>
              <Box marginBottom={3}>
                <Typography>
                  The following URL can be used to download charts programmatically:
                </Typography>
              </Box>

              {
                this.renderDialogContent()
              }
            </DialogContent>

            <DialogActions>
              <Button onClick={this.onScriptClose} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default decorate(ScriptButton);
