import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { IProps, TRouteParams } from './index';
import { flow } from 'lodash';
import { TAppState } from '../../store';
import { fetchProduct } from '../../actions/product';
import * as QueryString from '../../utils/queryString';
import playerDataSelector from '../../selectors/playerDataSelector';
import { sanitizeParams } from '../../utils/RoutingHelpers';

const mapStateToProps = (state: TAppState, props: IProps) => {
  const params = props.match && props.match.params;
  const { packageName, productName } = params as TRouteParams;

  const searchParams = QueryString.parse(props.location.search);
  const playerData = playerDataSelector(packageName, productName, sanitizeParams(searchParams))(state);

  const { controls_overlay, display_legend } = searchParams;

  return {
    ...playerData,
    controlsOverlay: Boolean(controls_overlay),
    displayLegend: Boolean(display_legend)
  };
};

const mapDispatchToProps = {
  fetchProduct
};

export default (component: ComponentType<IProps>) =>
  flow(
    connect(mapStateToProps, mapDispatchToProps)
  )(component);
