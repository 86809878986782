import React, { Component, Fragment } from 'react';
import { Box } from '@material-ui/core';
import TimelineSlider from './TimelineSlider';
import { IDimensionOptionControls } from '../index';

type TProps = IDimensionOptionControls;

class Timeline extends Component<TProps> {
  render() {
    const { selectedDimensionOption, dimensionOptions, onDimensionOptionChange } = this.props;

    return (
      <Fragment>
        <Box>
          <TimelineSlider
            dimensionOptions={dimensionOptions}
            selectedDimensionOption={selectedDimensionOption}
            onDimensionOptionChange={onDimensionOptionChange}
          />
        </Box>
      </Fragment>
    );
  }
}

export default Timeline;
