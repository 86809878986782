import { TAppState } from '../store';
import { TSettingId, TSettingValue } from '../reducers/settings';
import { GridSize } from '@material-ui/core';

const selectSetting = (state: TAppState, settingId: TSettingId): TSettingValue => {
  return state.settings[settingId];
};

const selectGridSizeSetting = (state: TAppState, settingId: TSettingId): TSettingValue<GridSize> => {
  const setting = selectSetting(state, settingId);

  return setting as GridSize;
};

export {
  selectSetting,
  selectGridSizeSetting
};
