import { TreeItemProps, TreeItem as MuiTreeItem } from '@material-ui/lab';
import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import decorate from './decorator';

export interface IProps extends Omit<TreeItemProps, 'classes'> {
  labelText: string;
  labelInfo?: ReactNode;
  classes: {
    root: string;
    selected: string;
    expanded: string;
    content: string;
    group: string;
    label: string;
    labelRoot: string;
    labelIcon: string;
    labelText: string;
  }
}

const TreeItem = (props: IProps) => {
  const { labelText, labelInfo, classes, ...rest } = props;

  return <MuiTreeItem
    {...rest}
    classes={{
      root: classes.root,
      selected: classes.selected,
      expanded: classes.expanded,
      group: classes.group,
      content: classes.content,
      label: classes.label
    }}
    label={
      <div className={classes.labelRoot}>
        <FolderIcon color="inherit" className={classes.labelIcon} fontSize="small" />

        <Typography variant="body1" className={classes.labelText}>
          {labelText}
        </Typography>

        {
          labelInfo &&
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        }
      </div>
    }
  />;
};

export default decorate(TreeItem);
