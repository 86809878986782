import { Box, MenuItem, MenuItemProps } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface IProps extends MenuItemProps<'li', { button?: true }> {
  icon: ReactNode;
}

const IconMenuItem = React.forwardRef<HTMLLIElement, IProps>(
  (props, ref) => {
    const { children, icon, ...rest } = props;

    return (
      <MenuItem ref={ref} {...rest} >
        <Box component="span" display="flex" marginRight={1.5} marginLeft={-0.25}>{icon}</Box>
        {children}
      </MenuItem>
    );
  }
);

export default IconMenuItem;
