import React, { Component, Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Announcements from "../../components/Announcements";
import Router from '../../router';
import FlashMessage from '../../components/FlashMessage';
import isEmbedded from "../../utils/isEmbedded";

class App extends Component {
  render() {
    return (
      <Fragment>
        <CssBaseline />
        {
          !isEmbedded() && (
            <Announcements />
          )
        }

        <Router />

        <FlashMessage />
      </Fragment>
    );
  }
}

export default App;
