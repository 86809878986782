import cycloneMapPath from './tropical-cyclone-map.png';
import React from 'react';

export interface TropicalCycloneMapConfig {
  width: number;
  height: number;
  mapArea: {
    xOffset: number;
    yOffset: number;
    width: number;
    height: number
  }
}

const MAP_BOUNDARY = {
  x0: 83,
  x1: 1828,
  y0: 185,
  y1: 911
};

export const TROPICAL_CYCLONE_MAP_CONFIG: TropicalCycloneMapConfig = {
  width: 1920,
  height: 1080,
  mapArea: {
    width: MAP_BOUNDARY.x1 - MAP_BOUNDARY.x0,
    height: MAP_BOUNDARY.y1 - MAP_BOUNDARY.y0,
    xOffset: MAP_BOUNDARY.x0,
    yOffset: MAP_BOUNDARY.y0
  }
};

interface Props {
  onLoad: () => void;
}

const TropicalCycloneMap = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLImageElement>) => {
    return (
      <img
        ref={ref}
        src={cycloneMapPath}
        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        alt="Tropical Cyclones map"
      />
    );
  }
);

export default TropicalCycloneMap;
