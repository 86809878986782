import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  content: {
    minHeight: 'calc(100vh - 98px)'
  },
  formRoot: {
    flexDirection: 'row',
    flexGrow: 1
  }
});
