import {
  AnnouncementActions,
  ANNOUNCEMENTS_FETCH_COMPLETED, ANNOUNCEMENTS_FETCH_FAILED,
  ANNOUNCEMENTS_FETCH_STARTED
} from '../actions/announcement';
import { Announcement } from '../api/announcement';
import { Nullable } from '../utils/types';

type AnnouncementState = Readonly<{
  loading: boolean;
  loadedAt: Nullable<number>;
  error: Nullable<string>;
  items: Array<Announcement>;
}>

const initialState: AnnouncementState = {
  loading: false,
  loadedAt: null,
  error: null,
  items: []
};

export default (state: AnnouncementState = initialState, action: AnnouncementActions): AnnouncementState => {
  switch (action.type) {
    case ANNOUNCEMENTS_FETCH_STARTED:
      return {
        ...state,
        error: null,
        loading: true
      }

    case ANNOUNCEMENTS_FETCH_COMPLETED:
      return {
        ...state,
        items: action.data,
        loading: false,
        loadedAt: Date.now(),
        error: null
      }

    case ANNOUNCEMENTS_FETCH_FAILED:
      return {
        ...state,
        error: action.error.message,
        loading: false
      }

    default:
      return state;
  }
};
