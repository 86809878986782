import { IRecentLocationAddAction, RECENT_LOCATION_ADD_COMPLETED } from '../actions/recentLocations';
import * as StoreIndex from '../utils/storeIndex';
import { compact, uniqBy } from 'lodash';

export type TLocation = {
  state?: string;
  latitude: string;
  longitude: string;
  name: string;
  country?: string;
}

type TState = Record<string, Array<TLocation>>

type TAllowedActions = IRecentLocationAddAction;

const locationUniqKey = (location: TLocation) => {
  return compact([location.latitude, location.longitude, location.name]).join('::');
};

const addLocation = (state: TState, key: string, location: TLocation) => {
  const locations = [...state[key] ?? []];
  locations.unshift(location);

  const uniqLocations = uniqBy(locations, locationUniqKey).slice(0, 10);

  return {
    ...state,
    [key]: uniqLocations
  };
};

export default (state: TState = {}, action: TAllowedActions) => {
  const key = StoreIndex.recentLocation(action.packageName, action.productName);

  switch (action.type) {
    case RECENT_LOCATION_ADD_COMPLETED: {
      return addLocation(state, key, action.location);
    }

    default:
      return state;
  }
};
