import { Action } from 'redux';
import { TCycloneOption } from '../api/cyclone';

export const RECENT_CYCLONE_ADD_COMPLETED = 'RECENT_CYCLONE_ADD_COMPLETED' as const;

export interface IRecentCycloneAddAction extends Action<typeof RECENT_CYCLONE_ADD_COMPLETED> {
  packageName: string;
  productName: string;
  cyclone: TCycloneOption;
}

const addRecentCyclone = (packageName: string, productName: string, cyclone: TCycloneOption): IRecentCycloneAddAction => {
  return {
    type: RECENT_CYCLONE_ADD_COMPLETED,
    packageName,
    productName,
    cyclone
  };
};

export {
  addRecentCyclone
};
