import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: '100%',
    position: 'sticky',
    bottom: 0,
    padding: theme.spacing(1, 2),
    zIndex: theme.zIndex.appBar
  }
});
