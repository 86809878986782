import queryString from 'query-string';

export type TQuerySearchParams<V = string> = { [key: string]: V | Array<V> };

const parse = <T extends string>(query: string) => queryString.parse(query) as TQuerySearchParams<T>;

const stringify = (params: TQuerySearchParams<string | number>) => queryString.stringify(params);

export {
  parse,
  stringify
};
