import React, { Component } from 'react';
import DimensionSelectorForm, {
  TDimensionSelectorFormProps
} from '../DimensionSelectorForm';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

class DimensionSelectorSmallScreen extends Component<TDimensionSelectorFormProps> {
  state = {
    dialogOpen: false
  }

  onDialogOpen = () => {
    this.setState({ dialogOpen: true });
  }

  onDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  render() {
    const { dialogOpen } = this.state;

    return (
      <Grid container justify='flex-end'>
        <Grid item style={{ paddingBottom: 0 }}>
          <Button onClick={this.onDialogOpen} endIcon={<MenuIcon />} color="secondary">
            Select dimensions
          </Button>

          <Dialog open={dialogOpen} onClose={this.onDialogClose} maxWidth="md">
            <DialogTitle>
              Select dimensions
            </DialogTitle>

            <DialogContent>
              <Box>
                <DimensionSelectorForm {...this.props} />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.onDialogClose} fullWidth color="secondary">Close</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    );
  }
}

export default DimensionSelectorSmallScreen;
