import { Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component, Fragment } from 'react';
import { Announcement } from "../../../api/announcement";
import Alert from '../../Alert';
import Markdown from "../../Markdown";

interface Props {
  announcements: Array<Announcement>;
  onClose: (announcement: Announcement) => void;
}

class BannerAnnouncement extends Component<Props> {
  handleCloseClick = (announcement: Announcement) => () => {
    const { onClose } = this.props;

    onClose(announcement);
  };

  renderItem(announcement: Announcement) {
    return (
      <Alert
        severity={announcement.severity}
        action={
          <IconButton onClick={this.handleCloseClick(announcement)} color="inherit" size="small">
            <CloseIcon />
          </IconButton>
        }
      >
        <Markdown source={announcement.text} removeParagraphSpacing />
      </Alert>
    );
  }

  renderAnnouncements() {
    const { announcements } = this.props;
    const size = announcements.length;

    return announcements.map((announcement, index) => {
      const shouldShowDivider = index + 1 < size;

      return (
        <Fragment key={announcement.id}>
          {
            this.renderItem(announcement)
          }
          {
            shouldShowDivider &&
            <Divider />
          }
        </Fragment>
      );
    });
  }

  render() {
    return (
      <div>
        {
          this.renderAnnouncements()
        }
      </div>
    );
  }
}

export default BannerAnnouncement;
