import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  icon: {
    marginRight: '0.3em',
    fontSize: '1.2rem',
    verticalAlign: 'text-bottom'
  }
});
