import { createStyles } from '@material-ui/core';

export default () => createStyles({
  root: {
    padding: '6px 18px',
    background: '#ffffff'
  }
  // legendWrapper: {
  //   display: 'flex',
  //   paddingTop: 20
  // }
});
