import { ThunkDispatch } from 'redux-thunk';
import { TAppState } from '../store';
import { Action } from 'redux';
import Api from '../api';
import { IProductListResponse, TProductDetails } from '../api/product';
import ErrorHelpers from '../utils/ErrorHelpers';

export const PRODUCT_LIST_FETCH_STARTED: 'PRODUCT_LIST_FETCH_STARTED' = 'PRODUCT_LIST_FETCH_STARTED';
export const PRODUCT_LIST_FETCH_COMPLETED: 'PRODUCT_LIST_FETCH_COMPLETED' = 'PRODUCT_LIST_FETCH_COMPLETED';
export const PRODUCT_LIST_FETCH_FAILED: 'PRODUCT_LIST_FETCH_FAILED' = 'PRODUCT_LIST_FETCH_FAILED';

export interface IProductListFetchStartAction<T = typeof PRODUCT_LIST_FETCH_STARTED> extends Action<T> {
  type: T,
  packageName: string
}

export interface IProductListFetchCompleteAction<T = typeof PRODUCT_LIST_FETCH_COMPLETED> extends Action<T> {
  type: T,
  packageName: string,
  data: IProductListResponse
}

export interface IProductListFetchFailAction<T = typeof PRODUCT_LIST_FETCH_FAILED> extends Action<T> {
  type: T,
  packageName: string,
  error: Error
}

const productListFetchStart = (packageName: string): IProductListFetchStartAction => ({
  type: PRODUCT_LIST_FETCH_STARTED,
  packageName
});

const productListFetchComplete = (packageItem: IProductListResponse): IProductListFetchCompleteAction => ({
  type: PRODUCT_LIST_FETCH_COMPLETED,
  packageName: packageItem.name,
  data: packageItem
});

const productListFetchFail = (packageName: string, error: Error): IProductListFetchFailAction => ({
  type: PRODUCT_LIST_FETCH_FAILED,
  packageName,
  error: error
});

const fetchProductsByPackage = (packageName: string) => {
  return async (dispatch: ThunkDispatch<TAppState, void, Action>): Promise<IProductListResponse> => {
    try {
      dispatch(productListFetchStart(packageName));

      const data = await Api.Product.listByPackage(packageName);

      dispatch(productListFetchComplete(data));

      return data;
    } catch (error) {
      if (ErrorHelpers.isError(error)) {
        dispatch(productListFetchFail(packageName, error));
      }

      throw error;
    }
  };
};

export const PRODUCT_DETAILS_FETCH_STARTED: 'PRODUCT_DETAILS_FETCH_STARTED' = 'PRODUCT_DETAILS_FETCH_STARTED';
export const PRODUCT_DETAILS_FETCH_COMPLETED: 'PRODUCT_DETAILS_FETCH_COMPLETED' = 'PRODUCT_DETAILS_FETCH_COMPLETED';
export const PRODUCT_DETAILS_FETCH_FAILED: 'PRODUCT_DETAILS_FETCH_FAILED' = 'PRODUCT_DETAILS_FETCH_FAILED';

export interface IProductDetailsFetchStartAction<T = typeof PRODUCT_DETAILS_FETCH_STARTED> extends Action<T> {
  type: T,
  packageName: string,
  productName: string
}

export interface IProductDetailsFetchCompleteAction<T = typeof PRODUCT_DETAILS_FETCH_COMPLETED> extends Action<T> {
  type: T,
  packageName: string,
  productName: string,
  data: TProductDetails
}

export interface IProductDetailsFetchFailAction<T = typeof PRODUCT_DETAILS_FETCH_FAILED> extends Action<T> {
  type: T,
  packageName: string,
  productName: string,
  error: Error
}

const productDetailsFetchStart = (packageName: string, productName: string): IProductDetailsFetchStartAction => ({
  type: PRODUCT_DETAILS_FETCH_STARTED,
  packageName,
  productName
});

const productDetailsFetchComplete = (
  packageName: string,
  productName: string,
  product: TProductDetails
): IProductDetailsFetchCompleteAction => ({
  type: PRODUCT_DETAILS_FETCH_COMPLETED,
  packageName,
  productName,
  data: product
});

const productDetailsFetchFail = (
  packageName: string,
  productName: string,
  error: Error
): IProductDetailsFetchFailAction => ({
  type: PRODUCT_DETAILS_FETCH_FAILED,
  packageName,
  productName,
  error: error
});

const fetchProduct = (
  packageName: string,
  productName: string,
  axes?: TProductDetails['values']
) => {
  return async (dispatch: ThunkDispatch<TAppState, void, Action>): Promise<TProductDetails> => {
    try {
      dispatch(productDetailsFetchStart(packageName, productName));

      const product = await Api.Product.fetch(packageName, productName, axes);

      dispatch(productDetailsFetchComplete(packageName, productName, product));

      return product;
    } catch (error) {
      if (ErrorHelpers.isError(error)) {
        dispatch(productDetailsFetchFail(packageName, productName, error));
      }

      throw error;
    }
  };
};

export {
  fetchProduct,
  fetchProductsByPackage
};
