import React, { Component, Fragment, MouseEvent } from 'react';
import { Box, CircularProgress, IconButton, Menu, ThemeProvider, Typography } from '@material-ui/core';
import { IPointDataDescription, PlayerDimension } from '../index';
import ProductShareDialog from '../../../containers/Product/ProductShareDialog';
import decorate from './decorator';
import EmbedShare from './EmbedShare';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import Api from '../../../api';
import { TFlashMessageVariants } from '../../FlashMessage';
import IconMenuItem from '../../IconMenuItem';
import ShareIcon from '@material-ui/icons/Share';
import CodeIcon from '@material-ui/icons/Code';
import DashboardCustomizeIcon from '../../Icons/DashboardCustomize';
import theme from '../../../theme';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { noop } from 'lodash';

interface IDialogController {
  embedDialogOpen: boolean;
  permalinkDialogOpen: boolean;
}

type TState = {
  menuAnchorEl: HTMLElement | null;
  permalink: string | null;
  isSharing: boolean;
} & IDialogController

export interface IProps {
  className?: string;
  disablePermalink?: boolean;
  disableEmbed?: boolean;
  disableDashboard?: boolean;
  selectedDimension: PlayerDimension;
  pointData: IPointDataDescription;
  displayFlashMessage: (type: TFlashMessageVariants, message: string) => void;
  classes: {
    socialMediaContainer: string;
    socialMediaItem: string;
    socialMediaItemHeader: string;
    copyLink: string;
  }
}

class ShareButton extends Component<IProps, TState> {
  state: TState = {
    embedDialogOpen: false,
    permalinkDialogOpen: false,
    menuAnchorEl: null,
    permalink: null,
    isSharing: false
  };

  get shouldRenderMenu() {
    const { disableEmbed, disablePermalink, disableDashboard } = this.props;

    const enabled = [
      disableEmbed,
      disablePermalink,
      disableDashboard
    ].reduce((acc, v) => {
      acc += v ? 0 : 1;

      return acc;
    }, 0);

    return enabled > 1;
  }

  onEmbedDialogOpen = () => {
    this.setState({
      embedDialogOpen: true,
      menuAnchorEl: null
    });
  };

  onEmbedDialogClose = () => {
    this.setState({
      embedDialogOpen: false
    });
  };

  onPermalinkDialogOpen = () => {
    const { displayFlashMessage } = this.props;

    this.setState({
      isSharing: true,
      menuAnchorEl: null
    }, () => {
      this.createPermalink()
        .then(data => {
          const { location } = data;

          this.setState({
            permalinkDialogOpen: true,
            permalink: location,
            menuAnchorEl: null
          });
        }).catch(() => {
          displayFlashMessage('ERROR', 'Unable to create sharable content.');
        }).finally(() => {
          this.setState({
            isSharing: false
          });
        });
    });
  };

  onPermalinkDialogClose = () => {
    this.setState({
      permalinkDialogOpen: false,
      permalink: null
    });
  };

  onShareClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { isSharing } = this.state;
    const { disableEmbed, disablePermalink, disableDashboard } = this.props;

    if (isSharing) {
      return;
    }

    if (this.shouldRenderMenu) {
      this.setState({
        menuAnchorEl: event.currentTarget
      });
    } else if (!disablePermalink) {
      this.onPermalinkDialogOpen();
    } else if (!disableEmbed) {
      this.onEmbedDialogOpen();
    } else if (!disableDashboard) {
      this.onAddToDashboard();
    }
  };

  onAddToDashboard = () => {
    const { pointData, displayFlashMessage } = this.props;

    this.setState({
      isSharing: true,
      menuAnchorEl: null
    }, () => {
      Api.Product.addToDashboard(pointData.packageName, pointData.productName, pointData.values).then((result) => {
        displayFlashMessage('SUCCESS', `Product has been added to your [Dashboard](${result.url}).`);
      })
        .catch(() => {
          displayFlashMessage('ERROR', 'Unable to add to Dashboard.');
        })
        .finally(() => {
          this.setState({
            isSharing: false
          });
        });
    });
  }

  onCopyLink = () => {
    const { permalink } = this.state;
    const { displayFlashMessage } = this.props;

    if (permalink) {
      copyToClipboard(permalink).then(() => {
        displayFlashMessage('SUCCESS', 'A link has been saved to the clipboard.');
        this.onPermalinkDialogClose();
      }).catch(noop);
    }
  }

  onMenuClose = () => {
    this.setState({
      menuAnchorEl: null
    });
  };

  createPermalink() {
    const { pointData } = this.props;

    return Api.Product.share(pointData.packageName, pointData.productName, pointData.values);
  }

  render() {
    const { embedDialogOpen, permalinkDialogOpen, permalink, menuAnchorEl, isSharing } = this.state;
    const {
      className,
      pointData,
      selectedDimension,
      disableEmbed,
      disablePermalink,
      disableDashboard,
      classes
    } = this.props;

    if (disableEmbed && disablePermalink && disableDashboard) {
      return null;
    }

    return (
      <Fragment>
        <IconButton
          onClick={this.onShareClick}
          className={className}
          title="Share"
          disabled={isSharing}
        >
          {
            isSharing ?
              <CircularProgress size={24} variant="indeterminate" color="inherit" />
              : <ShareIcon />
          }
        </IconButton>

        {
          this.shouldRenderMenu &&
          <ThemeProvider theme={theme(false, 'dark')}>
            <Menu
              id="share-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              disableAutoFocusItem
              open={Boolean(menuAnchorEl)}
              onClose={this.onMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right'
              }}
            >
              {
                !disableEmbed &&
                <IconMenuItem onClick={this.onEmbedDialogOpen} icon={<CodeIcon />}>Embed</IconMenuItem>
              }

              {
                !disablePermalink &&
                <IconMenuItem onClick={this.onPermalinkDialogOpen} icon={<ShareIcon />}>
                  Social Media
                </IconMenuItem>
              }

              {
                !disableDashboard &&
                <IconMenuItem onClick={this.onAddToDashboard} icon={<DashboardCustomizeIcon />}>
                  Add to Dashboard
                </IconMenuItem>
              }
            </Menu>
          </ThemeProvider>
        }

        <ProductShareDialog
          open={embedDialogOpen}
          onClose={this.onEmbedDialogClose}
        >
          <EmbedShare
            selectedDimension={selectedDimension}
            data={pointData}
          />
        </ProductShareDialog>

        <ProductShareDialog
          open={permalinkDialogOpen}
          onClose={this.onPermalinkDialogClose}
        >
          <Box className={classes.socialMediaContainer}>
            <Box className={classes.socialMediaItem}>
              <IconButton className={classes.copyLink} onClick={this.onCopyLink}>
                <CodeIcon fontSize="large" />
              </IconButton>
              <Typography className={classes.socialMediaItemHeader}>
                Copy link
              </Typography>
            </Box>

            <FacebookShareButton url={permalink || ''} beforeOnClick={this.onPermalinkDialogClose}>
              <Box className={classes.socialMediaItem}>
                <FacebookIcon />
                <Typography className={classes.socialMediaItemHeader}>
                  Facebook
                </Typography>
              </Box>
            </FacebookShareButton>

            <TwitterShareButton
              url={permalink || ''}
              beforeOnClick={this.onPermalinkDialogClose}
              title="Check out:"
              via="ECMWF"
            >
              <Box className={classes.socialMediaItem}>
                <TwitterIcon />
                <Typography className={classes.socialMediaItemHeader}>
                  Twitter
                </Typography>
              </Box>
            </TwitterShareButton>
          </Box>
        </ProductShareDialog>
      </Fragment>
    );
  }
}

export default decorate(ShareButton);
