import React, { ChangeEvent, Component, ReactNode } from 'react';
import { DimensionChoice, DimensionOption, DimensionSelectionMapping } from '../../../index';
import { Box, InputLabel, Select, FormControl, MenuItem } from '@material-ui/core';

interface IProps {
  selectedDimensions: DimensionSelectionMapping;
  dimension: DimensionChoice;
  value: string | number;
  onChange: (dimension: DimensionChoice, value: DimensionOption['value']) => void;
  endAdornment?: ReactNode;
  nativeSelect?: boolean;
}

class ChoiceDimensionControl extends Component<IProps> {
  onChange = (event: ChangeEvent<unknown>) => {
    const { value } = event.target as HTMLSelectElement;
    const { onChange, dimension } = this.props;

    onChange(dimension, value);
  };

  renderOptions(dimension: DimensionChoice) {
    const { nativeSelect } = this.props;

    const Component = nativeSelect ? 'option' : MenuItem;

    return dimension.values.map(option =>
      <Component
        key={option.value}
        value={option.value}
      >
        {option.label}
      </Component>
    );
  }

  render() {
    const {
      dimension,
      value,
      endAdornment,
      nativeSelect
    } = this.props;
    const { name, label } = dimension;


    return (
      <Box display="flex" alignItems="flex-end">
        <FormControl fullWidth>
          <InputLabel htmlFor={name}>{label}</InputLabel>

          <Select
            name={name}
            value={value}
            onChange={this.onChange}
            onClose={(e: any) => {
              e.target.blur();
            }}
            label={label}
            native={nativeSelect}
            style={{
              flexGrow: 1,
              minWidth: 100
            }}
          >
            {this.renderOptions(dimension)}
          </Select>
        </FormControl>

        {
          endAdornment
        }
      </Box>
    );
  }
}

export default ChoiceDimensionControl;
