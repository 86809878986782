import { Box, Button, Dialog, DialogActions, DialogContent, Icon, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Announcement } from "../../../api/announcement";
import Markdown from "../../Markdown";
import { deriveVariant } from '../variants';

interface Props {
  announcement: Announcement;
  onClose: (announcement: Announcement) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,

    '&:first-child': {
      paddingTop: 0
    }
  },
  iconWrapper: {
    padding: theme.spacing(2),
    color: 'white',
    display: 'flex',
    justifyContent: 'center'
  },
  info: {
    background: theme.palette.info.main
  },
  warning: {
    background: theme.palette.warning.main
  },
  error: {
    background: theme.palette.error.main
  },
  success: {
    background: theme.palette.success.main
  },
  content: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  icon: {
    fontSize: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DialogAnnouncement = (props: Props) => {
  const { announcement, onClose } = props;
  const timeoutRef = useRef<number>();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const variant = deriveVariant(announcement);
  const Icon = variant.icon;

  const onConfirm = (announcement: Announcement) => {
    setDialogOpen(false);
    onClose(announcement);
  }

  useEffect(() => {
    console.log('useEffect', announcement.delay, announcement)
    timeoutRef.current = window.setTimeout(() => {
      setDialogOpen(true);
    }, announcement.delay * 1000);

    return () => {
      window.clearTimeout(timeoutRef.current)
    }
  }, []);

  return (
    <Dialog
      open={dialogOpen}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogContent className={classes.root}>
        <div className={classNames(classes.iconWrapper, classes[variant.className])}>
          <Icon className={classes.icon} />
        </div>
        <div className={classes.content}>
          <Box>
            <Markdown source={announcement.text} />
          </Box>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="secondary"
          onClick={() => onConfirm(announcement)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogAnnouncement;
