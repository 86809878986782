import React, { Component, ChangeEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import SearchInput from '../../components/SearchInput';
import PackageCard from './PackageCard';
import { connect } from 'react-redux';
import { TAppState } from '../../store';
import { fetchPackageList } from '../../actions/package';
import { TPackage } from '../../api/package';
import Fuze from 'fuse.js';
import PageWrapper from '../../components/PageWrapper';
import PageError from '../../components/PageError';

interface IProps {
  packages: Array<TPackage>,
  packagesError: string | null,
  fetchPackageList: () => Promise<any>
}

type TState = {
  searchValue: string
};

const searchOptions = {
  keys: ['title'],
  ignoreLocation: true,
  threshold: 0.3
};

class Packages extends Component<IProps, TState> {
  fuze: Fuze<TPackage> = new Fuze([] as Array<TPackage>, searchOptions);

  state: TState = {
    searchValue: ''
  };

  handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchValue: e.target.value
    });
  };

  handleSearchValueReset = () => {
    this.setState({
      searchValue: ''
    });
  };

  componentDidMount(): void {
    this.props.fetchPackageList();
  }

  componentDidUpdate(prevProps: IProps) {
    const { packages } = this.props;

    if (prevProps.packages !== packages) {
      this.fuze.setCollection(packages);
    }
  }

  renderPackage(item: TPackage) {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.name}>
        <PackageCard value={item} />
      </Grid>
    );
  }

  renderPackages() {
    const { packages } = this.props;
    const { searchValue } = this.state;

    return (
      searchValue ? this.fuze && this.fuze.search(searchValue).map(r => r.item) : packages
    ).map(item => this.renderPackage(item));
  }

  render() {
    const { searchValue } = this.state;
    const { packagesError } = this.props;

    if (packagesError) {
      return (
        <PageError title="Sorry! We were unable to retrieve data." />
      );
    }

    return (
      <PageWrapper>
        <Grid container direction="column">
          <Grid item xs={12} style={{ marginBottom: 32 }}>
            <Grid container justify="center">
              <Grid item xs={12} sm={10} md={8}>
                <SearchInput
                  placeholder="Search packages..."
                  onChange={this.handleSearchValueChange}
                  onReset={this.handleSearchValueReset}
                  value={searchValue}
                  fullWidth
                  inputProps={{ 'aria-label': 'search-packages' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {
                this.renderPackages()
              }
            </Grid>
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state: TAppState) => {
  return {
    packages: Object.values(state.packages.data),
    packagesError: state.packages.error
  };
};

const mapDispatchToProps = {
  fetchPackageList
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
