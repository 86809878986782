import React, { Component } from 'react';
import decorate from './decorator';
import { Box, Button } from '@material-ui/core';
import { copyToClipboard } from '../../utils/copyToClipboard';
import classNames from 'classnames';

export interface IProps {
  code: string;
  classes: {
    root: string;
    code: string;
    copyButton: string;
    copied: string;
  }
}

interface IState {
  copied: boolean;
}

const STATUS_DURATION = 1500;

class CodeBlock extends Component<IProps, IState> {
  statusTimeout: number | null = null;

  state = {
    copied: false
  }

  onCopy = () => {
    const { code } = this.props;
    this.clearStatusTimeout();

    copyToClipboard(code).then(() => {
      this.setState({
        copied: true
      }, () => {
        this.startStatusTimeout(() => {
          this.setState({
            copied: false
          });
        });
      });
    });
  }

  startStatusTimeout(callback: TimerHandler) {
    this.statusTimeout = window.setTimeout(callback, STATUS_DURATION);
  }

  clearStatusTimeout() {
    this.statusTimeout && window.clearTimeout(this.statusTimeout);
  }

  componentWillUnmount() {
    this.clearStatusTimeout();
  }

  render() {
    const { classes, code } = this.props;
    const { copied } = this.state;

    return (
      <Box className={classes.root}>
        <code className={classes.code}>{code}</code>

        <Button
          size="small"
          className={classNames(classes.copyButton, copied && classes.copied)}
          onClick={this.onCopy}
        >
          { copied ? 'Copied!' : 'Copy' }
        </Button>
      </Box>
    );
  }
}

export default decorate(CodeBlock);
