import React, { PureComponent, ReactNode } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { Grid, WithStyles } from '@material-ui/core';
import {
  Dimension,
  DimensionChoice,
  DimensionCyclone, DimensionMultipleChoice,
  DimensionOption,
  DimensionSelectionMapping,
  DimensionType
} from '../../index';
import ChoiceDimensionControl from './ChoiceDimensionControl';
import LocationDimensionControl, { IProps as LocationDimensionControlProps } from './LocationDimensionControl';
import config from '../../../../config';
import paths from '../../../../router/paths';
import IconButtonLink from '../../../../components/IconButtonLink';
import GridViewIcon from '../../../../components/Icons/GridView';
import CycloneDimensionControl from './CycloneDimensionControl';
import MultipleChoiceDimensionControl from './MultipleChoiceDimensionControl';

export interface IDimensionSelectorFormCore {
  dimensions: Array<Dimension>;
  selectedDimensions: DimensionSelectionMapping;
  onChange: (values: DimensionSelectionMapping, changed?: Array<keyof DimensionSelectionMapping>) => void;
  nativeSelect?: boolean;
}

export interface IDimensionSelectorForm extends IDimensionSelectorFormCore {
  showOverviewLink?: boolean;
  hideRecentLocations?: boolean;
  locationControlVariant?: LocationDimensionControlProps['variant'];
  orientation?: 'horizontal' | 'vertical';
  packageName?: string;
  productName?: string;
}

export type TDimensionSelectorFormProps = IDimensionSelectorForm;

export const isWithOverview = (props: IDimensionSelectorForm) => {
  return props.showOverviewLink;
};

class DimensionSelectorForm extends PureComponent<TDimensionSelectorFormProps & WithStyles<typeof styles>> {
  static defaultProps = {
    showOverviewLink: false,
    orientation: 'vertical' as const
  };

  onChoiceDimensionChange = (dimension: DimensionChoice, value: DimensionOption['value']) => {
    const { onChange, selectedDimensions } = this.props;

    onChange({
      ...selectedDimensions,
      [dimension.name]: value
    }, [dimension.name]);
  };

  onLocationDimensionChange = (latitude: string, longitude: string, stationName: string) => {
    const { onChange, selectedDimensions } = this.props;

    onChange({
      ...selectedDimensions,
      lat: latitude,
      lon: longitude,
      station_name: stationName
    }, ['lat', 'lon', 'station_name']);
  }

  onCycloneDimensionChange = (dimension: DimensionCyclone, value: string) => {
    const { onChange, selectedDimensions } = this.props;

    onChange({
      ...selectedDimensions,
      [dimension.name]: value
    }, [dimension.name]);
  }

  onMultipleChoiceDimensionChange = (dimension: DimensionMultipleChoice, values: Array<string>) => {
    const { onChange, selectedDimensions } = this.props;

    onChange({
      ...selectedDimensions,
      [dimension.name]: values.join(',')
    }, [dimension.name]);
  }

  renderOverviewButton(dimension: DimensionChoice) {
    if (!isWithOverview(this.props)) {
      return null;
    }

    const { productName, packageName, selectedDimensions } = this.props;
    const { overviewEnabled } = config;

    if (!overviewEnabled || !packageName || !productName) {
      return null;
    }
    const overviewPath = paths.productDimensionOverview(packageName, productName, dimension.name, selectedDimensions);

    return (
      <IconButtonLink
        title={`"${dimension.label}" dimension overview`}
        to={overviewPath}
        style={{ marginLeft: 8 }}
        color="secondary"
      >
        <GridViewIcon fontSize="small" />
      </IconButtonLink>
    );
  }

  renderControl(dimension: Dimension) {
    const {
      selectedDimensions,
      productName,
      packageName,
      nativeSelect,
      locationControlVariant,
      hideRecentLocations,
      orientation,
      classes
    } = this.props;
    const { name } = dimension;
    let control: ReactNode;

    const currentValue = selectedDimensions[name];

    switch (dimension.type) {
      case DimensionType.CHOICE:
        control = <ChoiceDimensionControl
          selectedDimensions={selectedDimensions}
          dimension={dimension}
          value={currentValue}
          onChange={this.onChoiceDimensionChange}
          endAdornment={
            isWithOverview(this.props) && this.renderOverviewButton(dimension)
          }
          nativeSelect={nativeSelect}
        />;

        break;
      case DimensionType.LOCATION:
        control = <LocationDimensionControl
          packageName={packageName}
          productName={productName}
          location={{
            name: selectedDimensions.station_name as string,
            latitude: selectedDimensions.lat as string,
            longitude: selectedDimensions.lon as string
          }}
          onChange={this.onLocationDimensionChange}
          hideRecentLocations={hideRecentLocations}
          variant={locationControlVariant}
        />;

        break;

      case DimensionType.CYCLONE:
        control = <CycloneDimensionControl
          packageName={packageName}
          productName={productName}
          dimension={dimension}
          value={currentValue as string}
          onChange={this.onCycloneDimensionChange}
        />;
        break;

      case DimensionType.MULTIPLE_CHOICE:
        control = <MultipleChoiceDimensionControl
          dimension={dimension}
          onChange={this.onMultipleChoiceDimensionChange}
          values={(currentValue as string).split(',')}
          orientation={orientation!}
        />;
        break;

      default:
        return null;
    }

    return (
      <Grid item key={name} className={classes.gridItem}>
        {control}
      </Grid>
    );
  }

  render() {
    const { dimensions, classes } = this.props;

    return (
      <Grid container spacing={3} className={classes.root}>
        {
          dimensions.map(dimension => this.renderControl(dimension))
        }
      </Grid>
    );
  }
}
export default withStyles(styles)(DimensionSelectorForm);
