import React, { Component, ComponentType } from 'react';
import { TDimensionSelectorFormProps } from './DimensionSelectorForm';
import {
  withWidth
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import DimensionSelectorSmallScreen from './DimensionSelectorSmallScreen';
import DimensionSelectorLargeScreen from './DimensionSelectorLargeScreen';


type TProps = Omit<TDimensionSelectorFormProps, 'showOverviewLink'> & {
  width: Breakpoint
}

const components: Record<Breakpoint, ComponentType<TDimensionSelectorFormProps>> = {
  xs: DimensionSelectorSmallScreen,
  sm: DimensionSelectorSmallScreen,
  md: DimensionSelectorSmallScreen,
  lg: DimensionSelectorLargeScreen,
  xl: DimensionSelectorLargeScreen
};

class DimensionSelector extends Component<TProps> {
  render() {
    const { packageName, productName, dimensions, selectedDimensions, onChange, width } = this.props;
    const Component = components[width];

    return (
      <Component
        showOverviewLink
        packageName={packageName}
        productName={productName}
        dimensions={dimensions}
        selectedDimensions={selectedDimensions}
        onChange={onChange}
      />
    );
  }
}

export default withWidth()(DimensionSelector);
