import createStyles from '@material-ui/core/styles/createStyles';

export default () => createStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentArea: {
    flexGrow: 1
  },
  media: {
    paddingBottom: '50%'
  }
});
