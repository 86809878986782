import { Box, Chip, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { Fragment, useMemo } from "react";
import { DimensionMultipleChoice } from "../../../../index";


interface OrientationInputValueProps {
  dimensionOptionMap: Record<string, {
    value: string;
    label: string;
    status: "disabled" | "enabled";
  }>;
  values: Array<string>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  label: {
    textDecoration: 'line-through'
  }
}));

const HorizontalInputValue = (props: OrientationInputValueProps) => {
  const { dimensionOptionMap, values } = props;
  const classes = useStyles();

  return (
    <Fragment>
      {
        values.map((value, index) => {
          const option = dimensionOptionMap[value];

          return (
            <Fragment key={option.value}>
              <span
                className={classNames(option.status === 'disabled' && classes.label)}
              >
                {option.label}
              </span>

              {
                (index < values.length - 1) &&
                <span>,&nbsp;</span>
              }
            </Fragment>
          );
        })
      }
    </Fragment>
  );
}

const VerticalInputValue = (props: OrientationInputValueProps) => {
  const { dimensionOptionMap, values } = props;
  const classes = useStyles();

  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {
        values.map((value) => {
          const option = dimensionOptionMap[value];

          return (
            <Chip
              key={option.value}
              disabled={option.status === 'disabled'}
              className={classes.chip}
              classes={{ label: classNames(option.status === 'disabled' && classes.label) }}
              label={option.label}
            />
          );
        })
      }
    </Box>
  )
}

interface Props {
  dimension: DimensionMultipleChoice;
  values: Array<string>;
  orientation: 'horizontal' | 'vertical';
}

const InputValueRenderer = (props: Props) => {
  const { values, dimension, orientation } = props;

  const dimensionOptionMap = useMemo(() => {
    return dimension.values.reduce((acc, option) => {
      acc[option.value] = option;

      return acc;
    }, {} as Record<string, {
      value: string;
      label: string;
      status: "disabled" | "enabled";
    }>);
  }, [dimension]);

  switch (orientation) {
    case 'horizontal':
      return <HorizontalInputValue values={values} dimensionOptionMap={dimensionOptionMap} />;
    case 'vertical':
      return <VerticalInputValue values={values} dimensionOptionMap={dimensionOptionMap} />;
  }
};

export default InputValueRenderer;
