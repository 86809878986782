import { withStyles } from '@material-ui/core';
import { ComponentType } from 'react';
import { flow, intersection } from 'lodash';
import { connect } from 'react-redux';
import filterProductDataParams from "../../utils/filterProductDataParams";
import styles from './styles';
import { DimensionProductData, PlayerDimension, TProps } from './index';
import { TAppState } from '../../store';
import {
  fetchProductData,
  preloadProductData, TProductDimensionParams
} from '../../actions/productData';
import { Dimension, DimensionSelectionMapping, DimensionType } from '../../containers/Product';
import * as StoreIndex from '../../utils/storeIndex';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connectThunkAction } from '../../utils/ReduxHelpers';

export const derivePlayerDataFromProductData = (
  productData: TAppState['productData'],
  packageName: string,
  productName: string,
  dimensions: Array<Dimension>,
  selectedDimension: PlayerDimension,
  productValues: DimensionSelectionMapping
) => {
  const filteredProductValues = filterProductDataParams(dimensions, productValues);

  const data = selectedDimension.values.reduce((acc: DimensionProductData, dimensionValue) => {
    const key = StoreIndex.productData(packageName, productName, {
      ...filteredProductValues,
      [selectedDimension.name]: dimensionValue.value
    });

    const item = productData[key];

    if (item) {
      acc[dimensionValue.value] = {
        ...item,
        value: dimensionValue.value,
        label: dimensionValue.label
      };
    }

    return acc;
  }, {});

  return data;
};

const mapStateToProps = (state: TAppState, props: TProps) => {
  const { dimensions, selectedDimension, productValues, packageName, productName } = props;

  const data = derivePlayerDataFromProductData(
    state.productData,
    packageName,
    productName,
    dimensions,
    selectedDimension,
    productValues
  );

  return {
    ...props,
    dimensionProductData: data
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchProductData: connectThunkAction(dispatch, fetchProductData),
  preloadProductData: connectThunkAction(dispatch, preloadProductData)
});

export default (
  component: ComponentType<TProps>
): ComponentType<Omit<TProps, 'classes' | 'dimensionProductData' | 'fetchProductData' | 'preloadProductData'>> =>
  flow(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(component);
