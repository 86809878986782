import Link from '../../Link';
import Typography from '@material-ui/core/Typography';
import React, { ReactNode } from 'react';
import { Link as MuiLink } from '@material-ui/core';

interface IProps {
  label: string;
  path: string | null;
  external?: boolean;
  icon?: ReactNode;
}

const BreadcrumbItem = (props: IProps) => {
  const { label, path, icon, external } = props;

  if (path) {
    if (external) {
      return (
        <MuiLink color="inherit" href={path}>
          {icon}
          {label}
        </MuiLink>
      );
    } else {
      return (
        <Link color="inherit" to={path}>
          {icon}
          {label}
        </Link>
      );
    }
  }

  return (
    <Typography color="textSecondary">{label}</Typography>
  );
};

export default BreadcrumbItem;
