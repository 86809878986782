import { withStyles } from '@material-ui/core';
import { IProps } from './index';
import { ComponentType } from 'react';
import styles from './styles';
import { withRouter } from 'react-router';

export default (component: ComponentType<IProps>) =>
  withRouter(
    withStyles(styles)(component)
  );
