import DimensionSelectorForm, { TDimensionSelectorFormProps } from '../DimensionSelectorForm';
import React, { Fragment } from 'react';
import { Paper, Box } from '@material-ui/core';

const DimensionSelectorLargeScreen = (props: TDimensionSelectorFormProps) => {
  return (
    <Fragment>
      <Paper style={{ height: '100%' }}>
        <Box padding={2}>
          <DimensionSelectorForm {...props} />
        </Box>
      </Paper>
    </Fragment>
  );
};

export default DimensionSelectorLargeScreen;
