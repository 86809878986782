import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    margin: '0 auto',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    background: theme.palette.common.white,
    display: 'block'
  },
  loading: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(6),
    background: theme.palette.common.white,
    marginTop: 'auto',
    color: theme.palette.grey.A200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  notAvailable: {
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    background: theme.palette.common.white,
    textAlign: 'center'
  },
  retryButton: {
    marginTop: theme.spacing(2)
  },
  visible: {
    zIndex: 0
  },
  interactive: {
    cursor: 'pointer'
  }
});
