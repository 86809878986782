import React, { Component } from 'react';
import { Box, Card, CardContent, Typography, WithStyles } from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import CardActionAreaLink from '../../../components/CardActionAreaLink';
import ChartSetMedia from '../ChartSetMedia';
import deriveDimensionsFromAxis from '../../../utils/deriveDimensionsFromAxis';
import * as DimensionUtils from '../../../utils/DimensionUtils';
import { pickBy, map } from 'lodash';
import paths from '../../../router/paths';
import { TProductDetails } from '../../../api/product';
import { Dimension, DimensionSelectionMapping } from '../../Product';

export interface IProps extends WithStyles<typeof styles> {
  product: TProductDetails;
  commonDimensions: Array<Dimension>;
  selectedDimensions: DimensionSelectionMapping;
}

class ChartSetItem extends Component<IProps> {
  render() {
    const { product, selectedDimensions, commonDimensions, classes } = this.props;

    const dimsSet = deriveDimensionsFromAxis(product.axis, {});
    const mapping = DimensionUtils.withValues(dimsSet.dimensions, selectedDimensions);

    const commonDimensionsNames = map(commonDimensions, (dimension => dimension.name));
    const specificDimensions = mapping.filter(item => !commonDimensionsNames.includes(item.name));

    const productDimensionNames = dimsSet.dimensions.map(dimension => dimension.name);
    const query = pickBy(selectedDimensions, (_, key) => {
      return productDimensionNames.includes(key);
    });
    const href = paths.productDetails(product.package.name, product.name, query);

    return (
      <Card className={classes.card}>
        <CardActionAreaLink to={href} className={classes.linkArea}>
          <ChartSetMedia
            dimensions={commonDimensions}
            product={product}
            selectedDimensions={selectedDimensions}
          />

          <CardContent className={classes.cardContent}>
            <Box marginBottom={2}>
              <Typography variant='h5'>
                {product.title}
              </Typography>
            </Box>

            {
              specificDimensions.length !== 0 &&
              <Box>
                <Typography variant="body1">
                  Product specific dimensions:
                </Typography>

                {
                  specificDimensions.map(item =>
                    <Typography variant='body2' color="textSecondary" key={product.name}>
                      {`${item.label}: ${item.value}`}
                    </Typography>
                  )
                }
              </Box>
            }

          </CardContent>
        </CardActionAreaLink>
      </Card>
    );
  }
}

export default decorate(ChartSetItem);
