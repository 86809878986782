import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';

interface Props<T = unknown> {
  items: Array<T>;
  perPage: number;
  renderItem: (item: T) => JSX.Element;
}

const InfiniteScroll = function <T>(props: Props<T>) {
  const { items, perPage, renderItem } = props;

  const observerTarget = useRef(null);
  const [limit, setLimit] = useState(perPage);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setLimit(limit => limit + perPage);
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  const itemsToRender = items.slice(0, limit);

  return (
    <div>
      <Box style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: 24,
        rowGap: 24,
        gridAutoRows: '1fr'
      }}>
        {itemsToRender.map(renderItem)}
        {/* {isLoading && <p>Loading...</p>}*/}
        {/* {error && <p>Error: {error.message}</p>}*/}
      </Box>
      <div className="observerTarget" ref={observerTarget}></div>
    </div>
  );
};

export default InfiniteScroll;
