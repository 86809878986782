import { intersection } from "lodash";
import { Dimension, DimensionType } from "../containers/Product";

type Params = {
  [key: string]: string | number
}

export const filterProductDataParams = (dimensions: Array<Dimension>, params: Params) => {
  const filteredParams: Params = { ...params };

  dimensions.forEach(dimension => {
    if (params[dimension.name] === undefined) {
      return;
    }

    if (dimension.type === DimensionType.MULTIPLE_CHOICE) {
      const enabledValues = dimension.values
        .filter(i => i.status === 'enabled')
        .map(i => i.value);
      const values = params[dimension.name].toString().split(',');

      filteredParams[dimension.name] = intersection(values, enabledValues).join(',');
    } else {
      filteredParams[dimension.name] = params[dimension.name];
    }
  })

  return filteredParams;
};

export default filterProductDataParams;
