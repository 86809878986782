import React, { Component, Fragment } from 'react';
import { Box } from '@material-ui/core';
import decorate from './decorator';
import {
  DimensionProductData,
  PlayerDimensionDataItem,
  PlayerDimensionOption
} from '../index';
import { map, isEmpty } from 'lodash';
import Legend from '../Legend';
import { DimensionSelectionMapping } from '../../../containers/Product';
import HelpInfo from './HelpInfo';
import ProductDataPointMedia, { IPosition } from '../../ProductDataPointMedia';

export type TProps = Readonly<{
  data: DimensionProductData,
  productValues: DimensionSelectionMapping,
  selectedDimensionOption: PlayerDimensionOption | null,
  embedMode: boolean | undefined,
  legendOpen: boolean,
  onLegendClose?: () => void;
  controlsOverlay?: boolean;
  uiVisible?: boolean;
  onItemClick?: (item: PlayerDimensionDataItem, x: number, y: number) => void;
  onItemFetchRetry?: (item: PlayerDimensionDataItem) => void;
  classes: {
    root: string;
    loading: string;
    mediaRoot: string;
    mediaLoading: string;
    mediaNotAvailable: string;
    mediaVisible: string;
  }
}>

class Media extends Component<TProps> {
  handleItemClick = (item: PlayerDimensionDataItem, pos: IPosition) => {
    const { onItemClick } = this.props;
    const spec = item.click?.navigation;

    if (!spec) {
      return null;
    }

    // Y axis is inverted on the backend
    const posY = -1 * (pos.y - pos.height);

    const pcx = ((pos.x * spec.width) / pos.width) + spec.xmin;
    const pcy = ((posY * spec.height) / pos.height) + spec.ymin;

    onItemClick && onItemClick(item, pcx, pcy);
  }

  renderItem = (item: PlayerDimensionDataItem | undefined, key: string | number) => {
    const {
      selectedDimensionOption,
      legendOpen,
      controlsOverlay,
      classes,
      onItemFetchRetry,
      onLegendClose
    } = this.props;
    const label = item?.label.toString() ?? '';
    const isSelected = Boolean(selectedDimensionOption && item && item.value === selectedDimensionOption.value);

    return (
      <Fragment key={key}>
        <ProductDataPointMedia
          key={key}
          item={item}
          title={label}
          visible={isSelected}
          onClick={this.handleItemClick}
          onRetry={onItemFetchRetry}
          classes={{
            root: classes.mediaRoot,
            loading: classes.mediaLoading,
            notAvailable: classes.mediaNotAvailable,
            visible: classes.mediaVisible
          }}
        />

        {
          item && item.legend.length > 0 &&
          <Legend
            controlsOverlay={controlsOverlay}
            items={item.legend}
            visible={legendOpen && isSelected}
            onClose={onLegendClose}
          />
        }
      </Fragment>
    );
  };

  renderImages() {
    const { data } = this.props;

    return map(data, this.renderItem);
  }

  renderFeatureHelp() {
    const { data, selectedDimensionOption } = this.props;

    const isFeatureHelpEnabled =
      !isEmpty(data) &&
      selectedDimensionOption &&
      data[selectedDimensionOption.value]?.click;

    if (!isFeatureHelpEnabled) {
      return null;
    }

    const tooltip = data[selectedDimensionOption!.value]?.click!.config.tooltip;

    return <HelpInfo text={tooltip} />;
  }

  render() {
    const { classes } = this.props;

    return (
      <Box position="relative" className={classes.root}>
        {this.renderFeatureHelp()}
        {this.renderImages()}
      </Box>
    );
  }
}

export default decorate(Media);
