import { createStyles, Theme } from '@material-ui/core';
import { THUMB_HEIGHT } from '../constants';

const margin = -4;
const size = THUMB_HEIGHT;

export default (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: margin,
    marginTop: margin,
    width: size,
    height: size,
    border: 0,
    borderRadius: '50%',
    whiteSpace: 'nowrap',
    position: 'absolute',
    zIndex: 2,
    cursor: 'pointer'
  }
});
