import {
  CircularProgress,
  IconButton,
  Menu,
  ThemeProvider
} from '@material-ui/core';
import React, { Component, Fragment, MouseEvent } from 'react';
import PhotoIcon from '@material-ui/icons/Photo';
import DescriptionIcon from '@material-ui/icons/Description';
import Api from '../../api';
import { TProductDownloadDetails } from '../../api/product';
import { IPointDataDescription } from '../Player';
import IconMenuItem from '../IconMenuItem';
import theme from '../../theme';
import { TFlashMessageVariants } from '../FlashMessage';
import decorate from './decorator';
import { noop } from 'lodash';
import ScriptButton from './ScriptButton';
import NotebookButton from './NotebookButton';
import ExportIcon from '../Icons/Export';

export interface IProps {
  pointData: IPointDataDescription;
  displayFlashMessage: (type: TFlashMessageVariants, message: string) => void;
  className?: string;
  notebook: boolean;
  script: boolean;
}

type TState = {
  isDownloading: boolean;
  isScriptDialogOpen: boolean;
  menuAnchorEl: HTMLElement | null;
};

type TDownloadFormats = 'pdf' | 'png';

class ProductDataItemDownloadButton extends Component<IProps, TState> {
  state = {
    isDownloading: false,
    isScriptDialogOpen: false,
    menuAnchorEl: null
  };

  download = (format: TDownloadFormats): Promise<TProductDownloadDetails> => {
    const { pointData, displayFlashMessage } = this.props;

    return new Promise((resolve, reject) => {
      this.setState({
        isDownloading: true,
        menuAnchorEl: null
      }, () => {
        Api.Product.download(pointData.packageName, pointData.productName, format, pointData.values)
          .then(response => {
            displayFlashMessage('SUCCESS', 'Product has been successfully downloaded.');
            resolve(response);
          })
          .catch(() => {
            displayFlashMessage('ERROR', 'Unable to download a product.');
            reject();
          })
          .finally(() => {
            this.setState({
              isDownloading: false
            });
          });
      });
    });
  };



  onDownload = (format: TDownloadFormats) => () => {
    this.download(format).then(response => {
      const link = document.createElement('a');

      link.href = response.url;
      link.download = '';
      link.target = '_blank';
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(noop);
  };

  onMenuClose = () => {
    this.setState({
      menuAnchorEl: null
    });
  };

  onMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    const { isDownloading } = this.state;

    if (isDownloading) {
      return;
    }

    this.setState({
      menuAnchorEl: event.currentTarget
    });
  }

  render() {
    const { className, pointData, notebook, script } = this.props;
    const { isDownloading, menuAnchorEl } = this.state;

    return (
      <Fragment>
        <IconButton
          className={className}
          onClick={this.onMenuOpen}
          title="Export plot"
          disabled={isDownloading}
        >
          {
            isDownloading ?
              <CircularProgress size={24} variant="indeterminate" color="inherit" />
              : <ExportIcon />
          }
        </IconButton>

        <ThemeProvider theme={theme(false, 'dark')}>
          <Menu
            id="product-data-item-download-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            disableAutoFocusItem
            open={Boolean(menuAnchorEl)}
            onClose={this.onMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
          >
            {
              <IconMenuItem onClick={this.onDownload('png')} icon={<PhotoIcon />}>
                Image
              </IconMenuItem>
            }

            {
              <IconMenuItem onClick={this.onDownload('pdf')} icon={<DescriptionIcon />}>
                PDF
              </IconMenuItem>
            }

            {
              script &&
              <ScriptButton
                packageName={pointData.packageName}
                productName={pointData.productName}
                dimensionsSelection={pointData.values}
                onStart={() => {
                  this.setState({
                    isDownloading: true,
                    menuAnchorEl: null
                  });
                }}
                onComplete={() => {
                  this.setState({
                    isDownloading: false
                  });
                }}
                onError={() => {
                  this.setState({
                    isDownloading: false
                  });
                }}
              />
            }


            {
              notebook &&
              <NotebookButton
                packageName={pointData.packageName}
                productName={pointData.productName}
                dimensionsSelection={pointData.values}
                onStart={() => {
                  this.setState({
                    isDownloading: true,
                    menuAnchorEl: null
                  });
                }}
                onComplete={() => {
                  this.setState({
                    isDownloading: false
                  });
                }}
                onError={() => {
                  this.setState({
                    isDownloading: false
                  });
                }}
              />
            }

          </Menu>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default decorate(ProductDataItemDownloadButton);
