import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { compact, includes } from 'lodash';
import React, { Component, Fragment } from 'react';
import { ReactCookieProps } from "react-cookie";
import { Announcement } from "../../api/announcement";
import config from "../../config";
import { Nullable } from "../../utils/types";
import BannerAnnouncement from './BannerAnnouncement';
import decorate from './decorator';
import DialogAnnouncement from './DialogAnnouncement';

dayjs.extend(isBetween);

const POLLING_INTERVAL = 2 * 60 * 1000;
const COOKIE_PREFIX = [config.cookieNamespace, 'announcements'].join('-');
const COOKIE_VALUE_SEPARATOR = ',';

const announcementsByStackAndType = (announcements: Array<Announcement>) => {
  return announcements.reduce((acc: Record<Announcement['type'], Array<Announcement>>, announcement: Announcement) => {
    if (
      announcement.target === 'all' ||
      (Array.isArray(announcement.target) && announcement.target.includes(config.stackName))
    ) {
      acc[announcement.type].push(announcement);
    }

    return acc;
  }, {
    banner: [],
    dialog: []
  });
};

const getReadAnnouncementIds = (props: Props) => {
  const { cookies } = props;
  const cookie = cookies?.get(COOKIE_PREFIX);

  return (cookie && cookie.split(COOKIE_VALUE_SEPARATOR).map((v: string) => parseInt(v, 10))) || [];
};

const getActiveAnnouncements = (props: Props) => {
  const readIds = getReadAnnouncementIds(props);

  return props.announcements.filter((announcement: Announcement) =>
    !includes(readIds, announcement.id) &&
    dayjs().isBetween(dayjs(announcement.start_date), dayjs(announcement.end_date))
  );
};

interface OwnProps {
  announcements: Array<Announcement>;
  fetchAnnouncements: () => void;
}

interface State {
  banner: Array<Announcement>;
  dialog: Array<Announcement>;
}

export type Props = OwnProps & ReactCookieProps;

class Announcements extends Component<Props> {
  state = {
    banner: [],
    dialog: []
  };
  private pollingId: Nullable<number> = null;

  static getDerivedStateFromProps(props: Props, state: State) {
    const activeAnnouncements = getActiveAnnouncements(props);

    return {
      ...state,
      ...announcementsByStackAndType(activeAnnouncements)
    };
  }

  markAsRead(announcement: Announcement) {
    const { cookies } = this.props;
    const readIds = getReadAnnouncementIds(this.props);

    const parsedCookieValue = compact([announcement.id, ...readIds]).sort().join(COOKIE_VALUE_SEPARATOR);

    cookies?.set(COOKIE_PREFIX, parsedCookieValue, { path: '/' });
  }

  handleOnClose = (announcement: Announcement) => {
    this.markAsRead(announcement);
  };

  componentDidMount() {
    const { fetchAnnouncements } = this.props;

    fetchAnnouncements();

    this.pollingId = window.setTimeout(() => {
      fetchAnnouncements();
    }, POLLING_INTERVAL);
  }

  componentWillUnmount() {
    this.pollingId && clearInterval(this.pollingId);
  }

  render() {
    const { banner, dialog } = this.state;

    return (
      <Fragment>
        <BannerAnnouncement announcements={banner} onClose={this.handleOnClose} />
        {
          dialog.length >= 1 &&
          <DialogAnnouncement announcement={dialog[0]} onClose={this.handleOnClose} />
        }
      </Fragment>
    );
  }
}

export default decorate(Announcements);
