import React from 'react';
import ReactDOM from 'react-dom';
import WebplotsApplication from '@webplots/core';
import theme from './theme';
import './styles.css';

const rootEl = document.getElementById('root');

const embedMode = (window as any).isEmbedded as boolean;


const App = () => {
  return (
    <WebplotsApplication theme={theme(embedMode)} />
  );
};

ReactDOM.render(<App />, rootEl);
