import { createMuiTheme } from '@material-ui/core/styles';
import { deepOrange, green } from '@material-ui/core/colors';

const theme = (embedMode: boolean, palette: 'light' | 'dark' = 'light') => createMuiTheme({
  palette: {
    type: palette,
    background: {
      default: embedMode ? '#ffffff' : '#f1f1f1'
    },
    primary: {
      main: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#e08d23',
      contrastText: '#ffffff'
    },
    link: {
      main: '#008099'
    },
    success: {
      main: green[700],
      contrastText: '#ffffff'
    },
    warning: {
      main: deepOrange[700],
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '2.4rem',
      fontWeight: 300
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 300
    },
    h3: {
      fontSize: '1.825rem',
      fontWeight: 300
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 300
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 300
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 300,
      textTransform: 'uppercase'
    }
  },
  player: {
    background: 'rgba(0, 0, 0, 0.75)'
  },
  overrides: {
    MuiCardHeader: {
      root: {
        paddingBottom: 0
      }
    },
    MuiLink: {
      root: {
        color: '#008099'
      }
    }
  }
});

export default theme;
