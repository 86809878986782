import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { flashMessageHide } from '../../actions/flashMessage';
import styles from './styles';
import { ComponentType } from 'react';
import { IProps } from './index';
import { TAppState } from '../../store';


const mapStateToProps = (state: TAppState) => {
  return {
    flashMessage: state.flashMessage
  };
};

const mapDispatchToProps = {
  flashMessageHide
};

export default (component: ComponentType<IProps>) => flowRight(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(component);
