import React, { Component } from 'react';
import { Box, Typography, Hidden } from '@material-ui/core';
import { PlayerDimension } from '../../index';
import EmbedOptionsForm, { TEmbedOptionValues, TEmbedSize } from './EmbedOptionsForm';
import decorate from './decorator';
import imageSize from '../../../../utils/imageSize';
import * as QueryString from '../../../../utils/queryString';
import { omit, trim, chain } from 'lodash';
import config from '../../../../config';
import { IPointDataDescription } from '../../index';
import getProductDataItemFromStore from '../../../../utils/getProductDataItemFromStore';
import CodeBlock from '../../../CodeBlock';
import paths from '../../../../router/paths';

type TSize = {
  width: number,
  height: number
};

export type TProps = {
  data: IPointDataDescription,
  selectedDimension: PlayerDimension,
  classes: {
    titleWrapper: string,
    title: string,
    preview: string
  }
}

type TState = {
  originalImageSize: TSize | null,
  previewIframeSize: TSize,
  options: {
    controls_overlay: boolean,
    display_legend: boolean,
    most_recent: boolean,
    aspectRatioLocked: boolean
  } & TEmbedSize
};

const iframeCode = (url: string, height: number, width: number) => {
  return `
    <iframe width="${width}" height="${height}" src="${url}"
      frameBorder="0" allowFullScreen></iframe>
  `;
};

/* eslint-disable jsx-a11y/iframe-has-title */
class EmbedShare extends Component<TProps, TState> {
  state: TState = {
    originalImageSize: null,
    previewIframeSize: {
      width: 700,
      height: 437
    },
    options: {
      width: 0,
      height: 0,
      controls_overlay: true,
      display_legend: false,
      most_recent: true,
      aspectRatioLocked: true
    }
  };

  get embedUrl() {
    const { data, selectedDimension } = this.props;

    if (!data) {
      return '';
    }

    const { packageName, productName, values } = data;
    const { options } = this.state;

    const searchParams = {
      ...(options.most_recent ? omit(values, 'base_time', 'valid_time') : values),
      ...(options.controls_overlay && { controls_overlay: 1 }),
      ...(options.display_legend && { display_legend: 1 }),
      ...(selectedDimension && { player_dimension: selectedDimension.name })
    };

    const path = chain([
      window.location.origin,
      config.mountPath,
      paths.productEmbed(packageName, productName)
    ])
      .map(s => trim(s, '/'))
      .compact()
      .join('/')
      .value();

    const search = QueryString.stringify(searchParams);

    // TODO: url-encode search part
    return [path, search].join('?');
  }

  getOriginalImageSize = async () => {
    const { data } = this.props;

    if (!data) {
      return;
    }

    const { packageName, productName, values } = data;

    const cached = getProductDataItemFromStore(packageName, productName, values);

    if (!cached || !cached.url) {
      return;
    }

    return imageSize(cached.url);
  };

  computeSizeWithAspectRatio = (fixedValueType: 'width' | 'height', value: number): TEmbedSize | null => {
    const { originalImageSize } = this.state;
    if (!originalImageSize) {
      return null;
    }

    const aspectRatio = originalImageSize.width / originalImageSize.height;

    if (fixedValueType === 'width') {
      return {
        width: value,
        height: Math.floor(value / aspectRatio)
      };
    }

    return {
      height: value,
      width: Math.floor(value * aspectRatio)
    };
  };

  onEmbedOptionChange = (values: TEmbedOptionValues) => {
    const { options, previewIframeSize } = this.state;
    let height = values.height;
    let width = values.width;

    if (values.aspectRatioLocked) {
      if (options.height !== values.height) {
        width = this.computeSizeWithAspectRatio('height', height)!.width;
      } else if (options.width !== values.width) {
        height = this.computeSizeWithAspectRatio('width', values.width)!.height;
        height = Math.max(height + (values.controls_overlay ? -1 : 1) * 52, 0);
      }
    }

    let previewIframeHeight = Math.floor(700 / (width / height));

    if (options.controls_overlay !== values.controls_overlay) {
      height = height + (values.controls_overlay ? -1 : 1) * 52;
      previewIframeHeight = previewIframeHeight + (values.controls_overlay ? -1 : 1) * 52;
    }

    this.setState({
      options: {
        ...values,
        width,
        height
      },
      previewIframeSize: {
        ...previewIframeSize,
        height: previewIframeHeight
      }
    });
  };

  componentDidMount(): void {
    const { options } = this.state;

    this.getOriginalImageSize().then(size => {
      if (size) {
        this.setState({
          originalImageSize: size,
          previewIframeSize: {
            width: 700,
            height: size.height * 700 / size.width + (options.controls_overlay ? 0 : 52)
          },
          options: {
            ...options,
            width: 600,
            height: size.height * 600 / size.width + (options.controls_overlay ? 0 : 52)
          }
        });
      }
    });
  }


  render() {
    const { options, previewIframeSize, originalImageSize } = this.state;
    const { width, height } = options;
    const { classes } = this.props;


    if (!originalImageSize) {
      return <div>Loading...</div>;
    }

    const code = iframeCode(this.embedUrl, height, width);

    return (
      <Box display="flex">
        <Hidden mdDown>
          <Box>
            <iframe
              src={this.embedUrl}
              width={previewIframeSize.width}
              height={previewIframeSize.height}
              frameBorder={0}
              className={classes.preview}
            />
          </Box>
        </Hidden>
        <Box padding={2} display="flex" flexDirection="column" justifyContent="space-between">
          <Box marginBottom={4} className={classes.titleWrapper}>
            <Typography variant="h3" className={classes.title}>
              Embed widget
            </Typography>
          </Box>

          <Box marginBottom={4}>
            <EmbedOptionsForm
              values={options}
              onChange={this.onEmbedOptionChange}
            />
          </Box>

          <Box marginBottom={4}>
            <CodeBlock code={code} />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default decorate(EmbedShare);
