import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    width: '100%'
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  label: {
    position: 'static',
    transform: 'none'
  },
  tabs: {
    minHeight: theme.spacing(2),
    borderRadius: '0.3em'
  },
  tab: {
    minHeight: theme.spacing(2),
    padding: '4px 8px',
    minWidth: 'auto'
  },
  compactCoordinatesControl: {
    maxWidth: 400,
    width: 400
  }
});
