import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    background: theme.palette.background.default,
    margin: theme.spacing(-1, -3, 3, -3),
    padding: theme.spacing(2, 3, 2, 3),
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },

  showMoreWrapper: {
    display: 'none',
    cursor: 'pointer',
    margin: theme.spacing(-1, -1, -1, 0),

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%'
    }
  },

  expansionIcon: {
    marginRight: theme.spacing(-2)
  },

  expansionWrapper: {
    overflow: 'hidden',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      height: 0
    }
  },

  expanded: {
    height: 'auto'
  },

  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(2)
    }
  }
});
