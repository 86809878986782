import { Box, Button, ButtonProps, SvgIconProps } from '@material-ui/core';
import React, { ComponentType } from 'react';

interface IProps extends ButtonProps {
  icon: ComponentType<SvgIconProps>
}

const ButtonWithIcon = (props: IProps) => {
  const { icon: IconComponent, children, ...rest } = props;

  return (
    <Button {...rest}>
      <IconComponent fontSize="small" />
      <Box component="span" marginLeft={1}>{children}</Box>
    </Button>
  );
};

export default ButtonWithIcon;
