import { TQuerySearchParams } from '../queryString';
import { isString, reduce } from 'lodash';

const sanitizeParams = (params: TQuerySearchParams) => {
  return reduce(params, (acc, value, key) => {
    if (isString(value)) {
      acc[key] = value;
    }

    return acc;
  }, {} as { [key: string]: string });
};

export {
  sanitizeParams
};
