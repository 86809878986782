import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid/Grid';
import { chunk, noop, omit } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { fetchProduct } from '../../actions/product';
import { fetchProductData } from '../../actions/productData';
import { TProductDetails } from '../../api/product';
import Breadcrumbs from '../../components/Breadcrumbs';
import GridSelect from '../../components/GridSelect';
import PageError from '../../components/PageError';
import PageLoading from '../../components/PageLoading';
import PageWrapper from '../../components/PageWrapper';
import { DimensionProductData, PlayerDimensionDataItem } from '../../components/Player';
import { derivePlayerDataFromProductData } from '../../components/Player/decorator';
import Toolbar from '../../components/Toolbar';
import config from '../../config';
import useAppDispatch from '../../hooks/useAppDispatch';
import paths from '../../router/paths';
import { selectSetting } from '../../selectors/settingsSelector';
import { TAppState } from '../../store';
import BreadcrumbHelpers from '../../utils/BreadcrumbHelpers';
import deriveDimensionsFromAxis from '../../utils/deriveDimensionsFromAxis';
import * as QueryString from '../../utils/queryString';
import { sanitizeParams } from '../../utils/RoutingHelpers';
import * as StoreIndex from '../../utils/storeIndex';
import { Nullable } from '../../utils/types';
import updateUrlSearchParams from '../../utils/updateUrlSearchParams';
import {
  Dimension,
  DimensionChoice,
  DimensionOption,
  DimensionSelectionMapping,
  DimensionType,
  getAxis
} from '../Product';
import DimensionSelectorForm from '../Product/DimensionSelector/DimensionSelectorForm';
import OverviewItemCard from './OverviewItemCard';

const buildLinkPath = (
  packageName: string,
  productName: string,
  dimensionValues: DimensionSelectionMapping,
  viewedDimension: DimensionChoice,
  dimensionOption: DimensionOption
) => {
  const query = {
    ...dimensionValues,
    [viewedDimension.name]: dimensionOption.value,
    player_dimension: viewedDimension.name
  };


  return [paths.productDetails(packageName, productName), QueryString.stringify(query)].join('?');
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formRoot: {
    flexDirection: 'row',
    flexGrow: 1
  }
}));

export type RouteParams = {
  name: string;
  packageName: string;
  axis: string
};

const getViewedDimensions = (dimensions: Array<Dimension>, viewedAxis: string): DimensionChoice | undefined => {
  return dimensions.find(dimension =>
    dimension.type === DimensionType.CHOICE && dimension.name === viewedAxis
  ) as DimensionChoice | undefined;
}

const DimensionOverview = () => {
  const params = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const classes = useStyles();

  const { name: productName, axis: viewedAxis } = params;
  const packageName = config.rootPackageId ? config.rootPackageId : params.packageName;
  const searchParams = sanitizeParams(QueryString.parse(location.search));

  const [error, setError] = useState<Nullable<string>>(null);
  const [viewedDimension, setViewedDimension] = useState<Nullable<DimensionChoice>>(null);
  const [dimensions, setDimensions] = useState<Nullable<Array<Dimension>>>(null);
  const [dimensionValues, setDimensionValues] = useState<Nullable<DimensionSelectionMapping>>(null);

  const gridSize = useSelector((state: TAppState) =>
    selectSetting(state, 'OVERVIEW_GRID_SIZE')) as GridSize;
  const product = useSelector((state: TAppState) =>
    state.productDetails[StoreIndex.productDetails(packageName, productName)]?.data
  );
  const productData = useSelector((state: TAppState) => {
    if (!viewedDimension || !dimensionValues || !dimensions) {
      return {} as DimensionProductData;
    }

    return derivePlayerDataFromProductData(state.productData, packageName, productName, dimensions, viewedDimension, dimensionValues);
  });

  const onOverviewItemFetchRetry = (item: PlayerDimensionDataItem) => {
    if (!viewedDimension || !dimensionValues || !dimensions) {
      return;
    }

    dispatch(fetchProductData(
      packageName,
      productName,
      dimensions,
      viewedDimension.name,
      dimensionValues,
      [item.value]
    ));
  };

  const onAxisChange = (values: TProductDetails['values']) => {
    if (!product) {
      return;
    }

    getAxis(product, values).then(axis => {
      // @ts-ignore
      const { dimensions, selected } = deriveDimensionsFromAxis(axis, values);
      const viewedDimension = getViewedDimensions(dimensions, viewedAxis)

      unstable_batchedUpdates(() => {
        setDimensions(dimensions);
        setDimensionValues(selected);
        setViewedDimension(viewedDimension ?? null);
      });
    });

  };

  useEffect(() => {
    dispatch(fetchProduct(packageName, productName)).then(product => {
      getAxis(product, searchParams).then(axis => {
        const { dimensions, selected } = deriveDimensionsFromAxis(axis, searchParams);
        const viewedDimension = getViewedDimensions(dimensions, viewedAxis)

        unstable_batchedUpdates(() => {
          setDimensions(dimensions);
          setDimensionValues(selected);
          setViewedDimension(viewedDimension ?? null);
        });
      });
    }).catch((error: any) => {
      setError('Product unavailable');
    });
  }, []);

  useEffect(() => {
    if (!viewedDimension || !product || !dimensionValues || !dimensions) {
      return;
    }
    const requestedValues = viewedDimension.values.map(option => option.value);

    const spreadValues = chunk(requestedValues, 3);

    Promise.all(
      spreadValues.map(values => {
        return dispatch(fetchProductData(
          product.package.name,
          product.name,
          dimensions,
          viewedDimension.name,
          dimensionValues,
          values
        ));
      })
    ).catch(noop);
  }, [product, dimensions, dimensionValues, viewedDimension]);

  useEffect(() => {
    updateUrlSearchParams(dimensionValues || {}, 'replace');
  }, [dimensionValues]);

  if (error) {
    return <PageError title="We're sorry. Product is currently unavailable." />;
  }

  if (!product || !viewedDimension || !dimensionValues || !dimensions) {
    return <PageLoading />;
  }

  const activeDimensions = dimensions.filter(d => d.name !== viewedDimension.name);

  return (
    <Fragment>
      <PageWrapper>
        <Grid item>
          <Breadcrumbs paths={BreadcrumbHelpers.buildPaths('overview', product)} />
        </Grid>

        <Box marginBottom={3}>
          <Typography variant="h2">
            Overview
          </Typography>

          <Typography variant="body1" color="textSecondary">
            Dimension: {viewedDimension.label}
          </Typography>
        </Box>

        <Grid container spacing={4}>

          {
            viewedDimension.values.map(dimensionOption =>
              <Grid item xs={12} sm={6} md={4} lg={gridSize} key={dimensionOption.value}>
                <OverviewItemCard
                  label={dimensionOption.label}
                  item={productData[dimensionOption.value.toString()]}
                  href={buildLinkPath(packageName, productName, dimensionValues, viewedDimension, dimensionOption)}
                  onItemFetchRetry={onOverviewItemFetchRetry}
                />
              </Grid>
            )
          }
        </Grid>
      </PageWrapper>

      <Toolbar>
        <Box display="flex" justifyContent="space-between">
          {
            <Box display="flex" justifyContent="space-between">
              <DimensionSelectorForm
                showOverviewLink
                hideRecentLocations
                locationControlVariant="compact"
                orientation="horizontal"
                packageName={packageName}
                productName={product.name}
                dimensions={activeDimensions}
                selectedDimensions={omit(dimensionValues, viewedDimension.name)}
                onChange={onAxisChange}
                classes={{
                  root: classes.formRoot
                }}
              />
            </Box>
          }

          <GridSelect settingId="OVERVIEW_GRID_SIZE" />
        </Box>
      </Toolbar>
    </Fragment>
  );
};

export default DimensionOverview;
