import React from 'react';
import { GridSize } from '@material-ui/core/Grid/Grid';
import { TSettingId } from '../../reducers/settings';
import DropDown from '../../containers/DimensionOverview/DropDown';
import { selectGridSizeSetting } from '../../selectors/settingsSelector';
import { useSelector } from 'react-redux';
import { TAppState } from '../../store';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setSetting } from '../../actions/settings';

export interface IProps {
  settingId: TSettingId;
}

const GRID_OPTIONS = [
  {
    value: 6,
    label: '2 columns'
  }, {
    value: 4,
    label: '3 columns'
  }, {
    value: 3,
    label: '4 columns'
  }, {
    value: 2,
    label: '6 columns'
  }
];

const GridSelect = (props: IProps) => {
  const { settingId } = props;
  const dispatch = useAppDispatch();
  const settingValue = useSelector((state: TAppState) => selectGridSizeSetting(state, settingId));

  const onDropdownChange = (grid: GridSize) => {
    dispatch(setSetting(settingId, grid));
  };

  return (
    <DropDown
      id="columns-per-row-menu"
      options={GRID_OPTIONS}
      value={settingValue}
      onChange={onDropdownChange}
    />
  );
};

export default GridSelect;
