import deriveDimensionsFromAxis, { DimensionSet, TSearchParams } from '../utils/deriveDimensionsFromAxis';
import { TAppState } from '../store';
import * as StoreIndex from '../utils/storeIndex';
import { Dimension, DimensionChoice, DimensionType } from '../containers/Product';
import { find, first, last } from 'lodash';

const DEFAULT_PLAYER_DIMENSION = 'valid_time';
const PLAYER_DIMENSION_SEARCH_PARAMS_KEY = 'player_dimension';

const getDefaultPlayerDimension = (dimensions: Array<DimensionChoice>) => {
  return find(dimensions, { name: DEFAULT_PLAYER_DIMENSION }) || last(dimensions);
};

export const isAnimatedDimensionType = (dimension: Dimension): dimension is DimensionChoice => {
  return dimension.type === DimensionType.CHOICE && dimension.animation;
};

export const derivePlayerDimensionFromSearchParams = (dimensions: Array<Dimension>, searchParams: TSearchParams) => {
  const dimensionValue = searchParams[PLAYER_DIMENSION_SEARCH_PARAMS_KEY];

  const animatableDimensions = dimensions.filter(isAnimatedDimensionType);

  return find(animatableDimensions, { name: dimensionValue }) ||
    getDefaultPlayerDimension(animatableDimensions);
};

export const derivePlayerDimensionOptionFromSearchParams = (dimension: DimensionChoice, searchParams: TSearchParams) => {
  const optionValue = searchParams[dimension.name];

  return find(dimension.values, { value: optionValue }) ||
    first(dimension.values);
};

const playerDataSelector = (
  packageName: string,
  productName: string,
  productAxisState: TSearchParams
) => (state: TAppState) => {
  let dimensionSet: DimensionSet = {
    dimensions: [],
    selected: {}
  };
  let playerSelectedDimension;
  let playerSelectedDimensionOption;

  const productState = state.productDetails[StoreIndex.productDetails(packageName, productName)];
  const product = productState && productState.data;

  if (product) {
    dimensionSet = deriveDimensionsFromAxis(product.axis, productAxisState);
    playerSelectedDimension = derivePlayerDimensionFromSearchParams(dimensionSet.dimensions, productAxisState);
    playerSelectedDimensionOption = playerSelectedDimension &&
      derivePlayerDimensionOptionFromSearchParams(playerSelectedDimension, productAxisState);
  }

  return {
    packageName,
    productName,
    product,
    dimensions: dimensionSet.dimensions,
    selectedDimensions: dimensionSet.selected,
    playerSelectedDimension,
    playerSelectedDimensionOption
  };
};

export default playerDataSelector;
