import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  sizeFieldsGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  }
});
