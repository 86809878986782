import PageWrapper from '../PageWrapper';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import decorate from './decorator';

export interface IProps {
  classes: {
    loading: string;
  }
}

const PageLoading = (props: IProps) => {
  const { classes } = props;

  return (
    <PageWrapper>
      <Box className={classes.loading}>
        <CircularProgress variant="indeterminate" />
      </Box>
    </PageWrapper>
  );
};

export default decorate(PageLoading);
