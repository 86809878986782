import React, { Component } from 'react';
import { TLegendJSON } from '../../../../api/axis';
import decorate from './LegendJSONColorbar/decorator';
import LegendJSONColorbar from './LegendJSONColorbar';
import LegendJSONDoubleLine from './LegendJSONDoubleLine';
import LegendJSONArrow from './LegendJSONArrow';
import LegendJSONGradient from './LegendJSONGradient';

export interface IProps {
  item: TLegendJSON,
  classes: {
    root: string;
    legendWrapper: string;
    legendItem: string;
    value: string;
    minValue: string;
    maxValue: string;
  }
}

const COMPONENTS_BY_TYPE = {
  colorbar: LegendJSONColorbar,
  gradients: LegendJSONGradient,
  'double-line': LegendJSONDoubleLine,
  arrow: LegendJSONArrow
};

class LegendJSON extends Component<IProps> {
  render() {
    const { item } = this.props;
    const Component = COMPONENTS_BY_TYPE[item.data.type];

    if (!Component) {
      return null;
    }

    return <Component item={item} />;
  }
}

export default decorate(LegendJSON);
