import { TropicalCycloneMapConfig } from '../TropicalCycloneMap';
import { ObjectFitSize } from '../../../../../../../components/ProductDataPointMedia';
import { Box } from '@material-ui/core';
import React from 'react';
import { latToPx, lonToPx } from '../utils';

interface Props {
  geoBoundary: {
    left: number
    top: number
    right: number
    bottom: number
  },
  mapConfig: TropicalCycloneMapConfig;
  imageConfig: ObjectFitSize;
}


const TargetArea = (props: Props) => {
  const { geoBoundary, imageConfig, mapConfig } = props;

  const targetArea = {
    left: lonToPx(geoBoundary.left, mapConfig, imageConfig),
    top: latToPx(geoBoundary.top, mapConfig, imageConfig),
    right: lonToPx(geoBoundary.right, mapConfig, imageConfig),
    bottom: latToPx(geoBoundary.bottom, mapConfig, imageConfig)
  };

  return (
    <Box style={{
      width: targetArea.right - targetArea.left,
      height: targetArea.bottom - targetArea.top,
      border: '2px solid red',
      zIndex: 10,
      position: 'absolute',
      top: targetArea.top - 1,
      left: targetArea.left - 1
    }} />
  );
};

export default TargetArea;
