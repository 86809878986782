import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400
  },
  treeWrapper: {
    minHeight: '70vh',
    padding: theme.spacing(2)
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
