import Api from '../api';
import { Announcement } from '../api/announcement';
import { TAppDispatch } from '../store';

export const ANNOUNCEMENTS_FETCH_STARTED = 'ANNOUNCEMENTS_FETCH_STARTED' as const;
export const ANNOUNCEMENTS_FETCH_FAILED = 'ANNOUNCEMENTS_FETCH_FAILED' as const;
export const ANNOUNCEMENTS_FETCH_COMPLETED = 'ANNOUNCEMENTS_FETCH_COMPLETED' as const;

const announcementsFetchCompleted = (data: Array<Announcement>) => ({
  type: ANNOUNCEMENTS_FETCH_COMPLETED,
  data
});

const announcementsFetchFailed = (error: Error) => ({
  type: ANNOUNCEMENTS_FETCH_FAILED,
  error
});

const announcementsFetchStarted = () => ({
  type: ANNOUNCEMENTS_FETCH_STARTED
});

const fetchAnnouncements = () => {
  return async (dispatch: TAppDispatch) => {
    try {
      dispatch(announcementsFetchStarted());
      const data = await Api.Announcement.list();

      dispatch(announcementsFetchCompleted(data));

      return data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(announcementsFetchFailed(error));
      }

      throw error;
    }
  };
};

type AnnouncementActions =
  ReturnType<typeof announcementsFetchStarted> |
  ReturnType<typeof announcementsFetchCompleted> |
  ReturnType<typeof announcementsFetchFailed>;

export {
  type AnnouncementActions,
  fetchAnnouncements
};
