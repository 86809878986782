import CardActionAreaLink from '../../../components/CardActionAreaLink';
import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import decorate from './decorator';
import { PlayerDimensionDataItem } from '../../../components/Player';
import ProductDataPointMedia from '../../../components/ProductDataPointMedia';

export interface IProps {
  item?: PlayerDimensionDataItem;
  href: string;
  label: string;
  onItemFetchRetry?: (item: PlayerDimensionDataItem) => void,
  classes: {
    root: string;
    cardArea: string;
    label: string;
  }
}


class OverviewItemCard extends React.Component<IProps> {
  renderMedia() {
    const { item, onItemFetchRetry } = this.props;

    if (!item) {
      return null;
    }

    return (
      <ProductDataPointMedia
        item={item}
        title={item.label.toString()}
        onRetry={onItemFetchRetry}
        visible
      />
    );
  }

  render() {
    const { href, item, label, classes } = this.props;

    if (!item) {
      return null;
    }

    return (
      <Card className={classes.root}>
        <CardActionAreaLink to={href} className={classes.cardArea}>
          {
            this.renderMedia()
          }

          <CardContent className={classes.label}>
            {label}
          </CardContent>
        </CardActionAreaLink>
      </Card>
    );
  }
}

export default decorate(OverviewItemCard);
