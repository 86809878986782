import React, { Component } from 'react';

import {
  Select as MuiSelect,
  SelectProps,
  ThemeProvider
} from '@material-ui/core';
import theme from '../../../../theme';

export interface IProps extends SelectProps {
}

class Select extends Component<IProps> {
  render() {
    return (
      <ThemeProvider theme={theme(false, 'dark')}>
        <MuiSelect
          {...this.props}
        />
      </ThemeProvider>
    );
  }
}

export default Select;
