import { ThunkDispatch } from 'redux-thunk';
import { TAppState } from '../store';
import { Action } from 'redux';
import { TGroup } from '../api/group';
import Api from '../api';
import ErrorHelpers from '../utils/ErrorHelpers';

export const GROUP_FETCH_STARTED: 'GROUP_FETCH_STARTED' = 'GROUP_FETCH_STARTED';
export const GROUP_FETCH_COMPLETED: 'GROUP_FETCH_COMPLETED' = 'GROUP_FETCH_COMPLETED';
export const GROUP_FETCH_FAILED: 'GROUP_FETCH_FAILED' = 'GROUP_FETCH_FAILED';


export interface IGroupFetchStartAction extends Action<typeof GROUP_FETCH_STARTED> {
  packageName: string;
  groupName: string;
}

export interface IGroupFetchCompleteAction extends Action<typeof GROUP_FETCH_COMPLETED> {
  packageName: string;
  groupName: string;
  group: TGroup;
}

export interface IGroupFetchFailAction extends Action<typeof GROUP_FETCH_FAILED> {
  packageName: string;
  groupName: string;
  error: Error;
}

const groupFetchStart = (packageName: string, groupName: string): IGroupFetchStartAction => ({
  type: GROUP_FETCH_STARTED,
  packageName,
  groupName
});

const groupFetchComplete = (packageName: string, group: TGroup): IGroupFetchCompleteAction => ({
  type: GROUP_FETCH_COMPLETED,
  packageName,
  groupName: group.name,
  group
});


const groupFetchFail = (packageName: string, groupName: string, error: Error): IGroupFetchFailAction => ({
  type: GROUP_FETCH_FAILED,
  packageName,
  groupName,
  error
});

const fetchGroup = (packageName: string, groupName: TGroup['name']) => {
  return async (dispatch: ThunkDispatch<TAppState, void, Action>): Promise<TGroup> => {
    try {
      dispatch(groupFetchStart(packageName, groupName));

      const group = await Api.Group.fetch(packageName, groupName);

      dispatch(groupFetchComplete(packageName, group));

      return group;
    } catch (error) {
      if (ErrorHelpers.isError(error)) {
        dispatch(groupFetchFail(packageName, groupName, error));
      }

      throw error;
    }
  };
};

export {
  fetchGroup
};
