import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react';
import {
  TCycloneOption,
  TCycloneRegionOption,
  TCycloneSearchParams,
  TCycloneSearchResult,
  TCycloneYearOption
} from '../../../../../../api/cyclone';
import { Nullable } from '../../../../../../utils/types';
import Api from '../../../../../../api';
import { isEmpty, map, omitBy } from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';

interface IProps {
  onChange: (value: TCycloneOption) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 400,

    [theme.breakpoints.up('sm')]: {
      width: 600
    }
  },
  resultsContent: {
    paddingTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      paddingTop: 0
    }
  },
  selectControl: {
    flexGrow: 1,
    minWidth: 100
  }
}));

const CycloneSearch = (props: IProps) => {
  const { onChange } = props;
  const [selectedYear, setSelectedYear] = useState<Nullable<TCycloneYearOption['value']>>(null);
  const [yearOptions, setYearOptions] = useState<Nullable<Array<TCycloneYearOption>>>(null);
  const [selectedRegion, setSelectedRegion] = useState<Nullable<TCycloneRegionOption['value']>>(null);
  const [regionOptions, setRegionOptions] = useState<Nullable<Array<TCycloneRegionOption>>>(null);
  const [searchResults, setSearchResults] = useState<Nullable<Array<TCycloneOption>>>(null);
  const classes = useStyles();

  useEffect(() => {
    Api.Cyclone.filterOptions().then((response) => {
      const years = map(response.years, (option) => ({
        value: option.value.toString(),
        label: option.label.toString()
      }));

      unstable_batchedUpdates(() => {
        setYearOptions(years);
        setRegionOptions(response.regions);

        setSelectedYear(years[0].value);
        setSelectedRegion(response.regions[0].value);
      });
    });
  }, []);

  useEffect(() => {
    if (selectedYear !== null || selectedRegion !== null) {
      const params = omitBy<{ year: Nullable<string>; region: Nullable<string> }>({
        year: selectedYear,
        region: selectedRegion
      }, isEmpty) as Partial<TCycloneSearchParams>;

      Api.Cyclone.search(params).then((result: TCycloneSearchResult) => {
        setSearchResults(result.cyclones);
      });
    }
  }, [selectedYear, selectedRegion]);

  const onYearChange = (event: ChangeEvent<unknown>) => {
    const { value } = event.target as HTMLSelectElement;

    setSelectedYear(value);
  };

  const onRegionChange = (event: ChangeEvent<unknown>) => {
    const { value } = event.target as HTMLSelectElement;

    setSelectedRegion(value);
  };

  const onCycloneSelect = useCallback((cyclone: TCycloneOption) => {
    onChange(cyclone);
  }, [onChange]);


  if (!yearOptions || !regionOptions) {
    return null;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={4}>
        <Box>
          <FormControl fullWidth style={{ marginBottom: 8 }}>
            <InputLabel variant="standard" htmlFor="year">
              Year
            </InputLabel>
            <Select
              name="year"
              value={selectedYear}
              onChange={onYearChange}
              className={classes.selectControl}
            >
              {yearOptions.map(option => <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="region">
              Region
            </InputLabel>
            <Select
              name="region"
              value={selectedRegion}
              onChange={onRegionChange}
              className={classes.selectControl}
            >
              {regionOptions.map(option => <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={8} className={classes.resultsContent}>
        <Box paddingX={2}>
          {
            searchResults === null &&
            <Box display="flex" justifyContent="center" margin={2}>
              <Typography variant="body2" color="textSecondary">
                Please use filters to select criteria
              </Typography>
            </Box>
          }

          {
            searchResults && searchResults.length === 0 &&
            <Box display="flex" justifyContent="center" margin={2}>
              <Typography variant="body2" color="textSecondary">
                Sorry, we could not find any within selected criteria.
              </Typography>
            </Box>
          }

          {
            searchResults && searchResults.length > 0 &&
            <Fragment>
              <Typography variant="body2" color="textSecondary">
                Cyclones found within selected criteria:
              </Typography>

              <List dense>
                {searchResults.map(cyclone =>
                  <ListItem button key={cyclone.value} onClick={() => onCycloneSelect(cyclone)}>
                    <ListItemText
                      primary={cyclone.label}
                    />
                  </ListItem>
                )}
              </List>
            </Fragment>
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default CycloneSearch;
