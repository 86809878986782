import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {},
  notebookHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  notebookContent: {
    lineHeight: 1.8
  },
  jupiterIcon: {
    marginRight: '0.5em',
    height: '1.5em',
    width: 'auto'
  },
  notebookServiceIcon: {
    width: 'auto',
    height: '0.8em',
    verticalAlign: 'text-bottom'
  }
});
