import React, { Component } from 'react';
import { WithStyles, Box } from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export interface IProps extends WithStyles<typeof styles> {
}

class ErrorWrapper extends Component<IProps> {
  render() {
    const { classes } = this.props;

    return (
      <Box className={classes.root}>
        <ErrorOutlineIcon className={classes.icon} />

        Sorry, it seems we were unable to load the content.
      </Box>
    );
  }
}

export default decorate(ErrorWrapper);
