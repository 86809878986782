import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { isEqual, noop, omit, throttle } from 'lodash';
import React, { Component, createRef, Fragment } from 'react';
import Api from '../../api';
import { TLegend } from '../../api/axis';
import { TProductLicence } from '../../api/product';
import { Dimension, DimensionOption, DimensionSelectionMapping, DimensionType } from '../../containers/Product';
import { ProductDataItemStatus } from '../../reducers/productData';
import { isAnimatedDimensionType } from '../../selectors/playerDataSelector';
import * as DimensionUtils from '../../utils/DimensionUtils';
import filterProductDataParams from "../../utils/filterProductDataParams";
import { Nullable } from '../../utils/types';
import Fullscreen from '../Fullscreen';
import Markdown from '../Markdown';
import ProductFeature, { IProductFeatureSpec } from '../ProductFeature';
import decorate, { mapDispatchToProps } from './decorator';
import PlayerInfo from './Info';
import Media from './Media';
import PlayerDimensionsControl from './PlayerDimensionsControl';
import WidgetControls from './WidgetControls';

export type PlayerDimensionOption = {
  value: string | number;
  label: string;
}

export type PlayerDimension = {
  type: DimensionType.CHOICE;
  name: string;
  label: string;
  animation: boolean;
  dynamic: boolean;
  values: Array<PlayerDimensionOption>;
}

export type PlayerDimensionDataItem = {
  status: ProductDataItemStatus;
  url: string | null;
  value: string | number;
  label: string | number;
  legend: Array<TLegend>;
  info?: string;
  error?: string;
  click?: {
    url: string;
    tooltip: string;
    config: {
      widget: Nullable<'epsgrams'>;
      tooltip: string;
      title: string;
    }
    navigation: {
      crs: string;
      xmin: number;
      ymin: number;
      width: number;
      height: number;
    }
    axis: Array<{
      values: Array<{
        value: string;
        label: string;
      }>
      name: string;
      title: string;
    }>
  };
};

export interface DimensionProductData {
  [key: string]: PlayerDimensionDataItem
}

export interface IPointDataDescription {
  packageName: string;
  productName: string;
  values: {
    [key: string]: string | number
  }
}

export type TProps = {
  embedMode?: boolean,
  controlsOverlay?: boolean,
  displayLegendOnMount?: boolean,
  packageName: string,
  productName: string,
  productTitle: string,
  dashboardShare?: boolean,
  notebook: boolean;
  script: boolean;
  licence: Nullable<TProductLicence>,
  productValues: DimensionSelectionMapping,
  onProductValuesChange?: (values: DimensionSelectionMapping) => void;
  dimensions: Array<Dimension>,
  selectedDimension: PlayerDimension,
  onDimensionChange?: (dimension: PlayerDimension) => void,
  selectedDimensionOption: PlayerDimensionOption,
  onDimensionOptionChange?: (option: PlayerDimensionOption) => void;
  dimensionProductData: DimensionProductData,
  classes: {
    root: string,
    rootEmbedded: string,
    infoExtra: string
  }
} & ReturnType<typeof mapDispatchToProps>

type TState = {
  fullScreenEnabled: boolean;
  legendOpen: boolean;
  playerDimensionsControlOpen: boolean;
  uiVisible: boolean;
  featureSpec: null | IProductFeatureSpec;
}

const deriveProductValues = (values: DimensionSelectionMapping, selectedDimension: Dimension): {
  [dimensionName: string]: DimensionOption['value']
} => {
  // const val = {...values};
  //
  // dimensions.forEach(dimension => {
  //   if (dimension.type ===  DimensionType.MULTIPLE_CHOICE) {
  //     const selectedValues = values[dimension.name];
  //     const enabledValues = dimension.values
  //       .filter(v => v.status === 'enabled')
  //       .map(v => v.value);
  //
  //     if (typeof selectedValues === "number") {
  //       return;
  //     }
  //
  //     val[dimension.name] = selectedValues.split(',').filter(v => enabledValues.includes(v)).join(',');
  //   }
  // });
  //
  return omit(values, selectedDimension.name);
};

const triggerBackgroundDimensionPreload = (
  packageName: string,
  productName: string,
  selectedDimension: PlayerDimension,
  productValues: DimensionSelectionMapping,
  startIndex: number = 0
): Promise<void> => {
  const batchSize = 5;
  let index = startIndex;
  const n = selectedDimension.values.length;

  return dimensionPreload(packageName, productName, selectedDimension, productValues, selectedDimension.values.slice(index, index + batchSize)).then(() => {
    index = index + batchSize;

    if (index < n) {
      return triggerBackgroundDimensionPreload(packageName, productName, selectedDimension, productValues, index + batchSize);
    } else {
      return undefined;
    }
  });
};

const dimensionPreload = (
  packageName: string,
  productName: string,
  selectedDimension: PlayerDimension,
  productValues: DimensionSelectionMapping,
  values: Array<PlayerDimensionOption>
) => {
  return Api.Axis.preload(packageName, productName, selectedDimension.name, {
    ...omit(productValues, selectedDimension.name),
    values: values.map(v => v.value).join(',')
  });
};


class Player extends Component<TProps, TState> {
  state: TState;

  constructor(props: TProps) {
    super(props);

    this.state = {
      fullScreenEnabled: false,
      legendOpen: Boolean(props.displayLegendOnMount),
      playerDimensionsControlOpen: false,
      uiVisible: true,
      featureSpec: null
    };
  }

  $root = createRef<HTMLDivElement>();

  loadAxisData(values: Array<string | number>) {
    const {
      productValues,
      packageName,
      productName,
      selectedDimension,
      dimensions
    } = this.props;

    return this.props.fetchProductData(
      packageName,
      productName,
      dimensions,
      selectedDimension.name,
      deriveProductValues(productValues, selectedDimension),
      values
    ).catch(noop);
  }

  loadCurrentDimensionOption() {
    const { selectedDimensionOption } = this.props;

    return this.loadAxisData([selectedDimensionOption.value]);
  }

  loadCurrentDimensionOptionWithPreload() {
    return this.loadCurrentDimensionOption().then(() => {
      return this.preload();
    });
  }

  preload() {
    const {
      productValues,
      packageName,
      productName,
      dimensions,
      selectedDimension,
      selectedDimensionOption
    } = this.props;

    this.props.preloadProductData(
      packageName,
      productName,
      dimensions,
      {
        ...deriveProductValues(productValues, selectedDimension),
        [selectedDimension.name]: selectedDimensionOption.value
      },
      selectedDimension,
      selectedDimensionOption
    ).catch(noop);
  }

  backgroundPreload() {
    const { packageName, productName, dimensions, productValues, selectedDimension, selectedDimensionOption } = this.props;
    return triggerBackgroundDimensionPreload(packageName, productName, selectedDimension, filterProductDataParams(dimensions, productValues));

    // console.log(packageName, productName, selectedDimension.name, {
    //   ...omit(productValues, selectedDimension.name),
    //   // [selectedDimension.name]: selectedDimensionOption.value,
    //   values: selectedDimension.values.map(v => v.value)
    // });
    //
    // Api.Axis.preload(packageName, productName, selectedDimension.name, {
    //   ...omit(productValues, selectedDimension.name),
    //   values: selectedDimension.values.map(v => v.value).slice(0, 5).join(',')
    // });
  }

  triggerFullScreen(enabled: boolean) {
    this.setState({ fullScreenEnabled: enabled });
  }

  triggerLegend(open: boolean) {
    this.setState({ legendOpen: open });
  }

  triggerPlayerDimensionsControl(open: boolean) {
    this.setState({
      playerDimensionsControlOpen: open
    });
  }

  onItemClick = (item: PlayerDimensionDataItem, x: number, y: number) => {
    const { productValues } = this.props;

    this.setState({
      featureSpec: {
        item,
        x,
        y,
        productValues
      }
    });
  }

  handleLegendClose = () => {
    this.triggerLegend(false);
  }

  handlePlayerValuesChange = (values: DimensionSelectionMapping) => {
    const { onProductValuesChange } = this.props;

    onProductValuesChange && onProductValuesChange(values);
  }

  handlePlayerDimensionChange = (dimension: PlayerDimension) => {
    const { onDimensionChange } = this.props;

    onDimensionChange && onDimensionChange(dimension);
  };

  handleDimensionOptionChange = (option: PlayerDimensionOption) => {
    const { onDimensionOptionChange } = this.props;

    onDimensionOptionChange && onDimensionOptionChange(option);
  };

  handlePlayerDimensionControlClose = () => {
    this.triggerPlayerDimensionsControl(false);
  }

  showUi = throttle(() => {
    this.setState({
      uiVisible: true
    });
  }, 500);

  hideUi = throttle(() => {
    this.setState({
      uiVisible: false
    });
  }, 500);

  handlePlay = () => {
    this.backgroundPreload().catch(noop);

    this.hideUi();
  };

  handlePause = () => {
    this.showUi();
  };

  handleItemFetchRetry = (item: PlayerDimensionDataItem) => {
    this.loadAxisData([item.value]).catch(noop);
  }

  addUiVisibilityListeners() {
    if (this.$root.current) {
      this.$root.current.addEventListener('mouseenter', this.showUi);
      this.$root.current.addEventListener('mouseleave', this.hideUi);
    }
  }

  removeVisibilityListeners() {
    if (this.$root.current) {
      this.$root.current.removeEventListener('mouseenter', this.showUi);
      this.$root.current.removeEventListener('mouseleave', this.hideUi);
    }
  }

  componentDidMount() {
    this.addUiVisibilityListeners();
    this.loadCurrentDimensionOptionWithPreload().catch(noop);
  }

  componentDidUpdate(prevProps: TProps) {
    const { selectedDimension, dimensions, productValues, selectedDimensionOption, dimensionProductData } = this.props;

    const hasSelectedDimensionChanged = !isEqual(prevProps.selectedDimension, selectedDimension);
    const haveProductValuesChanged = !isEqual(
      deriveProductValues(prevProps.productValues, prevProps.selectedDimension),
      deriveProductValues(productValues, selectedDimension)
    );
    const hasSelectedDimensionOptionChanged = !isEqual(prevProps.selectedDimensionOption, selectedDimensionOption);

    if (
      hasSelectedDimensionChanged ||
      haveProductValuesChanged
    ) {
      this.loadCurrentDimensionOption().catch(noop);
    }

    if (hasSelectedDimensionOptionChanged) {
      if (!dimensionProductData[selectedDimensionOption.value]) {
        this.loadCurrentDimensionOptionWithPreload().catch(noop);
      } else {
        this.preload();
      }
    }
  }

  componentWillUnmount() {
    this.removeVisibilityListeners();
  }

  info() {
    const { dimensions, productValues, dimensionProductData, selectedDimensionOption, classes } = this.props;

    const mapping = DimensionUtils.withValues(dimensions, filterProductDataParams(dimensions, productValues));

    const currentDataItem = dimensionProductData[selectedDimensionOption.value.toString()];

    return (
      <Fragment>
        <Box>
          {
            mapping.map(item =>
              <Typography variant="body1" key={item.name} gutterBottom>
                {item.label}: {item.value}
              </Typography>
            )
          }
        </Box>

        {
          currentDataItem?.info &&
          <Box className={classes.infoExtra}>
            <Markdown source={currentDataItem.info} />
          </Box>
        }
      </Fragment>
    );
  }

  render() {
    const { fullScreenEnabled, legendOpen, uiVisible, featureSpec, playerDimensionsControlOpen } = this.state;
    const {
      embedMode,
      controlsOverlay,
      productTitle,
      dashboardShare,
      notebook,
      script,
      licence,
      dimensions,
      selectedDimension,
      selectedDimensionOption,
      dimensionProductData,
      productValues,
      packageName,
      productName,
      classes
    } = this.props;

    const rootClassName = classNames(classes.root, embedMode && classes.rootEmbedded);
    const animatableDimensions = dimensions.filter(isAnimatedDimensionType);

    return (
      <Fullscreen
        enabled={fullScreenEnabled}
        onChange={
          (isFullscreen) => {
            this.triggerFullScreen(isFullscreen);
          }
        }
      >
        <div className={rootClassName} ref={this.$root}>
          <PlayerInfo
            visible={!playerDimensionsControlOpen}
            title={productTitle}
            embedMode={embedMode}
            dashboardShare={dashboardShare}
            selectedDimension={selectedDimension}
            notebook={notebook}
            script={script}
            licence={licence}
            pointData={{
              packageName,
              productName,
              values: filterProductDataParams(dimensions, productValues)
            }}
          >
            {this.info()}
          </PlayerInfo>

          <Media
            controlsOverlay={controlsOverlay}
            uiVisible={uiVisible}
            legendOpen={legendOpen}
            onLegendClose={this.handleLegendClose}
            embedMode={embedMode}
            data={dimensionProductData}
            productValues={productValues}
            selectedDimensionOption={selectedDimensionOption}
            onItemClick={this.onItemClick}
            onItemFetchRetry={this.handleItemFetchRetry}
          />

          <WidgetControls
            embedMode={embedMode}
            asOverlay={controlsOverlay}
            visible={uiVisible}
            dimensions={animatableDimensions}
            selectedDimension={selectedDimension}
            dimensionProductData={dimensionProductData}
            onPlay={this.handlePlay}
            onPause={this.handlePause}
            onDimensionChange={this.handlePlayerDimensionChange}
            dimensionOptions={selectedDimension.values}
            selectedDimensionOption={selectedDimensionOption}
            onDimensionOptionChange={this.handleDimensionOptionChange}
            onFullScreenChange={(enabled) => this.triggerFullScreen(enabled)}
            fullScreenEnabled={fullScreenEnabled}
            onLegendChange={(open) => this.triggerLegend(open)}
            legendOpen={legendOpen}
            onDimensionControlToggle={(open) => this.triggerPlayerDimensionsControl(open)}
            dimensionControlOpen={playerDimensionsControlOpen}
          />

          {
            (embedMode || fullScreenEnabled) &&
            <PlayerDimensionsControl
              open={playerDimensionsControlOpen}
              controlsOverlay={controlsOverlay}
              packageName={packageName}
              productName={productName}
              dimensions={dimensions}
              selectedDimensions={productValues}
              onChange={this.handlePlayerValuesChange}
              nativeSelect={fullScreenEnabled}
              onClose={this.handlePlayerDimensionControlClose}
              animatableDimensions={animatableDimensions}
              selectedAnimatedDimension={selectedDimension}
              onAnimatedDimensionChange={this.handlePlayerDimensionChange}
            />
          }

          <ProductFeature
            packageName={packageName}
            productName={productName}
            spec={featureSpec}
            onClose={() => this.setState({ featureSpec: null })}
          />

        </div>
      </Fullscreen>
    );
  }
}

export default decorate(Player);
