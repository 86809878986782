import React, { Component } from 'react';
import { SliderItem } from 'react-compound-slider';
import decorate from './decorator';

export interface IProps {
  tick: SliderItem;
  classes: {
    root: string;
  }
}

class Tick extends Component<IProps> {
  render() {
    const { tick, classes } = this.props;

    return (
      <div
        className={classes.root}
        style={{
          left: `${tick.percent}%`
        }}
      />
    );
  }
}

export default decorate(Tick);

