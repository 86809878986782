import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import CardActionAreaLink from '../../../components/CardActionAreaLink';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { TPackage } from '../../../api/package';
import paths from '../../../router/paths';

interface IProps extends WithStyles<typeof styles> {
  value: TPackage;
}

const PackageCard = (props: IProps) => {
  const { classes, value } = props;
  const { name, title, thumbnail } = value;
  const redirectPath = paths.packageDetails(name);

  return (
    <Card className={classes.root}>
      <CardActionAreaLink to={redirectPath} className={classes.contentArea}>
        <CardMedia
          className={classes.media}
          image={thumbnail}
          title={title}
        />
        <CardContent>
          <Typography variant="h5">
            {title}
          </Typography>
        </CardContent>
      </CardActionAreaLink>
      <CardActions>
        <Button size="small" color="secondary" component={Link} to={redirectPath}>Explore products</Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(PackageCard);
