import Packages from '../containers/Packages';
import Package from '../containers/Package';
import Widget from '../containers/Widget';
import React from 'react';
import { Route, Switch } from 'react-router';
import paths from './paths';
import config from '../config';
import DimensionOverview from '../containers/DimensionOverview';
import NotFound from '../containers/NotFound';
import ChartSet from '../containers/ChartSet';
import Product from '../containers/Product';

const Router = () => {
  const { rootPackageId, overviewEnabled } = config;

  if (rootPackageId) {
    return (
      <Switch>
        <Route path={paths.root} exact component={Package} />
        <Route path={paths.productDetails(':packageName', ':name')} exact component={Product} />

        {
          overviewEnabled &&
          <Route path={paths.productDimensionOverview(':packageName', ':name', ':axis')}
            exact component={DimensionOverview}
          />
        }

        <Route path={paths.productEmbed(':packageName', ':productName')} exact component={Widget} />

        <Route path={paths.chartSet(':packageName', ':groupName')} exact component={ChartSet} />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={paths.root} exact component={Packages} />
      <Route path={paths.packageDetails(':name')} exact component={Package} />
      <Route path={paths.productDetails(':packageName', ':name')} exact component={Product} />
      {
        overviewEnabled &&
        <Route path={paths.productDimensionOverview(':packageName', ':name', ':axis')}
          exact component={DimensionOverview}
        />
      }

      <Route path={paths.productEmbed(':packageName', ':productName')} exact component={Widget} />

      <Route path={paths.chartSet(':packageName', ':groupName')} exact component={ChartSet} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Router;
