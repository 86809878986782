import axios from 'axios';
import config from '../config';

const DEFAULT_TIMEOUT = 29 * 1000;

const client = axios.create({
  baseURL: config.apiUrl,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: config.nodeEnv !== 'production'
});

export default client;
