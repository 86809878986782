import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import React, { ChangeEvent, isValidElement, Fragment } from 'react';
import Api from '../../../../../../api';
import { TCity } from '../../../../../../api/city';
import { compact, find, noop } from 'lodash';
import { TLocation } from '../../../../../../reducers/recentLocationsByProduct';

interface IProps {
  initial?: TLocation;
  onChange: (latitude: string, longitude: string, city: string) => void
}

interface IState {
  loading: boolean;
  ready: boolean;
  initialCity: TCity | null;
  cities: Array<TCity>;
}

const optionLabel = (option: TCity) => {
  const { name, state, country } = option;

  return compact([name, state, country]).join(', ');
};

class CityLookup extends React.Component<IProps, IState> {
  state: IState = {
    loading: false,
    initialCity: null,
    ready: false,
    cities: []
  };

  fetchCities(name: string) {
    return Api.City.list({
      name: name,
      limit: 10
    });
  }

  onInputChange = (event: ChangeEvent<{}>, newInputValue: string) => {
    this.setState({
      loading: true
    }, () => {
      this.fetchCities(newInputValue).then(cities => {
        this.setState({ cities, loading: false });
      }).catch(noop);
    });
  }

  onCitySelect = (event: ChangeEvent<{}>, value: TCity | null) => {
    if (value) {
      this.props.onChange(value.latitude, value.longitude, value.name);
    }
  }

  componentDidMount() {
    const { initial } = this.props;

    if (!initial) {
      this.setState({
        ready: true
      });

      return;
    }

    const { name, latitude, longitude } = initial;

    this.fetchCities(name).then(cities => {
      const found = find(
        cities,
        city => city.latitude === latitude && city.longitude === longitude
      );

      this.setState({
        ready: true,
        initialCity: found ? found : {
          altitude: 0,
          country: '',
          state: '',
          latitude: '0',
          longitude: '0',
          name
        }
      });
    });
  }

  render() {
    const { cities, loading, initialCity, ready } = this.state;

    if (!ready) {
      return null;
    }

    return (
      <Autocomplete
        autoComplete
        includeInputInList
        filterSelectedOptions
        defaultValue={initialCity}
        renderInput={params => {
          const autocompleteAdornment = params.InputProps.endAdornment;

          return <TextField
            {...params}
            label="City"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {
                    isValidElement(autocompleteAdornment) &&
                    <InputAdornment
                      position="end"
                      className={autocompleteAdornment.props.className}
                      style={{ height: 'auto' }}
                    >
                      {loading && <CircularProgress color="inherit" size={14} />}
                      {autocompleteAdornment.props.children}
                    </InputAdornment>
                  }
                </Fragment>
              )
            }}
          />;
        }
        }
        options={cities}
        getOptionLabel={optionLabel}
        onInputChange={this.onInputChange}
        onChange={this.onCitySelect}
        popupIcon={null}
      />
    );
  }
}

export default CityLookup;
