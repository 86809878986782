import React, { Component, Fragment } from 'react';
import { SliderItem } from 'react-compound-slider';
import decorate from './decorator';
import Tooltip from '../Tooltip';
import { SliderLabelMap } from '../index';

export interface IProps {
  domain: Array<number>;
  handle: SliderItem;
  isActive: boolean;
  disabled?: boolean;
  getHandleProps: (id: string, config: object) => object;
  sliderWidth: number | null;
  labelMap: SliderLabelMap;
  classes: {
    root: string;
  };
}

class Handle extends Component<IProps> {
  static defaultProps = {
    disabled: false
  };

  state = {
    mouseOver: false
  };

  onMouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  onMouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  render() {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      isActive,
      disabled,
      classes,
      getHandleProps,
      sliderWidth,
      labelMap
    } = this.props;

    const { mouseOver } = this.state;
    const label = labelMap[value.toString()];

    return (
      <Fragment>
        {
          (mouseOver || isActive) && !disabled &&
          <Tooltip
            percent={percent}
            sliderWidth={sliderWidth}
            text={label}
          />
        }

        <div
          role="slider"
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          className={classes.root}
          style={{ left: `${percent}%` }}
          {...getHandleProps(id, {
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave
          })}
        />
      </Fragment>
    );
  }
}

export default decorate(Handle);
