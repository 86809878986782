import { PlayerDimensionOption } from '../../components/Player';
import * as DateFormatter from '../DateFormatter';

const TIME_DECODING_PATTERN = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})$/;

const axisValueToDateTime = (value: string): number | null => {
  const matches = value.match(TIME_DECODING_PATTERN);

  if (!matches) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, year, month, day, hour, minutes] = matches;

  return Date.UTC(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    parseInt(hour, 10),
    parseInt(minutes, 10)
  );
};

const timestampToAxisValue = (timestamp: number): string => {
  return DateFormatter.axisTimeString(timestamp);
};

const dimensionOptionsToTimestamps = (dimensionOptions: Array<PlayerDimensionOption>): Array<number> => {
  return dimensionOptions.map(d => axisValueToDateTime(d.value.toString())!);
};

export {
  axisValueToDateTime,
  timestampToAxisValue,
  dimensionOptionsToTimestamps
};
