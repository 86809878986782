import { Action } from 'redux';

export const PRODUCT_COLLECTION_ITEM_ADDED: 'PRODUCT_COLLECTION_ITEM_ADDED' = 'PRODUCT_COLLECTION_ITEM_ADDED';

export interface IAddProductToCollectionAction extends Action<typeof PRODUCT_COLLECTION_ITEM_ADDED> {
  packageName: string;
  productName: string;
}

const addProductToCollectionCompleted = (packageName: string, productName: string): IAddProductToCollectionAction => {
  return {
    type: PRODUCT_COLLECTION_ITEM_ADDED,
    packageName,
    productName
  };
};

export const PRODUCT_COLLECTION_ITEM_REMOVE_COMPLETED = 'PRODUCT_COLLECTION_ITEM_REMOVE_COMPLETED' as const;

export interface IRemoveProductFromCollectionAction extends Action<typeof PRODUCT_COLLECTION_ITEM_REMOVE_COMPLETED> {
  packageName: string;
  productName: string;
}

const removeProductFromCollectionCompleted = (
  packageName: string,
  productName: string
): IRemoveProductFromCollectionAction => {
  return {
    type: PRODUCT_COLLECTION_ITEM_REMOVE_COMPLETED,
    packageName,
    productName
  };
};

export const PRODUCT_COLLECTION_CLEAR_COMPLETED = 'PRODUCT_COLLECTION_CLEAR_COMPLETED' as const;

export interface IClearProductCollectionAction extends Action<typeof PRODUCT_COLLECTION_CLEAR_COMPLETED> {
  packageName: string;
}

const clearProductCollectionCompleted = (packageName: string): IClearProductCollectionAction => {
  return {
    type: PRODUCT_COLLECTION_CLEAR_COMPLETED,
    packageName
  };
};

const addProductToCollection = (packageName: string, productName: string) => {
  return addProductToCollectionCompleted(packageName, productName);
};

const removeProductFromCollection = (packageName: string, productName: string) => {
  return removeProductFromCollectionCompleted(packageName, productName);
};

const clearProductCollection = (packageName: string) => {
  return clearProductCollectionCompleted(packageName);
};

export {
  addProductToCollection,
  removeProductFromCollection,
  clearProductCollection
};
