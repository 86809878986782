import React, { Component, Fragment, ReactNode, MouseEvent, createRef } from 'react';
import decorate from './decorator';
import { Box, CircularProgress, Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import { PlayerDimensionDataItem } from '../Player';
import { ProductDataItemStatus } from '../../reducers/productData';
import preloadImage from '../../utils/preloadImage';
import ReplayIcon from '@material-ui/icons/Replay';
import { isEqual } from 'lodash';

export interface IPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

export type TProps = {
  item?: PlayerDimensionDataItem,
  title: string,
  visible: boolean,
  onClick?: (item: PlayerDimensionDataItem, position: IPosition) => void,
  onRetry?: (item: PlayerDimensionDataItem) => void,
  classes: {
    root: string;
    loading: string;
    notAvailable: string;
    retryButton: string;
    visible: string;
    interactive: string;
  }
};

type TState = {
  status: null | 'loading' | 'loaded' | 'error'
};

export interface ObjectFitSize {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const getObjectFitSize = (image: HTMLImageElement): ObjectFitSize => {
  const containerWidth = image.width;
  const containerHeight = image.height;
  const width = image.naturalWidth;
  const height = image.naturalHeight;
  const doRatio = width / height;
  const cRatio = containerWidth / containerHeight;
  let targetWidth = 0;
  let targetHeight = 0;
  const test = doRatio > cRatio;

  if (test) {
    targetWidth = containerWidth;
    targetHeight = targetWidth / doRatio;
  } else {
    targetHeight = containerHeight;
    targetWidth = targetHeight * doRatio;
  }

  targetWidth = targetWidth || 0;
  targetHeight = targetHeight || 0;

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2
  };
};

const calculateImageClickCoordinates = (event: MouseEvent<HTMLImageElement>, image: HTMLImageElement) => {
  const imageCoords = image.getBoundingClientRect();
  const clickPosition = {
    x: event.clientX - imageCoords.x,
    y: event.clientY - imageCoords.y
  };
  const fittedImageCoords = getObjectFitSize(image);

  const position = {
    x: clickPosition.x - fittedImageCoords.x,
    y: clickPosition.y - fittedImageCoords.y,
    width: fittedImageCoords.width,
    height: fittedImageCoords.height
  };

  // return null if click outside of fitted image
  if (
    position.x < 0 || position.x > fittedImageCoords.width ||
    position.y < 0 || position.y > fittedImageCoords.height
  ) {
    return null;
  }

  return position;
};


class ProductDataPointMedia extends Component<TProps, TState> {
  $image = createRef<HTMLImageElement>();

  state = {
    status: null
  };

  renderLoading() {
    const { classes, visible } = this.props;

    return (
      <Box className={classNames(classes.loading, visible && classes.visible)}>
        <CircularProgress size={48} variant="indeterminate" />
      </Box>
    );
  }


  renderNotAvailable(message: ReactNode = 'Data unavailable.', retry = true) {
    const { classes, visible, onRetry } = this.props;

    return (
      <Box className={classNames(classes.notAvailable, visible && classes.visible)}>
        <Typography color="textSecondary">
          {message}
        </Typography>

        {
          retry && onRetry &&
          <Button
            onClick={this.retry}
            className={classes.retryButton}
            title="Retry"
            color="secondary"
            endIcon={<ReplayIcon/>}
          >
            Try again
          </Button>
        }
      </Box>
    );
  }

  retry = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const { item, onRetry } = this.props;

    item && onRetry && onRetry(item);
  }

  loadImage() {
    const { item } = this.props;

    this.setState({
      status: 'loading'
    }, () => {
      if (!item || !item.url) {
        this.setState({
          status: 'error'
        });

        return;
      }

      preloadImage(item.url).then(() => {
        this.setState({
          status: 'loaded'
        });
      }).catch(() => {
        this.setState({
          status: 'error'
        });
      });
    });
  }

  imageClickHandler = (event: MouseEvent<HTMLImageElement>) => {
    const { item, onClick } = this.props;
    const itemClick = item?.click?.navigation;

    const imageEl = this.$image.current;
    if (!onClick || !imageEl || !item || !itemClick) {
      return;
    }

    const pos = calculateImageClickCoordinates(event, imageEl);

    pos && onClick(item, pos);
  }

  componentDidMount() {
    const { item } = this.props;

    if (item && item.url) {
      this.loadImage();
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { item } = this.props;

    if (prevProps.item?.url !== item?.url) {
      this.loadImage();
    }
  }

  shouldComponentUpdate(nextProps: Readonly<TProps>, nextState: Readonly<TState>): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  render() {
    const { visible, item, title, classes, onClick } = this.props;
    const { status } = this.state;
    const interactive = Boolean(item?.click);

    if (!item) {
      return null;
    }

    if (item.status === ProductDataItemStatus.LOADING || status === 'loading') {
      return this.renderLoading();
    }

    if (!item.url || item.error) {
      return this.renderNotAvailable(item.error, false);
    }

    if (!item.url || item.status === ProductDataItemStatus.ERROR) {
      return this.renderNotAvailable();
    }

    if (status === 'error') {
      return this.renderNotAvailable('Sorry, it seems that image could not be loaded.', false);
    }

    return (
      <Fragment>
        <img
          ref={this.$image}
          className={classNames(classes.root, visible && classes.visible, interactive && classes.interactive)}
          alt={title}
          src={item.url}
          onClick={onClick && this.imageClickHandler}
        />
      </Fragment>
    );
  }
}

export default decorate(ProductDataPointMedia);
