import client from './client';
import SurveyAd from '../content/SurveyAd';

export interface Announcement {
  id: number;
  type: 'banner' | 'dialog';
  severity: 'info' | 'warning' | 'error' | 'success';
  target: 'all' | Array<string>; // Example: ['opencharts', 'cams', 'c3s', 'catalogue']
  start_date: string;
  end_date: string;
  delay: number;
  text: string;
}

// const list = (): Promise<Array<Announcement>> =>
//   client.get<Array<Announcement>>('/announcements').then(response => response.data);

const list = (): Promise<Array<Announcement>> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve([
        {
          id: -1,
          type: 'dialog',
          severity: 'info',
          start_date: '2024-09-17T00:00:00Z',
          end_date: '2024-10-29T00:00:00Z',
          delay: 5,
          target: ['opencharts', 'cams', 'c3s', 'catalogue'],
          text: SurveyAd.content
        }
      ]);
    }, 0)
  });
}

export {
  list
};
