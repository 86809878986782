import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  titleWrapper: {
    borderBottom: '1px solid #c1c1c1'
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  preview: {
    display: 'block'
  }
});
