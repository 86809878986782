import { styled, Typography } from '@material-ui/core';
import { Alert as MuiAlert, AlertProps, AlertTitle as MuiAlertTitle } from '@material-ui/lab';

type Props = Pick<AlertProps, 'severity' | 'action' | 'children'> & {
  title?: string;
}

const StyledMuiAlert = styled(MuiAlert)({
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center'
});

const StyledMuiAlertTitle = styled(MuiAlertTitle)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize
}));

const Alert = (props: Props) => {
  const { severity, title, action, children } = props;

  return (
    <StyledMuiAlert severity={severity} variant="filled" action={action}>
      {
        title && <StyledMuiAlertTitle>{title}</StyledMuiAlertTitle>
      }

      <Typography component="div" variant="body2">
        {
          children
        }
      </Typography>
    </StyledMuiAlert>
  );
};

export default Alert;
