import { ThunkDispatch } from 'redux-thunk';
import { TAppState } from '../store';
import { Action } from 'redux';
import { TFlashMessageVariants } from '../components/FlashMessage';

export const FLASH_MESSAGE_DISPLAYED = 'FLASH_MESSAGE_DISPLAYED';
export const FLASH_MESSAGE_HIDDEN = 'FLASH_MESSAGE_HIDDEN';

export interface IFlashMessageHiddenAction extends Action<typeof FLASH_MESSAGE_HIDDEN> {
}

export interface IFlashMessageDisplayedAction extends Action<typeof FLASH_MESSAGE_DISPLAYED> {
  message: {
    type: TFlashMessageVariants;
    text: string;
  };
}

const flashMessageHide = (): IFlashMessageHiddenAction => ({
  type: FLASH_MESSAGE_HIDDEN
});

const flashMessageDisplay = (type: TFlashMessageVariants, text: string): IFlashMessageDisplayedAction => ({
  type: FLASH_MESSAGE_DISPLAYED,
  message: {
    type,
    text
  }
});

const displayFlashMessage = (type: TFlashMessageVariants, message: string) => {
  return (dispatch: ThunkDispatch<TAppState, void, Action>): void => {
    dispatch(flashMessageHide());
    dispatch(flashMessageDisplay(type, message));
  };
};

export {
  displayFlashMessage,
  flashMessageHide
};
