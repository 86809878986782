import { Box, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import decorate from './decorator';
import Navigation from './Navigation';
import Timeline from './Timeline';
import DimensionSelect from './DimensionSelect';
import DimensionOptionSelect from './DimensionOptionSelect';
import isTimeDimension from '../../../utils/isTimeDimension';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { DimensionProductData, PlayerDimension, PlayerDimensionOption } from '../index';
import { dimensionOptionsToTimestamps } from '../../../utils/TimestampUtils';

export interface IPlayerFullScreenControls {
  onFullScreenChange?: (enabled: boolean) => void,
  fullScreenEnabled?: boolean
}

export interface IPlayerLegendControls {
  onLegendChange?: (open: boolean) => void;
  legendOpen?: boolean;
}

export interface IPlayerDimensionsControls {
  onDimensionControlToggle?: (open: boolean) => void;
  dimensionControlOpen?: boolean;
}

export interface IPlayerActionHandlers {
  onPlay?: () => void,
  onPause?: () => void,
}

export interface IDimensionControls {
  dimensions: Array<PlayerDimension>,
  selectedDimension: PlayerDimension,
  onDimensionChange: (option: PlayerDimension) => void
}

export interface IDimensionOptionControls {
  dimensionOptions: Array<PlayerDimensionOption>,
  selectedDimensionOption: PlayerDimensionOption | null,
  onDimensionOptionChange: (option: PlayerDimensionOption) => void
}

export type TProps = {
  embedMode?: boolean,
  asOverlay?: boolean,
  visible?: boolean,
  dimensionProductData: DimensionProductData,
  classes: {
    root: string,
    rootAsOverlay: string,
    rootHidden: string
  }
} & IDimensionControls
  & IDimensionOptionControls
  & IPlayerFullScreenControls
  & IPlayerLegendControls
  & IPlayerDimensionsControls
  & IPlayerActionHandlers;

class WidgetControls extends Component<TProps> {
  private get isTimeDimension() {
    const { selectedDimension } = this.props;

    return isTimeDimension(selectedDimension.name);
  }

  private get isRegularTimeDimension() {
    const { selectedDimension } = this.props;

    if (!this.isTimeDimension || selectedDimension.values.length < 2) {
      return false;
    }

    const timestamps = dimensionOptionsToTimestamps(this.sortedDimensionOptions);

    const minDiff = timestamps[1] - timestamps[0];
    let isRegular = true;

    for (let i = 2; i < timestamps.length; i++) {
      const diff = timestamps[i] - timestamps[i - 1];

      if (diff !== minDiff && diff % minDiff !== 0) {
        isRegular = false;
      }
    }

    return isRegular;
  }

  private get sortedDimensionOptions() {
    const { dimensionOptions } = this.props;

    return this.isTimeDimension ?
      orderBy(this.props.dimensionOptions, option => parseInt(option.value.toString(), 10))
      : dimensionOptions;
  }

  render() {
    const {
      classes,
      embedMode,
      dimensions,
      selectedDimension,
      dimensionProductData,
      onDimensionChange,
      selectedDimensionOption,
      onDimensionOptionChange,
      onFullScreenChange,
      fullScreenEnabled,
      onLegendChange,
      legendOpen,
      onDimensionControlToggle,
      dimensionControlOpen,
      visible,
      asOverlay,
      onPause,
      onPlay
    } = this.props;

    const rootClassName = classNames(
      classes.root,
      asOverlay && classes.rootAsOverlay,
      asOverlay && !visible && classes.rootHidden
    );

    return (
      <Box className={rootClassName}>
        {
          this.isRegularTimeDimension &&
          <Timeline
            dimensionOptions={this.sortedDimensionOptions}
            selectedDimensionOption={selectedDimensionOption}
            onDimensionOptionChange={onDimensionOptionChange}
          />
        }

        <Box display="flex" alignItems="center">
          {
            !embedMode && dimensions.length > 1 &&
            <Box style={{ width: '150px', marginRight: '30px' }}>
              <DimensionSelect
                native={fullScreenEnabled}
                dimensions={dimensions}
                selectedDimension={selectedDimension}
                onDimensionChange={onDimensionChange}
              />
            </Box>
          }

          <Navigation
            theme="dark"
            embedMode={embedMode}
            onPlay={onPlay}
            onPause={onPause}
            dimensionProductData={dimensionProductData}
            dimensionOptions={this.sortedDimensionOptions}
            selectedDimensionOption={selectedDimensionOption}
            onDimensionOptionChange={onDimensionOptionChange}
            onFullScreenChange={onFullScreenChange}
            fullScreenEnabled={fullScreenEnabled}
            onLegendChange={onLegendChange}
            legendOpen={legendOpen}
            onDimensionControlToggle={onDimensionControlToggle}
            dimensionControlOpen={dimensionControlOpen}
          >
            {
              this.isRegularTimeDimension && selectedDimensionOption &&
              <Typography variant="body2">
                {selectedDimensionOption.label}
              </Typography>
            }

            {
              !this.isRegularTimeDimension &&
              <DimensionOptionSelect
                native={fullScreenEnabled}
                dimensionOptions={this.sortedDimensionOptions}
                selectedDimensionOption={selectedDimensionOption}
                onDimensionOptionChange={onDimensionOptionChange}
              />
            }
          </Navigation>
        </Box>
      </Box>
    );
  }
}

export default decorate(WidgetControls);
