import config from '../config';
import * as QueryString from '../utils/queryString';
import { omit, isEmpty, compact } from 'lodash';
import { TQuerySearchParams } from '../utils/queryString';

type TRouteParam = string | number;
type TQuery = Record<string, string | number>;

const withQueryString = (path: string, query?: TQuerySearchParams) => {
  if (!query || isEmpty(query)) {
    return path;
  }

  return path + '?' + QueryString.stringify(query);
};

const root = compact([config.mountNamespacePath, '/']).join('');

const packageDetails = (name: TRouteParam) => {
  const { rootPackageId } = config;

  if (rootPackageId) {
    return root;
  }

  return root + `packages/${name}/`;
};

const productDetails = (packageName: TRouteParam, name: TRouteParam, query?: TQuerySearchParams<string | number>) => {
  let path = packageDetails(packageName) + `products/${name}`;

  if (query) {
    path = [path, QueryString.stringify(query)].join('?');
  }

  return path;
};

const productEmbed = (packageName: TRouteParam, name: TRouteParam) => root + `embed/${packageName}/${name}`;

const productDimensionOverview = (packageName: TRouteParam, productName: TRouteParam, axis: string, query?: TQuery) => {
  return withQueryString(
    productDetails(packageName, productName) + `/overview/${axis}`,
    omit(query, axis)
  );
};

const chartSet = (
  packageName: string,
  groupName: string,
  productNames: Array<string> = [],
  dimensions?: TQuery
) => {
  const path = packageDetails(packageName) + `chart-set/${groupName}`;

  return withQueryString(
    path,
    {
      ...dimensions,
      productNames
    }
  );
};

const paths = {
  root,
  packageDetails,
  productDetails,
  productDimensionOverview,
  productEmbed,
  chartSet
};

export default paths;
