const IMAGE_PRELOAD_TIMEOUT = 20_000;

const preloadImage = (url: string, timeout: number = IMAGE_PRELOAD_TIMEOUT): Promise<void> => {
  const img = new Image();

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject();
    }, timeout);

    img.onload = () => {
      clearTimeout(timeoutId);
      resolve();
    };
    img.onerror = () => reject;

    img.src = url;
  });
};

export default preloadImage;
