import React, { Component, Fragment, ReactNode } from 'react';
import { Box, WithStyles } from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import { Alert } from '@material-ui/lab';
import Markdown from '../../Markdown';
import { TProductFeature } from '../index';
import LocationDimensionControl
  from '../../../containers/Product/DimensionSelector/DimensionSelectorForm/LocationDimensionControl';
import { TFeatureLocationLatLon } from '../../../api/feature';
import ControlsWrapper from '../ControlsWrapper';
import ErrorWrapper from '../ErrorWrapper';

export interface IProps extends WithStyles<typeof styles> {
  feature: TProductFeature;
  onLocationChange: (location: TFeatureLocationLatLon) => void;
  controls?: ReactNode;
  error?: boolean;
}

class Epsgram extends Component<IProps> {
  onLocationChange = (latitude: string, longitude: string, stationName: string) => {
    const { onLocationChange } = this.props;

    onLocationChange({
      type: 'latlon',
      lat: Number(latitude),
      lon: Number(longitude),
      station_name: stationName
    });
  }

  renderContent() {
    const { feature, error } = this.props;

    if (error) {
      return <ErrorWrapper />;
    }

    return (
      <Fragment>
        <Alert severity="info">
          <Markdown source={feature.text} removeParagraphSpacing />
        </Alert>

        <img src={feature.url} alt={feature.value} style={{ maxWidth: '100%' }} />
      </Fragment>
    );
  }

  render() {
    const { feature, controls, classes } = this.props;

    // @ts-ignore
    const { lat, lon } = feature;

    return (
      <Box>
        <ControlsWrapper>
          <Box>
            {controls}
          </Box>

          <Box className={classes.locationControlWrapper}>
            <LocationDimensionControl
              onChange={this.onLocationChange}
              location={{
                name: '',
                latitude: lat.toFixed(2),
                longitude: lon.toFixed(2)
              }}
            />
          </Box>
        </ControlsWrapper>

        {
          this.renderContent()
        }
      </Box>
    );
  }
}

export default decorate(Epsgram);
