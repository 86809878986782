import client from './client';
import { mapValues, flatten, map } from 'lodash';
import { TProductAxis } from './axis';
import { Nullable } from '../utils/types';

export type TProduct = {
  name: string,
  title: string,
  subtitle: string,
  description: string,
  short_description: string,
  notebook: boolean;
  thumbnail: string,
  groups: Array<string>,
  package: {
    name: string,
    title: string
  },
  path: Array<string>,
  widget: Array<'basic'>,
  tags: { [tag: string]: string },
  viewport: {
    width: number,
    height: number
  },
  options: ProductOptions
}

export interface ProductOptions {
  notebook: boolean;
  notebooks_urls: Nullable<Record<string, string>>;
  script: boolean;
  legend_open: boolean;
}

export type TProductFacets = {
  [name: string]: Array<string>
}

export type TSubpackage = {
  name: string;
  title: string;
  package: string;
  path: Array<string>
}

export type TProductLicence = {
  type: 'CC-BY-4.0' | 'CC-BY-NC-SA-4';
  url: string;
}

export interface IProductListResponse {
  facets: TProductFacets;
  subpackages: Array<TSubpackage>
  products: Array<TProduct>;
  name: string;
  title: string;
  description: string;
  tracker: string;
  uid: string
}

export type TProductDetails = {
  name: string,
  package: {
    name: string,
    title: string
  },
  title: string,
  subtitle: Array<string>,
  description: string,
  options: ProductOptions,
  values: { [param: string]: string | number },
  uid: string,
  axis: Array<TProductAxis>,
  licence: Nullable<TProductLicence>;
  admin?: {
    url: string;
  },
  dashboard?: {
    url: string;
  }
}

export type TProductShareDetails = {
  slug: string,
  description: string,
  title: string,
  location: string
}

export type TProductDownloadDetails = {
  url: string
}

export type TProductExportSpec = {
  product: string;
  swagger: string;
  wget: string;
  notebook?: {
    binder: string;
    github: string;
    colab: string;
  }
}

const sanitizeFacets = (data: IProductListResponse) => {
  data.facets = mapValues(data.facets, value => flatten(value));

  return data;
};

const listByPackage = (packageName: string): Promise<IProductListResponse> =>
  client.get(`/packages/${packageName}/products/`)
    .then(response => response.data)
    .then(sanitizeFacets);

const fetch = (
  packageName: string,
  productName: string,
  params?: { [param: string]: any }
): Promise<TProductDetails> => {
  return client
    .get(`/packages/${packageName}/products/${productName}/`, { params })
    .then(response => response.data);
};

const share = (
  packageName: string,
  productName: string,
  params?: { [param: string]: any }
): Promise<TProductShareDetails> => {
  return client.post('/share/', {
    package: packageName,
    product: productName,
    ...params
  })
    .then(response => response.data);
};

const download = (
  packageName: string,
  productName: string,
  format: 'pdf' | 'png',
  params?: { [param: string]: any }
): Promise<TProductDownloadDetails> => {
  return client.post('/export/', {
    package: packageName,
    product: productName,
    format,
    ...params
  })
    .then(response => response.data);
};

const exportSpec = (
  packageName: string,
  productName: string,
  params?: { [param: string]: any }
): Promise<TProductExportSpec> => {
  return client.get('/export-spec/', {
    params: {
      package: packageName,
      product: productName,
      ...params
    }
  })
    .then(response => response.data);
};

const addToDashboard = (
  packageName: string,
  productName: string,
  params?: { [param: string]: any }
): Promise<{ url:	string }> => {
  return client.post('/save-to-dashboard/', {
    package: packageName,
    product: productName,
    ...params
  })
    .then(response => response.data);
};

export {
  download,
  exportSpec,
  fetch,
  listByPackage,
  share,
  addToDashboard
};
