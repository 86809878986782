import { ThunkAction } from 'redux-thunk';
import { PlayerDimensionOption } from '../components/Player';
import { TAppState } from '../store';
import { Action } from 'redux';
import Api from '../api';
import { IAxisFetchResponse, IAxisFetchResponseResult } from '../api/axis';
import filterProductDataParams from "../utils/filterProductDataParams";
import * as StoreIndex from '../utils/storeIndex';
import { findIndex, intersection, isEmpty, map, values } from 'lodash';
import { Dimension, DimensionChoice, DimensionSelectionMapping, DimensionType } from '../containers/Product';
import { ProductDataItemStatus } from '../reducers/productData';
import preloadImage from '../utils/preloadImage';

export const PRODUCT_DATA_FETCH_STARTED: 'PRODUCT_DATA_FETCH_STARTED' = 'PRODUCT_DATA_FETCH_STARTED';
export const PRODUCT_DATA_FETCH_COMPLETED: 'PRODUCT_DATA_FETCH_COMPLETED' = 'PRODUCT_DATA_FETCH_COMPLETED';
export const PRODUCT_DATA_FETCH_FAILED: 'PRODUCT_DATA_FETCH_FAILED' = 'PRODUCT_DATA_FETCH_FAILED';

export interface IProductDataFetchStartAction<T = typeof PRODUCT_DATA_FETCH_STARTED> extends Action<T> {
  packageName: string,
  productName: string,
  dimensionName: string,
  params: { [key: string]: string | number },
  requestedValues: Array<string | number>
}

export interface IProductDataFetchCompleteAction<T = typeof PRODUCT_DATA_FETCH_COMPLETED> extends Action<T> {
  packageName: string,
  productName: string,
  dimensionName: string,
  params: { [key: string]: string | number },
  data: IAxisFetchResponse['results']
}

export interface IProductDataFetchFailedAction<T = typeof PRODUCT_DATA_FETCH_FAILED> extends Action<T> {
  packageName: string,
  productName: string,
  dimensionName: string,
  params: { [key: string]: string | number },
  requestedValues: Array<string | number>
}

export type TProductDimensionParams = {
  [key: string]: string | number
}

const productDataFetchStarted = (
  packageName: string,
  productName: string,
  dimensionName: string,
  params: TProductDimensionParams,
  requestedValues: Array<string | number>
): IProductDataFetchStartAction => ({
  type: PRODUCT_DATA_FETCH_STARTED,
  packageName,
  productName,
  dimensionName,
  params,
  requestedValues
});

const productDataFetchComplete = (
  packageName: string,
  productName: string,
  dimensionName: string,
  params: TProductDimensionParams,
  data: IAxisFetchResponse['results']
): IProductDataFetchCompleteAction => ({
  type: PRODUCT_DATA_FETCH_COMPLETED,
  packageName,
  productName,
  dimensionName,
  params,
  data
});

const productDataFetchFailed = (
  packageName: string,
  productName: string,
  dimensionName: string,
  params: TProductDimensionParams,
  requestedValues: Array<string | number>
): IProductDataFetchFailedAction => ({
  type: PRODUCT_DATA_FETCH_FAILED,
  packageName,
  productName,
  dimensionName,
  params,
  requestedValues
});

const preloadProductDataImages = async (items: { [value: string]: IAxisFetchResponseResult }): Promise<void> => {
  const promises = map(items, item => {
    if (item.url) {
      return preloadImage(item.url);
    }

    return Promise.reject();
  });

  try {
    await Promise.all(promises);
  } catch {
    return;
  }

  return;
};

const fetchProductData = (
  packageName: string,
  productName: string,
  dimensions: Array<Dimension>,
  dimensionName: string,
  params: TProductDimensionParams,
  requestedValues: Array<string | number>
): ThunkAction<Promise<IAxisFetchResponse['results']>, TAppState, unknown, Action<string>> => {
  return async (dispatch, getState) => {
    const { productData } = getState();
    const filteredParams = filterProductDataParams(dimensions, params);

    const valuesToFetch = requestedValues.filter((requestedValue) => {
      const cacheKey = StoreIndex.productData(packageName, productName, {
        ...filteredParams,
        [dimensionName]: requestedValue
      });

      const cachedItem = productData[cacheKey];
      return !cachedItem || !cachedItem.url || cachedItem.status === ProductDataItemStatus.ERROR;
    });

    if (isEmpty(valuesToFetch)) {
      return {};
    }

    try {
      dispatch(productDataFetchStarted(packageName, productName, dimensionName, filteredParams, valuesToFetch));

      const response = await Api.Axis.fetch(
        packageName,
        productName,
        dimensionName,
        {
          ...filteredParams,
          values: valuesToFetch.join(',')
        }
      );

      await preloadProductDataImages(response.results);

      dispatch(productDataFetchComplete(packageName, productName, dimensionName, filteredParams, response.results));

      return response.results;
    } catch (error) {
      dispatch(productDataFetchFailed(packageName, productName, dimensionName, filteredParams, valuesToFetch));

      throw error;
    }
  };
};

const preloadProductData = (
  packageName: string,
  productName: string,
  dimensions: Array<Dimension>,
  productValues: DimensionSelectionMapping,
  selectedDimension: DimensionChoice,
  selectedDimensionOption: PlayerDimensionOption
): ReturnType<typeof fetchProductData> => {
  return async (dispatch) => {
    try {
      const preloadSize = 9;
      const selectedDimensionOptionIndex = findIndex(
        selectedDimension.values,
        option => option.value === selectedDimensionOption.value
      );

      const transpositionLength = Math.floor((preloadSize - 1) / 2);
      const leftIndexPos = Math.max(selectedDimensionOptionIndex - transpositionLength, 0);
      const rightIndexPos = Math.min(leftIndexPos + preloadSize, selectedDimension.values.length);

      const valuesToRequest = selectedDimension.values.slice(
        leftIndexPos,
        rightIndexPos
      ).map(option => option.value);

      return dispatch(
        fetchProductData(
          packageName,
          productName,
          dimensions,
          selectedDimension.name,
          productValues,
          valuesToRequest
        )
      );
    } catch (e) {
      throw new Error('preload failed');
    }
  };
};


export {
  fetchProductData,
  preloadProductData
};
