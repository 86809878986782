import React, { Component } from 'react';
import { TLegendImage } from '../../../../api/axis';
import { Box } from '@material-ui/core';
import LegendTitle from '../LegendTitle';

interface IProps {
  item: TLegendImage
}

class LegendImage extends Component<IProps> {
  render() {
    const { item } = this.props;
    const { url } = item.data;

    return (
      <Box style={{ background: '#ffffff' }}>
        <LegendTitle item={item} />

        <Box>
          <img src={url} alt="Legend" />
        </Box>
      </Box>
    );
  }
}

export default LegendImage;
