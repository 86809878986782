import React, { Component } from 'react';
import decorate from './decorator';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  List,
  ListItemText,
  WithStyles,
  Box
} from '@material-ui/core';
import paths from '../../../router/paths';
import styles from './styles';
import { TProduct } from '../../../api/product';
import history from '../../../history';
import { Alert } from '@material-ui/lab';
import GridViewIcon from '../../../components/Icons/GridView';
import ClearIcon from '@material-ui/icons/Clear';
import ButtonWithIcon from '../../../components/ButtonWithIcon';

export interface IProps extends WithStyles<typeof styles> {
  packageName: string;
  products: Array<TProduct>;
  clearProductCollection: (packageName: string) => void;
}

class ChartSetInfoBar extends Component<IProps> {
  onClear = () => {
    const { clearProductCollection, packageName } = this.props;

    clearProductCollection(packageName);
  }

  onOpen = () => {
    const {
      packageName,
      products
    } = this.props;

    const groupName = products[0].groups[0];

    const path = paths.chartSet(packageName, groupName, products.map(product => product.name));

    history.push(path);
  }

  renderProducts() {
    const { products } = this.props;

    return (
      <List>
        {
          products.map(product => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={product.thumbnail} alt={product.title} style={{ height: 40 }} />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={product.title}
                  primaryTypographyProps={{
                    variant: 'body2'
                  }}
                />
              </ListItem>
            );
          })
        }
      </List>
    );
  }

  renderMessage() {
    const { products } = this.props;

    const productsTextForm = products.length === 1 ? 'product' : 'products';

    return `You have selected ${products.length} ${productsTextForm} to be included in ChartSet.`;
  }

  render() {
    const { classes, products } = this.props;

    if (!products.length) {
      return null;
    }

    return (
      <Box className={classes.root}>
        <Alert
          className={classes.alert}
          severity="info"
          variant="filled"
          classes={{
            action: classes.alertAction
          }}
          action={
            <Box className={classes.actions}>
              <ButtonWithIcon icon={GridViewIcon} onClick={this.onOpen} color="inherit" title="Open ChartSet">
                Open ChartSet
              </ButtonWithIcon>

              <ButtonWithIcon icon={ClearIcon} onClick={this.onClear} color="inherit" title="Clear ChartSet">
                Clear
              </ButtonWithIcon>
            </Box>
          }
        >
          {this.renderMessage()}
        </Alert>
      </Box>
    );
  }
}

export default decorate(ChartSetInfoBar);
