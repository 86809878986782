import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    flexDirection: 'column'
  },
  gridItem: {
    maxWidth: '100%'
  }
});
