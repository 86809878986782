import React, { Component, Fragment } from 'react';
import Slider from '../Slider';
import { find } from 'lodash';
import { IDimensionOptionControls } from '../../index';
import {
  dimensionOptionsToTimestamps,
  axisValueToDateTime,
  timestampToAxisValue
} from '../../../../../utils/TimestampUtils';
import { PlayerDimensionOption } from '../../../index';


type TProps = IDimensionOptionControls;

const MAX_STEP = 7 * 1000 * 60 * 60 * 24; // weekly

const foo = (options: Array<PlayerDimensionOption>) => {
  return options.reduce((acc, option) => {
    const key = axisValueToDateTime(option.value.toString());

    if (key) {
      acc[key] = option.label;
    }

    return acc;
  }, {} as Record<string, string>);
};

class TimelineSlider extends Component<TProps> {
  onChange = (values: ReadonlyArray<number>) => {
    const { dimensionOptions, onDimensionOptionChange } = this.props;

    const value = timestampToAxisValue(values[0]);

    const option = find(dimensionOptions, (opt) => opt.value === value);

    option && onDimensionOptionChange(option);
  };

  get sliderOptions() {
    const { dimensionOptions } = this.props;

    const points = dimensionOptionsToTimestamps(dimensionOptions);

    const step = points.reduce((acc: number, point, index, arr) => {
      if (index === 0) {
        return acc;
      }

      return Math.min(acc, point - arr[index - 1]);
    }, MAX_STEP);

    return {
      min: points[0],
      max: points[points.length - 1],
      points,
      step
    };
  }


  render() {
    const { selectedDimensionOption, dimensionOptions } = this.props;

    const parsedSelectedDimensionValue =
      selectedDimensionOption && axisValueToDateTime(selectedDimensionOption.value.toString());

    const value = parsedSelectedDimensionValue || 0;

    const { min, max, points, step } = this.sliderOptions;

    return (
      <Fragment>
        <Slider
          min={min}
          max={max}
          step={step}
          values={[value]}
          labelMap={foo(dimensionOptions)}
          onChange={this.onChange}
          tickProps={{
            values: points
          }}
        />
      </Fragment>
    );
  }
}

export default TimelineSlider;
