import { Breadcrumbs as BreadcrumbsMui } from '@material-ui/core';
import React, { Component } from 'react';
import decorate from './decorator';
import { kebabCase, map } from 'lodash';
import BreadcrumbItem from './BreadcrumbItem';
import HomeIcon from '@material-ui/icons/Home';
import isEmbedded from '../../utils/isEmbedded';
import config from '../../config';

export interface IProps {
  paths: {
    [label: string]: string | null;
  };
  classes: {
    root: string;
    icon: string;
  };
}


class Breadcrumbs extends Component<IProps> {
  renderBreadcrumb(path: string | null, label: string) {
    const key = kebabCase(label);

    return <BreadcrumbItem label={label} path={path} key={key} />;
  }

  render() {
    const { paths, classes } = this.props;
    const embedded = isEmbedded();
    const homeUrl = config.appHomeLink ?? '/';
    const hasCustomHomeLink = Boolean(config.appHomeLink);

    if (embedded) {
      return null;
    }

    return (
      <BreadcrumbsMui aria-label="breadcrumb" className={classes.root}>
        <BreadcrumbItem
          label="Home"
          path={homeUrl}
          icon={<HomeIcon className={classes.icon} />}
          external={hasCustomHomeLink}
        />

        {
          map(paths, this.renderBreadcrumb)
        }
      </BreadcrumbsMui>
    );
  }
}

export default decorate(Breadcrumbs);
