import React from 'react';
import CCLicenceIcon from '../Icons/CCLicenceIcon';
import ByLicenceIcon from '../Icons/ByLicenceIcon';
import ExternalLink from '../ExternalLink';
import decorate from './decorator';
import NCLicenceIcon from '../Icons/NCLicenceIcon';
import SALicenceIcon from '../Icons/SALicenceIcon';

export interface IProps {
  type: 'CC-BY-4.0' | 'CC-BY-NC-SA-4';
  url: string;
  classes: {
    root: string;
    icon: string;
  }
}

const CC_BY_40 = (props: Omit<IProps, 'type'>) => {
  const { classes, url } = props;

  return (
    <ExternalLink href={url} className={classes.root} title="Use of data accessed via this service">
      <CCLicenceIcon className={classes.icon} fontSize="large" />
      <ByLicenceIcon className={classes.icon} fontSize="large" />
    </ExternalLink>
  );
};

const CC_BY_NC_SA_40 = (props: Omit<IProps, 'type'>) => {
  const { classes, url } = props;

  return (
    <ExternalLink href={url} className={classes.root} title="Use of data accessed via this service">
      <CCLicenceIcon className={classes.icon} fontSize="large" />
      <ByLicenceIcon className={classes.icon} fontSize="large" />
      <NCLicenceIcon className={classes.icon} fontSize="large" />
      <SALicenceIcon className={classes.icon} fontSize="large" />
    </ExternalLink>
  );
};

const Licence = (props: IProps) => {
  const { classes, url, type } = props;

  switch (type) {
    case 'CC-BY-4.0':
      return <CC_BY_40 classes={classes} url={url} />;
    case 'CC-BY-NC-SA-4':
      return <CC_BY_NC_SA_40 classes={classes} url={url} />;
    default:
      return null;
  }
};

export default decorate(Licence);
