import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  root: {
    display: 'inline-block',
    color: 'inherit'
  },
  icon: {
    marginLeft: '0.5em',

    '&:first-child': {
      marginLeft: 0
    }
  }
});
