import React, { Component, MouseEvent } from 'react';
import decorate from './decorator';
import styles from './styles';
import { WithStyles, ListSubheader, List, ListItem, ListItemIcon, ListItemText, Typography, Box } from '@material-ui/core';
import { TLocation } from '../../../../../../reducers/recentLocationsByProduct';
import { formatCoordinates } from '../../../../../../utils/CoordinatesFormatter';
import PlaceIcon from '@material-ui/icons/Place';
import { compact } from 'lodash';

export interface IProps extends WithStyles<typeof styles> {
  packageName: string;
  productName: string;
  locations: Array<TLocation>;
  onSelect: (latitude: string, longitude: string, stationName?: string) => void
}

class RecentLocations extends Component<IProps> {
  onLocationSelect = (location: TLocation) => (ev: MouseEvent<HTMLElement>) => {
    const { onSelect } = this.props;
    ev.preventDefault();

    onSelect(location.latitude, location.longitude, location.name);
  }

  renderLocation(location: TLocation) {
    const { classes } = this.props;
    const title = compact(
      [location.name, formatCoordinates(location.latitude, location.longitude)]
    ).join(', ');

    return (
      <ListItem button onClick={this.onLocationSelect(location)} className={classes.listItem} key={title}>
        <ListItemIcon className={classes.listItemIcon}>
          <PlaceIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            variant: 'body2'
          }}
        />
      </ListItem>
    );
  }

  renderEmpty() {
    return (
      <Box paddingTop={1} paddingBottom={1}>
        <Typography variant="body2" align="center" component="div">
          No recent locations.
        </Typography>
      </Box>
    );
  }

  render() {
    const { locations, classes } = this.props;

    if (!locations.length) {
      return this.renderEmpty();
    }

    return (
      <List
        subheader={
          <ListSubheader component="div" className={classes.listSubheader}>
            Recently viewed locations
          </ListSubheader>
        }
      >
        {
          locations.map(location => this.renderLocation(location))
        }
      </List>
    );
  }
}

export default decorate(RecentLocations);
