import { keyBy } from 'lodash';
import {
  PACKAGE_LIST_FETCH_STARTED,
  PACKAGE_LIST_FETCH_COMPLETED,
  PACKAGE_LIST_FETCH_FAILED,
  IPackageListFetchStartAction,
  IPackageListFetchCompleteAction,
  IPackageListFetchFailAction
} from '../actions/package';
import { TPackage } from '../api/package';

type TPackageStateData = {
  [name: string]: TPackage
};

type TPackagesState = {
  loading: boolean,
  loadedAt: null | number,
  error: null | string,
  data: TPackageStateData
};

const initialState = {
  loading: false,
  loadedAt: null,
  error: null,
  data: {}
};

type TAllowedActions =
  IPackageListFetchStartAction |
  IPackageListFetchCompleteAction |
  IPackageListFetchFailAction;

export default (state: TPackagesState = initialState, action: TAllowedActions): TPackagesState => {
  switch (action.type) {
    case PACKAGE_LIST_FETCH_STARTED:
      return {
        ...state,
        loading: true
      };

    case PACKAGE_LIST_FETCH_COMPLETED: {
      const data = keyBy(action.data, 'name');

      return {
        ...state,
        data,
        loading: false,
        loadedAt: Date.now(),
        error: null
      };
    }

    case PACKAGE_LIST_FETCH_FAILED:
      return {
        ...state,
        error: action.error.message,
        loading: false
      };

    default:
      return state;
  }
};
