import React, { Component, Fragment } from 'react';
import decorate from './decorator';
import Tooltip from '../Tooltip';
import { Box } from '@material-ui/core';
import { SliderLabelMap } from '../index';

export interface IProps {
  activeHandleID: string;
  getRailProps: (props: object) => object;
  getEventData: (e: Event) => object;
  disabled?: boolean;
  sliderWidth: number | null;
  labelMap: SliderLabelMap;
  classes: {
    rail: string;
    baseRail: string;
  };
}

type TState = {
  value: number | null,
  percent: number | null
}

class TooltipRail extends Component<IProps, TState> {
  state: TState = {
    value: null,
    percent: null
  };

  static defaultProps = {
    disabled: false
  };

  onMouseEnter = () => {
    document.addEventListener('mousemove', this.onMouseMove);
  };

  onMouseLeave = () => {
    this.setState({ value: null, percent: null });
    document.removeEventListener('mousemove', this.onMouseMove);
  };

  onMouseMove = (e: Event) => {
    const { activeHandleID, getEventData } = this.props;

    if (activeHandleID) {
      this.setState({ value: null, percent: null });
    } else {
      this.setState(getEventData(e));
    }
  };

  render() {
    const { value, percent } = this.state;
    const { classes, activeHandleID, getRailProps, sliderWidth, labelMap } = this.props;
    const label = value ? labelMap[value.toString()] : '';

    return (
      <Fragment>
        {
          !activeHandleID && value &&
          <Tooltip
            sliderWidth={sliderWidth}
            percent={percent}
            text={label}
          />
        }

        <Box
          className={classes.rail}
          {...getRailProps({
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave
          })}
        />
        <Box className={classes.baseRail} />
      </Fragment>
    );
  }
}

export default decorate(TooltipRail);
