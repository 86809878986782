import React, { Component, CSSProperties } from 'react';
import Fuze from 'fuse.js';
import { IProductListResponse, TProduct, TProductFacets } from '../../../api/product';
import PageLoading from "../../../components/PageLoading";
import * as QueryString from '../../../utils/queryString';
import { flatten, intersection, isEmpty, slice, uniq } from 'lodash';
import { Box, Grid, Typography, withWidth } from '@material-ui/core';
import Filters from '../Filters';
import ProductCard from '../ProductCard';
import { RouteComponentProps, withRouter } from 'react-router';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';
import { WithWidth } from '@material-ui/core/withWidth/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { CellMeasurerCacheInterface } from 'react-virtualized/dist/es/CellMeasurer';
import ChartSetInfoBar from '../ChartSetInfoBar';
import InfiniteScroll from '../../../components/InfiniteScroll';

interface IProps extends RouteComponentProps<{ name: string }>, WithWidth {
  selectedProducts: Array<string>;
  selectedProductGroups?: Array<string>;
  packageItem: IProductListResponse;
}

interface IState {
  searchValue: string;
  selectedFacets: TProductFacets;
  isSearchInitialized: boolean;
}

const searchOptions = {
  keys: ['title'],
  ignoreLocation: true,
  threshold: 0.3
};

const columnsPerRow: Record<Breakpoint, number> = {
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 4
};

const filterProductsByFacets = (products: Array<TProduct>, facets: TProductFacets) => {
  return products.filter((product) => {
    return Object.entries(facets).every(entry => {
      const [key, value] = entry;

      if (isEmpty(value)) {
        return true;
      }

      const inter = intersection(value, product.tags[key]);

      return Boolean(inter.length);
    });
  });
};

class FacetedView extends Component<IProps, IState> {
  fuze: Fuze<TProduct> = new Fuze([] as Array<TProduct>, searchOptions);

  state: IState = {
    isSearchInitialized: false,
    searchValue: '',
    selectedFacets: {}
  };

  cache: CellMeasurerCacheInterface = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 300
  });

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { location } = props;
    const queryParams = QueryString.parse(location.search);

    return {
      ...state,
      searchValue: queryParams.query ?? '',
      ...(queryParams.facets && { selectedFacets: JSON.parse(queryParams.facets as string) })
    };
  }

  handleSearchValueChange = (value: string) => {
    const { selectedFacets } = this.state;

    this.updateUrl(value, selectedFacets);
  };

  handleFacetChange = (facetId: string, value: string, isSelected: boolean) => {
    const { searchValue, selectedFacets } = this.state;

    const currentValues = selectedFacets[facetId] || [];
    const selectedValues = isSelected ?
      [...(currentValues), value]
      : currentValues.filter(v => v !== value);

    this.updateUrl(searchValue, {
      ...selectedFacets,
      [facetId]: uniq(selectedValues)
    });
  };

  updateUrl(searchValue: string, selectedFacets: TProductFacets) {
    const { history, location } = this.props;

    history.replace(location.pathname + '?' + QueryString.stringify({
      ...(!isEmpty(searchValue) && { query: searchValue }),
      ...(!isEmpty(selectedFacets) && { facets: JSON.stringify(selectedFacets) })
    }));
  }


  filterProducts() {
    const { searchValue, selectedFacets } = this.state;
    const { packageItem } = this.props;
    const products = packageItem?.products || [];
    let filteredProducts = searchValue ? this.fuze && this.fuze.search(searchValue).map(r => r.item) : products;
    const anySelected = flatten(Object.values(selectedFacets)).length;

    if (!anySelected) {
      return filteredProducts;
    }

    return filterProductsByFacets(filteredProducts, selectedFacets);
  }

  componentDidMount() {
    const { packageItem } = this.props;
    const { products } = packageItem;

    this.fuze.setCollection(products);
    this.setState({isSearchInitialized: true});
  }

  renderProduct(product: TProduct) {
    const { packageItem, selectedProducts, selectedProductGroups } = this.props;
    const packageName = packageItem.name;
    const isSelected = selectedProducts.includes(product.name);
    const anySelected = Boolean(selectedProducts.length);
    const isCompatibleWithSelected = Boolean(intersection(product.groups, selectedProductGroups).length);

    return (
      <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={product.name}>
        <ProductCard
          packageName={packageName}
          product={product}
          selected={isSelected}
          chartSetButtonEnabled={!anySelected || (anySelected && isCompatibleWithSelected)}
        />
      </Grid>
    );
  }

  renderProduct2(product: TProduct) {
    const { packageItem, selectedProducts, selectedProductGroups } = this.props;
    const packageName = packageItem.name;
    const isSelected = selectedProducts.includes(product.name);
    const anySelected = Boolean(selectedProducts.length);
    const isCompatibleWithSelected = Boolean(intersection(product.groups, selectedProductGroups).length);

    return (
      <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={product.name}>
        <ProductCard
          key={product.name}
          packageName={packageName}
          product={product}
          selected={isSelected}
          chartSetButtonEnabled={!anySelected || (anySelected && isCompatibleWithSelected)}
        />
      </Grid>
    );
  }

  renderProducts2() {
    const products = this.filterProducts();

    return (
      <InfiniteScroll
        perPage={6}
        items={products}
        renderItem={product => this.renderProduct2(product)}
      />
    );
  }

  renderProducts() {
    const products = this.filterProducts();
    const { width } = this.props;
    const columns = columnsPerRow[width];
    const rowCount = Math.ceil(products.length / columns);

    if (products.length === 0) {
      return (
        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
          <Typography component="div">
            No products found for selected criteria.
          </Typography>
        </Box>
      );
    }

    return (
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                rowCount={rowCount}
                overscanRowCount={1}
                rowHeight={520}
                rowRenderer={(props) =>
                  this.renderGroup(
                    props.key,
                    props.style,
                    props.index,
                    props.parent,
                    slice(products, columns * props.index, columns * props.index + columns)
                  )
                }
                scrollTop={scrollTop}
                width={width}
                // style={{ width: '100%' }}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    );
  }

  renderGroup(key: string, style: CSSProperties, rowIndex: number, parent: any, products: Array<TProduct>) {
    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={key}
        rowIndex={rowIndex}
        parent={parent}
      >
        {({ measure, registerChild }) => (
          <div ref={registerChild as any} key={key} style={style}>
            <Grid container spacing={2} style={{ height: '100%' }}>
              {products.map(product => this.renderProduct2(product))}
            </Grid>
          </div>
        )}
      </CellMeasurer>
    );
  }

  renderGroup2(key: string, style: CSSProperties, rowIndex: number, parent: any, products: Array<TProduct>) {
    return (
      <Box style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: 24,
        rowGap: 24,
        gridAutoRows: '1fr'
      }}>
        {products.map(product => this.renderProduct2(product))}
      </Box>
    );
  }

  render() {
    const { searchValue, selectedFacets, isSearchInitialized } = this.state;
    const { packageItem } = this.props;

    if (!isSearchInitialized) {
      return <PageLoading />;
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={3} xl={3}>
          <Filters
            searchValue={searchValue}
            onChange={this.handleSearchValueChange}
            facets={packageItem.facets}
            selectedFacets={selectedFacets}
            onFacetChange={this.handleFacetChange}
          />
        </Grid>

        <Grid item xs={12} md={9} xl={9}>
          {
            <ChartSetInfoBar packageName={packageItem.name} />
          }

          {
            this.renderProducts()
          }
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(
  withWidth()(FacetedView)
);
