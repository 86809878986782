import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const extendedUTC = (value: dayjs.ConfigType) => {
  return dayjs(value).utc().format('ddd DD MMM YYYY HH UTC');
};

const axisTimeString = (value: dayjs.ConfigType) => {
  return dayjs.utc(value).format('YYYYMMDDHHmm');
};

export {
  extendedUTC,
  axisTimeString
};
