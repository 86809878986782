import * as QueryString from './queryString';
import history from '../history';
import { omitBy, isEmpty } from 'lodash';

const updateUrlSearchParams = (
  params: { [key: string]: string | number },
  strategy: 'push' | 'replace' = 'replace'
) => {
  const { location } = history;

  const currentParams = QueryString.parse(location.search);

  const mergedParams = omitBy({
    ...currentParams,
    ...params
  }, isEmpty);

  const path = [
    location.pathname,
    QueryString.stringify(mergedParams)
  ].join('?');

  history[strategy](path);
};

export default updateUrlSearchParams;
