import React, { Component } from 'react';
import { WithStyles, ThemeProvider } from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import theme from '../../theme';

export interface IProps extends WithStyles<typeof styles> {
}

class Toolbar extends Component<IProps> {
  render() {
    const { classes, children } = this.props;

    return (
      <ThemeProvider theme={theme(false, 'dark')}>
        <div className={classes.root}>
          {
            children
          }
        </div>
      </ThemeProvider>
    );
  }
}

export default decorate(Toolbar);
