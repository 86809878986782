import React, { Component, Fragment, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { TProductFeature } from '../index';
import { Alert } from '@material-ui/lab';
import Markdown from '../../Markdown';
import ControlsWrapper from '../ControlsWrapper';
import ErrorWrapper from '../ErrorWrapper';
import { isEmpty } from 'lodash';

interface IProps {
  feature: TProductFeature;
  controls?: ReactNode;
  error?: boolean;
}

class ProductFeatureContent extends Component<IProps> {
  renderContent() {
    const { feature, error } = this.props;
    const { text } = feature;

    if (error) {
      return <ErrorWrapper />;
    }

    return (
      <Fragment>
        {
          !isEmpty(text) &&
          <Alert severity="info">
            <Markdown source={feature.text} removeParagraphSpacing />
          </Alert>
        }

        <img src={feature.url} alt={feature.value} style={{ maxWidth: '100%' }} />
      </Fragment>
    );
  }

  render() {
    const { controls } = this.props;

    return (
      <Box>
        <ControlsWrapper>
          <Box>
            {controls}
          </Box>
        </ControlsWrapper>

        {
          this.renderContent()
        }
      </Box>
    );
  }
}

export default ProductFeatureContent;
