import {
  FLASH_MESSAGE_DISPLAYED,
  FLASH_MESSAGE_HIDDEN, IFlashMessageDisplayedAction, IFlashMessageHiddenAction
} from '../actions/flashMessage';
import { pick } from 'lodash';
import { TFlashMessageVariants } from '../components/FlashMessage';

type TFlashMessageState = {
  type: TFlashMessageVariants | null;
  text: string | null;
}

const initialState: TFlashMessageState = {
  type: null,
  text: null
};

type TAllowedActions = IFlashMessageDisplayedAction | IFlashMessageHiddenAction;

export default (state = initialState, action: TAllowedActions): TFlashMessageState => {
  switch (action.type) {
    case FLASH_MESSAGE_DISPLAYED:
      return pick(action.message, 'type', 'text');

    case FLASH_MESSAGE_HIDDEN:
      return { ...initialState };
    default:
      return state;
  }
};
