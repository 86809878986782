import { Button, Grid, TextField, InputAdornment } from '@material-ui/core';
import React, { ChangeEvent, Component } from 'react';
import { isNaN, isNumber } from 'lodash';
import { parseLatitude, parseLongitude } from '../../../../../../utils/CoordinatesFormatter';

interface IProps {
  latitude: string;
  longitude: string;
  onChange: (latitude: string, longitude: string) => void;
  className?: string;
}

interface IState {
  latitude: number;
  latitudeUnit: 'N' | 'S';
  longitude: number;
  longitudeUnit: 'W' | 'E';
}

const latitudeAsNumber = (value: number, unit: 'N' | 'S') => {
  if (unit === 'S') {
    return -1 * value;
  }

  return value;
};

const longitudeAsNumber = (value: number, unit: 'W' | 'E') => {
  if (unit === 'W') {
    return -1 * value;
  }

  return value;
};

const fieldValue = (value: number) => {
  return isNaN(value) ? '' : value;
};

class CoordinatesControl extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const [latitude, latitudeUnit] = parseLatitude(props.latitude);
    const [longitude, longitudeUnit] = parseLongitude(props.longitude);

    this.state = {
      latitude,
      latitudeUnit,
      longitude,
      longitudeUnit
    } as IState;
  }

  get isDirty() {
    const { latitude, latitudeUnit, longitude, longitudeUnit } = this.state;

    return parseFloat(this.props.latitude) !== latitudeAsNumber(latitude, latitudeUnit) ||
      parseFloat(this.props.longitude) !== longitudeAsNumber(longitude, longitudeUnit);
  }

  get isValid() {
    const { latitude, longitude } = this.state;

    return isNumber(latitude) && isNumber(longitude);
  }

  onLocationChange = (field: 'latitude' | 'longitude') => (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);

    this.setState({
      ...this.state,
      [field]: value
    });
  }

  onLatitudeUnitChange = () => {
    const { latitudeUnit } = this.state;

    this.setState({
      latitudeUnit: latitudeUnit === 'N' ? 'S' : 'N'
    });
  }

  onLongitudeUnitChange = () => {
    const { longitudeUnit } = this.state;

    this.setState({
      longitudeUnit: longitudeUnit === 'W' ? 'E' : 'W'
    });
  }

  onApply = () => {
    const { onChange } = this.props;
    const { latitude, latitudeUnit, longitude, longitudeUnit } = this.state;

    onChange(
      latitudeAsNumber(latitude, latitudeUnit).toString(),
      longitudeAsNumber(longitude, longitudeUnit).toString()
    );
  }

  render() {
    const { className } = this.props;
    const { latitude, latitudeUnit, longitude, longitudeUnit } = this.state;

    return (
      <Grid container spacing={2} className={className}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            type="number"
            value={fieldValue(latitude)}
            onChange={this.onLocationChange('latitude')}
            label="Latitude"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <Button onClick={this.onLatitudeUnitChange} color="secondary" style={{ minWidth: 0, width: '2em' }}>
                    {latitudeUnit}
                  </Button>
                </InputAdornment>
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            type="number"
            fullWidth
            value={fieldValue(longitude)}
            onChange={this.onLocationChange('longitude')}
            label="Longitude"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <Button onClick={this.onLongitudeUnitChange} color="secondary" style={{ minWidth: 0, width: '2em' }}>
                    {longitudeUnit}
                  </Button>
                </InputAdornment>
            }}
          />
        </Grid>

        <Grid container item xs={4} justify="flex-end" alignItems="flex-end">
          <Button fullWidth onClick={this.onApply} color="secondary" disabled={!this.isDirty || !this.isValid}>Apply</Button>
        </Grid>
      </Grid>
    );
  }
}

export default CoordinatesControl;
