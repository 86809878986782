import React, { Component, ReactNode, SyntheticEvent, MouseEvent } from 'react';
import decorate from './decorator';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { DimensionProductData, PlayerDimensionOption } from '../../index';
import classNames from 'classnames';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import LoopIcon from '@material-ui/icons/Loop';
import TuneIcon from '@material-ui/icons/Tune';
import {
  IDimensionOptionControls,
  IPlayerActionHandlers, IPlayerDimensionsControls,
  IPlayerFullScreenControls,
  IPlayerLegendControls
} from '../index';
import SkipBackwardIcon from '../../../Icons/SkipBackward';
import { ProductDataItemStatus } from '../../../../reducers/productData';
import Api from '../../../../api';

export type TProps = {
  theme?: 'dark' | 'light',
  embedMode?: boolean,
  dimensionProductData: DimensionProductData,
  classes: {
    root: string,
    controlIcon: string,
    controlIconDark: string,
    controlIconActive: string,
    additionalContent: string
  },
  children?: ReactNode
} & IDimensionOptionControls
  & IPlayerFullScreenControls
  & IPlayerLegendControls
  & IPlayerDimensionsControls
  & IPlayerActionHandlers;

type TState = {
  isPlaying: boolean,
  isNextLoading: boolean,
  loop: boolean,
  selectedIndex: number
};

const ANIMATION_INTERVAL = 800;

class Navigation extends Component<TProps, TState> {
  private interval: number | null = null;

  state: TState = {
    isPlaying: false,
    loop: false,
    isNextLoading: false,
    selectedIndex: 0
  };

  public static getDerivedStateFromProps(props: TProps, prevState: TState) {
    const { dimensionOptions, selectedDimensionOption } = props;
    let selectedIndex;

    if (selectedDimensionOption) {
      selectedIndex = dimensionOptions
        .map(option => option.value)
        .indexOf(selectedDimensionOption.value);
    } else {
      selectedIndex = -1;
    }

    return {
      ...prevState,
      selectedIndex
    };
  }

  private onChange = (option: PlayerDimensionOption) => {
    this.props.onDimensionOptionChange(option);
  };

  private onLoopChange = (loop: boolean) => () => {
    this.setState({
      loop
    });
  };

  private onLegendChange = (open: boolean) => () => {
    const { onLegendChange } = this.props;

    onLegendChange && onLegendChange(open);
  };

  private onDimensionControlToggle = (open: boolean) => () => {
    const { onDimensionControlToggle } = this.props;

    onDimensionControlToggle && onDimensionControlToggle(open);
  }

  private onFullScreenChange = (enabled: boolean) => () => {
    const { onFullScreenChange } = this.props;

    onFullScreenChange && onFullScreenChange(enabled);
  };

  private isFirst = () => this.state.selectedIndex === 0;
  private isLast = () => this.state.selectedIndex === this.props.dimensionOptions.length - 1;

  private rewindToStart = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { loop } = this.state;
    const isFirst = this.isFirst();
    const { dimensionOptions } = this.props;

    if (isFirst && !loop) {
      return;
    }

    this.onChange(dimensionOptions[0]);
  }

  private prev = (event?: SyntheticEvent) => {
    const { selectedIndex, loop } = this.state;
    const { dimensionOptions } = this.props;
    const isFirst = this.isFirst();

    if (event) {
      event.preventDefault();
    }

    if (isFirst && !loop) {
      return;
    }

    const prevItemIndex = isFirst ? dimensionOptions.length - 1 : selectedIndex - 1;

    this.onChange(dimensionOptions[prevItemIndex]);
  };

  private next = (event?: SyntheticEvent) => {
    const { dimensionOptions, dimensionProductData } = this.props;
    const { selectedIndex, loop, isPlaying } = this.state;
    const isLast = this.isLast();

    if (event) {
      event.preventDefault();
    }

    if (isLast && !loop) {
      return;
    }

    const nextItemIndex = isLast ? 0 : selectedIndex + 1;

    const nextOption = dimensionOptions[nextItemIndex];

    const nextProductDataItem = dimensionProductData[nextOption.value];
    const isLoaded = nextProductDataItem?.status !== ProductDataItemStatus.LOADING;

    // TODO: handle error status
    if (isLoaded || !isPlaying) {
      this.setState({
        isNextLoading: false
      }, () => {
        this.onChange(nextOption);
      });
    } else {
      this.setState({
        isNextLoading: nextProductDataItem?.status === ProductDataItemStatus.LOADING
      });
    }
  };

  private play = () => {
    const { onPlay } = this.props;

    this.interval = window.setInterval(() => {
      const { loop } = this.state;

      if (this.isLast() && !loop) {
        this.stop();
      } else {
        this.next();
      }
    }, ANIMATION_INTERVAL);

    this.setState({
      isPlaying: true
    }, () => {
      onPlay && onPlay();
    });
  };

  private stop = () => {
    const { onPause } = this.props;

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.setState({
      isPlaying: false
    }, () => {
      onPause && onPause();
    });
  };

  private togglePlay = (e: SyntheticEvent | KeyboardEvent) => {
    e.preventDefault();

    const action = this.state.isPlaying ? this.stop : this.play;

    action();
  };

  private preload = () => {
    // Api.Axis.preload()
  }

  handleKeyboardEvent = (event: KeyboardEvent) => {
    const tagName = (event.target as HTMLElement).tagName;

    if (!/^(body)$/i.test(tagName)) {
      return;
    }

    switch (event.code) {
      case 'ArrowLeft':
        this.prev();
        break;
      case 'ArrowRight':
        this.next();
        break;
      case 'Space':
        this.togglePlay(event);
        break;
      default:
        break;
    }
  };

  attachKeyboardListeners() {
    document.addEventListener('keydown', this.handleKeyboardEvent, false);
  }

  removeKeyboardListeners() {
    document.removeEventListener('keydown', this.handleKeyboardEvent);
  }

  componentDidMount(): void {
    this.attachKeyboardListeners();
  }

  componentWillUnmount(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.removeKeyboardListeners();
  }

  renderDimensionControl() {
    const { classes, theme, dimensionControlOpen, embedMode, fullScreenEnabled } = this.props;

    if (!(embedMode || fullScreenEnabled)) {
      return;
    }

    const buttonClasses = classNames(
      classes.controlIcon,
      theme === 'dark' && classes.controlIconDark,
      dimensionControlOpen && classes.controlIconActive
    );

    return (
      <IconButton
        onClick={this.onDimensionControlToggle(!dimensionControlOpen)}
        className={buttonClasses}
        title="Change dimensions"
      >
        <TuneIcon />
      </IconButton>
    );
  }

  renderLoopControl() {
    const { classes, theme } = this.props;
    const { loop } = this.state;
    const buttonClasses = classNames(
      classes.controlIcon,
      theme === 'dark' && classes.controlIconDark,
      loop && classes.controlIconActive
    );

    return (
      <IconButton
        onClick={this.onLoopChange(!loop)}
        className={buttonClasses}
        title="Loop"
      >
        <LoopIcon />
      </IconButton>
    );
  }

  renderLegendControl() {
    const { classes, theme, legendOpen } = this.props;
    const buttonClasses = classNames(
      classes.controlIcon,
      theme === 'dark' && classes.controlIconDark,
      legendOpen && classes.controlIconActive
    );

    return (
      <IconButton
        onClick={this.onLegendChange(!legendOpen)}
        className={buttonClasses}
        title="Legend"
      >
        <ArtTrackIcon />
      </IconButton>
    );
  }

  renderFullScreenControl() {
    const { classes, theme, fullScreenEnabled } = this.props;
    const buttonClasses = classNames(classes.controlIcon, theme === 'dark' && classes.controlIconDark);

    return fullScreenEnabled ?
      <IconButton
        onClick={this.onFullScreenChange(false)}
        className={buttonClasses}
        title="Exit fullscreen"
      >
        <FullscreenExitIcon />
      </IconButton>
      :
      <IconButton
        onClick={this.onFullScreenChange(true)}
        className={buttonClasses}
        title="Enter fullscreen"
      >
        <FullscreenIcon />
      </IconButton>;
  }

  render() {
    const { classes, selectedDimensionOption, theme, children } = this.props;
    const { isPlaying, loop, isNextLoading } = this.state;
    const allButtonsDisabled = !selectedDimensionOption;
    const buttonClasses = classNames(classes.controlIcon, theme === 'dark' && classes.controlIconDark);

    return (
      <Box className={classes.root} display="flex" alignItems="center">
        <IconButton
          onClick={this.rewindToStart}
          disabled={allButtonsDisabled || (this.isFirst() && !loop)}
          className={buttonClasses}
          title="First"
        >
          <SkipBackwardIcon />
        </IconButton>

        <IconButton
          onClick={this.prev}
          disabled={allButtonsDisabled || (this.isFirst() && !loop)}
          className={buttonClasses}
          title="Previous"
        >
          <SkipPreviousIcon />
        </IconButton>

        <IconButton
          onClick={this.togglePlay}
          className={buttonClasses}
          disabled={allButtonsDisabled}
          title={isPlaying ? 'Pause' : 'Play'}
          style={{ position: 'relative' }}
        >
          {
            isNextLoading && isPlaying &&
            <CircularProgress size={26} style={{ position: 'absolute' }} color="inherit" />
          }
          {
            isPlaying ? <PauseIcon /> : <PlayArrowIcon />
          }
        </IconButton>

        <IconButton
          onClick={this.next}
          disabled={allButtonsDisabled || (this.isLast() && !loop)}
          className={buttonClasses}
          title="Next"
        >
          <SkipNextIcon />
        </IconButton>



        {
          children &&
          <Box className={classes.additionalContent}>
            {children}
          </Box>
        }

        <Box
          style={{ marginLeft: 'auto' }}
        >
          {this.renderDimensionControl()}
          {this.renderLoopControl()}
          {this.renderLegendControl()}
          {this.renderFullScreenControl()}
        </Box>
      </Box>
    );
  }
}

export default decorate(Navigation);
