import screenfull, { Screenfull } from 'screenfull';
import React, { Component, createRef, ReactNode } from 'react';

type TProps = {
  enabled: boolean,
  onChange: (isFullscreen: boolean) => void,
  children: ReactNode
}

class Fullscreen extends Component<TProps> {
  private wrapperRef = createRef<HTMLDivElement>();

  componentDidMount(): void {
    if (!screenfull.isEnabled) {
      return;
    }

    screenfull.on('change', () => {
      screenfull && this.props.onChange((screenfull as Screenfull).isFullscreen);
    });
  }

  componentWillUnmount(): void {
    if (!screenfull.isEnabled) {
      return;
    }

    screenfull.off('change', () => {
      screenfull && this.props.onChange((screenfull as Screenfull).isFullscreen);
    });
  }

  componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { enabled } = this.props;

    if (
      !this.wrapperRef.current ||
      !screenfull ||
      !screenfull.isEnabled ||
      prevProps.enabled === enabled
    ) {
      return;
    }

    if (enabled) {
      screenfull.request(this.wrapperRef.current);
    } else {
      screenfull.exit();
    }
  }

  render() {
    return (
      <div ref={this.wrapperRef} style={{ height: '100%', width: '100%' }}>
        {this.props.children}
      </div>
    );
  }
}

export default Fullscreen;
