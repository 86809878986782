import { ComponentType } from 'react';
import { IProps } from './index';
import { connect } from 'react-redux';
import { displayFlashMessage } from '../../actions/flashMessage';

const mapDispatchToProps = {
  displayFlashMessage
};

export default (component: ComponentType<IProps>) => connect(null, mapDispatchToProps)(component);
