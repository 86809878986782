import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  card: {
    height: '100%'
  },
  linkArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    width: '100%'
  }
});
