import React, { Component, createRef } from 'react';
import { Slider as ReactCompoundSlider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import TooltipRail from './Rail';
import { Box } from '@material-ui/core';
import Handle from './Handle';
import Track from './Track';
import { rootSliderCssStyles } from './styles';
import decorate from './decorator';
import Tick from './Tick';

export interface SliderLabelMap extends Record<string, string> {}

export type TProps = {
  step: number;
  min: number;
  max: number;
  values: Array<number>;
  labelMap: SliderLabelMap;
  onChange: (values: ReadonlyArray<number>) => void;
  tickProps?: {
    values: Array<number>;
  };
  classes: {
    root: string;
  };
}

class Slider extends Component<TProps> {
  slider = createRef<HTMLDivElement>();

  state = {
    sliderWidth: null
  };

  private getSliderWidth(): number | null {
    const $el = this.slider && this.slider.current;

    return $el && $el.getBoundingClientRect().width;
  }

  updateSliderWidth = () => {
    const width = this.getSliderWidth();

    if (width) {
      this.setState({
        sliderWidth: width
      });
    }
  };

  componentDidMount(): void {
    this.updateSliderWidth();

    window.addEventListener('resize', this.updateSliderWidth);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateSliderWidth);
  }

  render() {
    const { classes, min, max, values, step, onChange, tickProps, labelMap } = this.props;
    const { sliderWidth } = this.state;
    const domain = [min, max];

    return (
      <div className={classes.root} ref={this.slider}>
        <ReactCompoundSlider
          rootStyle={rootSliderCssStyles}
          domain={domain}
          values={values}
          step={step}
          onChange={onChange}
        >
          <Rail>
            {(railProps) => <TooltipRail {...railProps} sliderWidth={sliderWidth} labelMap={labelMap} />}
          </Rail>

          <Handles>
            {({ handles, activeHandleID, getHandleProps }) => (
              <Box>
                {
                  handles.map(handle => (
                    <Handle
                      key={handle.id}
                      isActive={handle.id === activeHandleID}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                      sliderWidth={sliderWidth}
                      labelMap={labelMap}
                    />
                  ))
                }
              </Box>
            )}
          </Handles>

          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <Box>
                {tracks.map(({ id, source, target }) => {
                  return (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  );
                })}
              </Box>
            )}
          </Tracks>

          <Ticks {...tickProps}>
            {({ ticks }) => {
              return (
                <Box>
                  {
                    ticks.map(tick => (
                      <Tick key={tick.id} tick={tick} />
                    ))
                  }
                </Box>
              );
            }}
          </Ticks>
        </ReactCompoundSlider>
      </div>
    );
  }
}

export default decorate(Slider);
