import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(8, 4)
  },
  icon: {
    fontSize: '5em',
    marginBottom: '0.3em'
  }
});
