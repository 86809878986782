import { TAppState } from '../store';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

type AsyncAction<T> = (dispatch: ThunkDispatch<TAppState, void, Action>) => Promise<T>;
type Dispatcher = <T>(action: T | Action) => T extends AsyncAction<any> ?
  ReturnType<T> : void;

const useAppDispatch: () => Dispatcher = useDispatch as any;

export default useAppDispatch;
