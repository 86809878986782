import { createStyles, Theme } from '@material-ui/core';
import { PlayerZIndex } from '../styles';

export default (theme: Theme) => createStyles({
  root: {
    width: '100%',
    background: theme.player.background,
    padding: '0 8px',
    bottom: 0,
    color: 'white',
    transition: 'transform 0.3s linear',
    zIndex: PlayerZIndex.CONTROLS
  },
  rootAsOverlay: {
    position: 'absolute'
  },
  rootHidden: {
    transform: 'translateY(60px)'
  }
});
