import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {},
  locationControlWrapper: {
    marginLeft: theme.spacing(4),
    flexGrow: 1,
    maxWidth: 360,
    minWidth: 220,

    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
      width: '100%',
      maxWidth: 'auto'
    }
  }
});
