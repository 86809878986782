import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TLegendJSONArrow } from '../../../../../api/axis';
import decorate from './decorator';
import LegendTitle from '../../LegendTitle';
import WindArrowIcon from '../../../../Icons/WindArrowIcon';

export interface IProps {
  item: TLegendJSONArrow;
  classes: {
    root: string;
  }
}

class LegendJSONArrow extends Component<IProps> {
  render() {
    const { item, classes } = this.props;
    const entry = item.data.entries[0];

    if (!entry) {
      return null;
    }

    return (
      <Box className={classes.root}>
        <LegendTitle item={item} />

        <Box display="flex" alignItems="center" style={{ padding: '1em 0' }}>
          <div style={{ color: entry.colour, display: 'flex', alignItems: 'center' }}>
            <WindArrowIcon />
          </div>

          <Typography variant="body2" style={{ marginLeft: '1em' }}>
            {entry.text}
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default decorate(LegendJSONArrow);
