import { combineReducers } from 'redux';
import announcements from './announcements';
import flashMessage from './flashMessage';
import groups from './groups';
import packages from './packages';
import productData from './productData';
import productDetails from './productDetails';
import productsByPackage from './productsByPackage';
import productCollectionsByPackage from './productCollectionsByPackage';
import settings from './settings';
import recentCyclonesByProduct from './recentCyclonesByProduct';
import recentLocationsByProduct from './recentLocationsByProduct';
import localStorage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const recentLocationsPersistConfig = {
  key: 'webplots:recentLocationsByProduct',
  storage: localStorage
};

const recentCyclonesPersistConfig = {
  key: 'webplots:recentCyclonesByProduct',
  storage: localStorage
};

const rootReducer = combineReducers({
  announcements,
  flashMessage,
  groups,
  packages,
  productDetails,
  productData,
  productsByPackage,
  productCollectionsByPackage,
  settings,
  recentCyclonesByProduct: persistReducer(recentCyclonesPersistConfig, recentCyclonesByProduct),
  recentLocationsByProduct: persistReducer(recentLocationsPersistConfig, recentLocationsByProduct)
});

export default rootReducer;
