import {
  Box,
  Button,
  Card, CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Typography,
  WithStyles
} from '@material-ui/core';
import React, { createRef, MouseEvent } from 'react';
import { TProduct } from '../../../api/product';
import CardActionAreaLink from '../../../components/CardActionAreaLink';
import styles from './styles';
import decorate from './decorator';
import paths from '../../../router/paths';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { addProductToCollection, removeProductFromCollection } from '../../../actions/productCollection';
import JupyterIcon from '../../../components/Icons/Jupyter';
import JupyterColoredIcon from '../../../components/Icons/JupyterColored';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ProductFeatures from './ProductFeatures';

export interface IProps extends WithStyles<typeof styles> {
  product: TProduct;
  packageName: string;
  addProductToCollection: typeof addProductToCollection;
  removeProductFromCollection: typeof removeProductFromCollection;
  selected?: boolean;
  chartSetButtonEnabled?: boolean;
}


class ProductCard extends React.Component<IProps> {
  static defaultProps = {
    chartSetButtonEnabled: true
  };

  $root = createRef<HTMLDivElement>();

  expandContent = () => {
    this.setState({
      expanded: true
    });
  };

  collapseContent = () => {
    this.setState({
      expanded: false
    });
  };

  onAddToCollection = (ev: MouseEvent<HTMLButtonElement>) => {
    const { product, packageName, addProductToCollection } = this.props;

    ev.stopPropagation();
    ev.preventDefault();

    addProductToCollection(packageName, product.name);
  }

  removeFromCollection = (ev: MouseEvent<HTMLButtonElement>) => {
    const { product, packageName, removeProductFromCollection } = this.props;

    ev.stopPropagation();
    ev.preventDefault();

    removeProductFromCollection(packageName, product.name);
  }

  addExpansionListeners() {
    if (this.$root.current) {
      this.$root.current.addEventListener('mouseenter', this.expandContent);
      this.$root.current.addEventListener('mouseleave', this.collapseContent);
    }
  }

  removeExpansionListeners() {
    if (this.$root.current) {
      this.$root.current.removeEventListener('mouseenter', this.expandContent);
      this.$root.current.removeEventListener('mouseleave', this.collapseContent);
    }
  }

  componentDidMount() {
    this.addExpansionListeners();
  }

  componentWillUnmount(): void {
    this.removeExpansionListeners();
  }

  renderCollectionButton() {
    const { selected, chartSetButtonEnabled } = this.props;

    if (selected) {
      return (
        <Button
          color="secondary"
          onClick={this.removeFromCollection}
          disabled={!chartSetButtonEnabled}
        >
          <HighlightOffIcon /> <Box component="span" marginLeft={1}>Remove from ChartSet</Box>
        </Button>
      );
    }

    return (
      <Button
        color="secondary"
        onClick={this.onAddToCollection}
        disabled={!chartSetButtonEnabled}
      >
        <PlaylistAddIcon /> <Box component="span" marginLeft={1}>Add to ChartSet</Box>
      </Button>
    );
  }

  renderChartSetIcon() {
    const { selected, chartSetButtonEnabled } = this.props;

    if (selected) {
      return (
        <IconButton
          onClick={this.removeFromCollection}
          disabled={!chartSetButtonEnabled}
          style={{ color: 'green', position: 'absolute' }}
          title="Remove from ChartSet"
        >
          <PlaylistAddCheckIcon />
        </IconButton>
      );
    }

    return (
      <IconButton
        color="secondary"
        onClick={this.onAddToCollection}
        disabled={!chartSetButtonEnabled}
        title="Add to ChartSet"
        style={{ position: 'absolute' }}
      >
        <PlaylistAddIcon />
      </IconButton>
    );
  }

  renderFeatureIndicators() {
    const { product, classes } = this.props;

    return (
      <Box display="flex" position='absolute' bottom={0} right={0} padding={0.5}>
        {
          product.notebook &&
          <JupyterIcon className={classes.featureIndicatorIcon} />
        }
        <span><JupyterColoredIcon className={classes.featureIndicatorIcon} /></span>
      </Box>
    );
  }

  render() {
    const { classes, product, packageName } = this.props;
    const { name, title, thumbnail, subtitle, short_description } = product;
    const redirectPath = paths.productDetails(packageName, name);
    const hasProductFeatures = Boolean(product.notebook);

    return (
      <Card className={classes.root} ref={this.$root}>
        <CardActionAreaLink to={redirectPath} className={classes.contentArea} title={product.title} disableRipple>
          <CardMedia
            className={classes.media}
            image={thumbnail}
            title={title}
          />

          <CardContent className={classes.content}>
            <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.preTitleContent}>
              {/* <Box className={classes.productGroupWrapper}>*/}
              {/*  {*/}
              {/*    product.groups.map(group => <Chip label={group} key={`${product.name}-${group}`} size="small"*/}
              {/*      className={classes.productGroup} />)*/}
              {/*  }*/}
              {/* </Box>*/}

              <Box className={classes.productGroupWrapper}>
                {
                  product.groups.map(group =>
                    <Typography
                      component="span"
                      key={`${product.name}-${group}`}
                      className={classes.productGroup}
                      color="textSecondary"
                      variant="body2"
                    >
                      {group}
                    </Typography>)
                }
              </Box>

              <Box className={classes.featuresIndicatorsWrapper} style={{ width: 48 }}>
                {
                  this.renderChartSetIcon()
                }
                {/* <IconButton*/}
                {/*  onClick={(event) => {*/}
                {/*    event.nativeEvent.stopImmediatePropagation();*/}
                {/*    event.stopPropagation();*/}
                {/*    event.preventDefault();*/}
                {/*    console.log('event', event);*/}
                {/*  }}*/}
                {/*  color="secondary"*/}
                {/*  style={{ position: 'absolute' }}*/}
                {/* ><PlaylistAddIcon /></IconButton>*/}
              </Box>
            </Box>

            <Typography component="h3" className={classes.title} variant="h4">
              {title}
            </Typography>

            {
              short_description &&
              <Typography
                component="div"
                variant="body2"
                color="textSecondary"
                className={classes.description}
              >
                {
                  short_description
                }
              </Typography>
            }


          </CardContent>
        </CardActionAreaLink>

        <CardActions>
          {
            hasProductFeatures &&
            <Box style={{ marginTop: 'auto', width: '100%' }}>
              <Box display="flex" alignItems="center" className={classes.features}>
                <ProductFeatures product={product} />
              </Box>
            </Box>
          }
        </CardActions>
        {/* <CardActions>*/}
        {/*  {*/}
        {/*    this.renderCollectionButton()*/}
        {/*  }*/}
        {/* </CardActions>*/}
      </Card>
    );
  }
}

export default decorate(ProductCard);
