import {
  IProductDataFetchCompleteAction,
  IProductDataFetchFailedAction,
  IProductDataFetchStartAction,
  PRODUCT_DATA_FETCH_COMPLETED,
  PRODUCT_DATA_FETCH_FAILED,
  PRODUCT_DATA_FETCH_STARTED,
  TProductDimensionParams
} from '../actions/productData';
import { IAxisFetchResponseResult, TLegend } from '../api/axis';
import { pick, reduce } from 'lodash';
import * as StoreIndex from '../utils/storeIndex';
import { Nullable } from '../utils/types';

export enum ProductDataItemStatus {
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}

export type TProductDataItem = {
  status: ProductDataItemStatus;
  url: string | null;
  info?: string;
  error?: string;
  legend: Array<TLegend>;
  click?: {
    url: string;
    tooltip: string;
    config: {
      widget: Nullable<'epsgrams'>;
      tooltip: string;
      title: string;
    };
    navigation: {
      crs: string;
      xmin: number;
      ymin: number;
      width: number;
      height: number;
    }
    axis: Array<{
      values: Array<{
        value: string;
        label: string;
      }>
      name: string;
      title: string;
    }>
  };
}

type TProductDataState = {
  [key: string]: TProductDataItem
};

type AllowedActions =
  IProductDataFetchStartAction |
  IProductDataFetchCompleteAction |
  IProductDataFetchFailedAction;

const initialState = {};

const updateState = (
  state: TProductDataState,
  packageName: string,
  productName: string,
  selectedDimension: string,
  params: TProductDimensionParams,
  items: { [value: string]: IAxisFetchResponseResult },
  status: TProductDataItem['status']
): TProductDataState => {
  const newState = reduce(items, (acc: TProductDataState, item, value) => {
    const key = StoreIndex.productData(packageName, productName, {
      ...params,
      [selectedDimension]: value
    });

    acc[key] = {
      ...pick(item, 'url', 'legend', 'click', 'info', 'error'),
      status
    };

    return acc;
  }, {} as TProductDataState);

  return {
    ...state,
    ...newState
  };
};

const mapDimensionValuesToEmptyItems = (dimensionValues: Array<string | number>) => {
  return dimensionValues.reduce((acc: { [value: string]: IAxisFetchResponseResult }, dimensionValue) => {
    acc[dimensionValue] = {
      url: null,
      value: dimensionValue,
      label: '',
      legend: []
    };

    return acc;
  }, {});
};

export default (state: TProductDataState = initialState, action: AllowedActions): TProductDataState => {
  switch (action.type) {

    case PRODUCT_DATA_FETCH_STARTED:
      return updateState(
        state,
        action.packageName,
        action.productName,
        action.dimensionName,
        action.params,
        mapDimensionValuesToEmptyItems(action.requestedValues),
        ProductDataItemStatus.LOADING
      );

    case PRODUCT_DATA_FETCH_COMPLETED:
      return updateState(
        state,
        action.packageName,
        action.productName,
        action.dimensionName,
        action.params,
        action.data,
        ProductDataItemStatus.LOADED
      );

    case PRODUCT_DATA_FETCH_FAILED:
      return updateState(
        state,
        action.packageName,
        action.productName,
        action.dimensionName,
        action.params,
        mapDimensionValuesToEmptyItems(action.requestedValues),
        ProductDataItemStatus.ERROR
      );

    default:
      return state;
  }
};
