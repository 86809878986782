import { createStyles, Theme } from '@material-ui/core';
import { PlayerZIndex } from '../styles';

export default (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    background: theme.palette.common.white
  },
  loading: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 48,
    height: 48,
    margin: 'auto'
  },
  mediaRoot: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    width: '100%',
    height: '100%'
  },
  mediaLoading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
  },
  mediaNotAvailable: {
    position: 'absolute'
  },
  mediaVisible: {
    zIndex: PlayerZIndex.VISIBLE_CONTENT
  }
});
