import {
  IAddProductToCollectionAction,
  PRODUCT_COLLECTION_ITEM_ADDED,
  PRODUCT_COLLECTION_CLEAR_COMPLETED,
  PRODUCT_COLLECTION_ITEM_REMOVE_COMPLETED,
  IClearProductCollectionAction, IRemoveProductFromCollectionAction
} from '../actions/productCollection';
import { uniq } from 'lodash';

interface IProductCollectionsByPackageState {
  [packageName: string]: Array<string>
}

type TAllowedActions =
  IAddProductToCollectionAction |
  IClearProductCollectionAction |
  IRemoveProductFromCollectionAction;

const initialState = {};

const updateStateWithNewProduct = (
  state: IProductCollectionsByPackageState,
  packageName: string,
  productName: string
) => {
  const current = state[packageName] ?? [];

  return {
    ...state,
    [packageName]: uniq([...current, productName])
  };
};

const removeProductFromState = (
  state: IProductCollectionsByPackageState,
  packageName: string,
  productName: string
) => {
  return {
    ...state,
    [packageName]: state[packageName].filter(v => v !== productName)
  };
};

const clearState = (state: IProductCollectionsByPackageState, packageName: string) => {
  return {
    ...state,
    [packageName]: []
  };
};

export default (state: IProductCollectionsByPackageState = initialState, action: TAllowedActions) => {
  switch (action.type) {
    case PRODUCT_COLLECTION_ITEM_ADDED: {
      return updateStateWithNewProduct(state, action.packageName, action.productName);
    }

    case PRODUCT_COLLECTION_ITEM_REMOVE_COMPLETED: {
      return removeProductFromState(state, action.packageName, action.productName);
    }

    case PRODUCT_COLLECTION_CLEAR_COMPLETED: {
      return clearState(state, action.packageName);
    }

    default:
      return state;
  }
};
