import { Direction, Transitions, TransitionsOptions } from '@material-ui/core/styles';

import { Palette, PaletteColor, PaletteColorOptions, PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Shape, ShapeOptions } from '@material-ui/core/styles/shape';
import { Breakpoints, BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';
import { Mixins, MixinsOptions } from '@material-ui/core/styles/createMixins';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { Shadows } from '@material-ui/core/styles/shadows';
import { Spacing, SpacingOptions } from '@material-ui/core/styles/createSpacing';
import { Typography, TypographyOptions } from '@material-ui/core/styles/createTypography';
import { ZIndex, ZIndexOptions } from '@material-ui/core/styles/zIndex';
import { createMuiTheme } from '@material-ui/core';
import { deepOrange, green } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    link: PaletteColor,
    success: PaletteColor,
    warning: PaletteColor
  }

  interface PaletteOptions {
    link?: PaletteColorOptions,
    success?: PaletteColorOptions,
    warning?: PaletteColorOptions
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    shape?: ShapeOptions;
    breakpoints?: BreakpointsOptions;
    direction?: Direction;
    mixins?: MixinsOptions;
    overrides?: Overrides;
    palette?: PaletteOptions;
    props?: ComponentsProps;
    shadows?: Shadows;
    spacing?: SpacingOptions;
    transitions?: TransitionsOptions;
    typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
    zIndex?: ZIndexOptions;

    player: PlayerThemeProps
  }

  interface Theme {
    shape: Shape;
    breakpoints: Breakpoints;
    direction: Direction;
    mixins: Mixins;
    overrides?: Overrides;
    palette: Palette;
    props?: ComponentsProps;
    shadows: Shadows;
    spacing: Spacing;
    transitions: Transitions;
    typography: Typography;
    zIndex: ZIndex;

    player: PlayerThemeProps
  }
}

interface PlayerThemeProps {
  background: string;
}

const theme = (embedMode: boolean, palette: 'light' | 'dark' = 'light') => createMuiTheme({
  palette: {
    type: palette,
    background: {
      default: embedMode ? '#ffffff' : '#f1f1f1'
    },
    primary: {
      main: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#e08d23',
      contrastText: '#ffffff'
    },
    link: {
      main: '#008099'
    },
    success: {
      main: green[700],
      contrastText: '#ffffff'
    },
    warning: {
      main: deepOrange[700],
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '2.4rem',
      fontWeight: 300
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 300
    },
    h3: {
      fontSize: '1.825rem',
      fontWeight: 300
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 300
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 300
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 300,
      textTransform: 'uppercase'
    }
  },
  player: {
    background: 'rgba(0, 0, 0, 0.75)'
  },
  overrides: {
    MuiCardHeader: {
      root: {
        paddingBottom: 0
      }
    },
    MuiLink: {
      root: {
        color: '#008099'
      }
    }
  }
});

export default theme;
