import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'visible',
    height: '100%'
  },
  contentArea: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    bottom: 0,
    width: '100%',
    color: theme.palette.common.black,
    transition: 'height 0.2s linear',
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(2),
    fontSize: '1.3em',
    fontWeight: 500
  },
  description: {
    // marginBottom: theme.spacing(2)
  },
  featureIndicatorIcon: {
    width: '1.3em',
    height: '1.3em'
  },
  media: {
    position: 'relative',
    width: '100%',
    paddingBottom: '60%'
  },
  preTitleContent: {
    minHeight: 18,
    marginBottom: theme.spacing(1)
  },
  featuresIndicatorsWrapper: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    position: 'relative',
    marginRight: theme.spacing(-2)
  },
  productGroupWrapper: {
    display: 'flex'
  },
  productGroup: {
    flexGrow: 1,
    fontSize: '0.9em',
    marginRight: theme.spacing(1)
  },
  features: {
  }
});
