import { TropicalCycloneMapConfig } from './TropicalCycloneMap';

const MAP_GEO_BOUNDARY = {
  x0: -20,
  x1: -20,
  y0: -60,
  y1: 64
};

const latToPxPosition = (lat: number, boxHeight: number) => {
  const { y0, y1 } = MAP_GEO_BOUNDARY;
  const distance = y1 - lat;
  const geoDistance = Math.abs(y1 - y0);

  const latAsPx = boxHeight / geoDistance;

  return distance * latAsPx;
};

const lonToPxPosition = (lon: number, boxWidth: number) => {
  const distance = lon < 0 ? 360 + lon : lon;

  const lonAsPx = boxWidth / 360;

  return distance * lonAsPx;
};

const lonToPx = (
  lon: number,
  mapConfig: TropicalCycloneMapConfig,
  imageConfig: {
    x: number,
    width: number
  }
) => {
  const { mapArea } = mapConfig;
  const sizeRatio = imageConfig.width / mapConfig.width;

  return imageConfig.x +
    (mapArea.xOffset + lonToPxPosition(lon - MAP_GEO_BOUNDARY.x0, mapArea.width)) * sizeRatio;
};

const latToPx = (
  lat: number,
  mapConfig: TropicalCycloneMapConfig,
  imageConfig: {
    y: number,
    width: number
  }
) => {
  const { mapArea } = mapConfig;
  const sizeRatio = imageConfig.width / mapConfig.width;

  return imageConfig.y + (mapArea.yOffset + latToPxPosition(lat, mapArea.height)) * sizeRatio;
};


export {
  latToPx,
  lonToPx
};
