import client from './client';

export type TPackage = {
  name: string,
  title: string,
  thumbnail: string,
  links: Array<{
    title: string,
    url: string
  }>
}

interface IResponse {
  packages: Array<TPackage>,
  tracker: string,
  categories: Array<any>,
  uid: string
}

interface TPackageListParams {
  scope?: string;
}

const list = (params: TPackageListParams = {}): Promise<IResponse> =>
  client.get('/packages/', {
    params
  }).then(response => response.data);

export {
  list
};
