import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

interface IProps extends SvgIconProps {
}

const DashboardCustomizeIcon = (props: IProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 3h8v8H3zm10 0h8v8h-8zM3 13h8v8H3zm15 0h-2v3h-3v2h3v3h2v-3h3v-2h-3z" />
    </SvgIcon>
  );
};

export default DashboardCustomizeIcon;
