import { compact } from 'lodash';

const parseLatitude = (value: number | string): [number, 'N' | 'S'] => {
  const parsedValue = parseFloat(value.toString());

  return [
    Math.abs(parsedValue),
    parsedValue > 0 ? 'N' : 'S'
  ];
};

const parseLongitude = (value: number | string): [number, 'W' | 'E'] => {
  const parsedValue = parseFloat(value.toString());

  return [
    Math.abs(parsedValue),
    parsedValue > 0 ? 'E' : 'W'
  ];
};

const formatCoordinates = (lat: number | string, lon: number | string, altitude?: number | string) => {
  return compact([
    parseLatitude(lat as string).join('°'),
    parseLongitude(lon as string).join('°'),
    altitude && `at ${altitude}m`
  ]).join(' ');
};

export {
  parseLatitude,
  parseLongitude,
  formatCoordinates
};
