import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    height: '100%'
  },
  cardArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    width: '100%',
    marginTop: 'auto',
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.grey[200]}`
  }
});
