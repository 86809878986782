import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import decorate from './decorator';

export type TProps = {
  children: ReactNode
  classes: {
    root: string
  }
}

const PageWrapper = (props: TProps) => {
  const { children, classes } = props;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        {children}
      </Grid>
    </Grid>
  );
};

export default decorate(PageWrapper);
