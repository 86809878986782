import React, { Component } from 'react';
import { Box, IconButton } from '@material-ui/core';
import decorate from './decorator';
import classNames from 'classnames';
import { TLegend } from '../../../api/axis';
import LegendImage from './LegendImage';
import LegendJSON from './LegendJSON';
import CloseIcon from '@material-ui/icons/Close';

export interface IProps {
  items: Array<TLegend>;
  visible: boolean;
  controlsOverlay?: boolean;
  onClose?: () => void;
  classes: {
    root: string;
    controlsOverlay: string;
    visible: string;
  };
}

class Legend extends Component<IProps> {
  renderLegend = (item: TLegend) => {
    switch (item.format) {
      case 'image':
        return <LegendImage item={item} key={item.data.title} />;
      case 'json':
        return <LegendJSON item={item} key={item.data.title} />;
      default:
        return null;
    }
  };

  render() {
    const { classes, visible, controlsOverlay, items, onClose } = this.props;

    const rootClassName = classNames(
      classes.root,
      controlsOverlay && classes.controlsOverlay,
      visible && classes.visible
    );

    return (
      <Box className={rootClassName}>
        {
          onClose &&
          <Box style={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        }

        {
          items.map(this.renderLegend)
        }
      </Box>
    );
  }
}

export default decorate(Legend);
