import { withStyles } from '@material-ui/core';
import styles from './styles';
import { ComponentType } from 'react';
import { IProps } from './index';
import { TAppState } from '../../../../../../store';
import { connect } from 'react-redux';
import * as StoreIndex from '../../../../../../utils/storeIndex';
import { flow } from 'lodash';

const mapStateToProps = (state: TAppState, props: IProps) => {
  const { packageName, productName } = props;
  const locations = state.recentLocationsByProduct[StoreIndex.recentLocation(packageName, productName)] ?? [];

  return {
    locations
  };
};

export default (component: ComponentType<IProps>): ComponentType<Omit<IProps, 'classes' | 'locations'>> =>
  flow(
    withStyles(styles),
    connect(mapStateToProps)
  )(component);
