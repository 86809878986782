import { Box, Typography } from '@material-ui/core';
import React, { Component, ReactNode } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import decorate from './decorator';

export interface IProps {
  title: ReactNode,
  description?: ReactNode,
  classes: {
    root: string;
    icon: string;
  };
}

class PageError extends Component<IProps> {
  render() {
    const { title, description, classes } = this.props;

    return (
      <Box className={classes.root}>
        <ErrorOutlineIcon className={classes.icon} />

        <Typography variant="h3" gutterBottom={Boolean(description)}>
          {title}
        </Typography>

        {
          Boolean(description) &&
          <Typography variant="h5">
            {description}
          </Typography>
        }
      </Box>
    );
  }
}

export default decorate(PageError);
