import React, { Component } from 'react';
import ProductDataPointMedia from '../../../components/ProductDataPointMedia';
import decorate, { mapDispatchToProps } from './decorator';
import { Dimension, DimensionSelectionMapping } from '../../Product';
import { omit, isEqual } from 'lodash';
import { PlayerDimensionDataItem } from '../../../components/Player';
import { TProductDetails } from '../../../api/product';
import deriveDimensionsFromAxis from '../../../utils/deriveDimensionsFromAxis';
import { Box } from '@material-ui/core';

export interface IProps extends ReturnType<typeof mapDispatchToProps> {
  product: TProductDetails,
  selectedDimensions: DimensionSelectionMapping;
  item?: PlayerDimensionDataItem;
  dimensions: Array<Dimension>;
}

class ChartSetMedia extends Component<IProps> {
  fetchDataPoint() {
    const { fetchProductData, selectedDimensions, product, dimensions } = this.props;
    const packageName = product.package.name;
    const productName = product.name;

    const { selected } = deriveDimensionsFromAxis(product.axis, selectedDimensions as Record<string, string>);

    const key = Object.keys(selected)[0];
    const params = omit(selected, key);

    return fetchProductData(packageName, productName, dimensions, key, params, [selectedDimensions[key]]);
  }

  componentDidMount() {
    this.fetchDataPoint();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    const { selectedDimensions } = this.props;

    if (!isEqual(prevProps.selectedDimensions, selectedDimensions)) {
      this.fetchDataPoint();
    }
  }

  render() {
    const { item, product } = this.props;

    return (
      <Box>
        <ProductDataPointMedia
          item={item}
          visible
          title={product.title}
        />
      </Box>
    );
  }
}

export default decorate(ChartSetMedia);
