import { createStyles, Theme } from '@material-ui/core';

export enum PlayerZIndex {
  VISIBLE_CONTENT = 1,
  CONTROLS = 5
}

export default (theme: Theme) => createStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden'
  },
  rootEmbedded: {
    height: '100vh'
  },
  infoExtra: {
    marginTop: theme.spacing(2)
  }
});
