import { Cyclone } from '../../../../../../../api/axis';
import { TropicalCycloneMapConfig } from '../TropicalCycloneMap';
import { ObjectFitSize } from '../../../../../../../components/ProductDataPointMedia';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import LocationIcon from '@material-ui/icons/LocationOn';
import { blue } from '@material-ui/core/colors';
import { latToPx, lonToPx } from '../utils';

interface Props {
  cyclone: Cyclone;
  mapConfig: TropicalCycloneMapConfig;
  imageConfig: ObjectFitSize;
  onSelect: (cycloneId: string) => void;
}

const useMarkerStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '1.1em'
  }
}));

const Marker = (props: Props) => {
  const { cyclone, imageConfig, mapConfig, onSelect } = props;
  const tooltipClasses = useMarkerStyles();

  const markerSize = Math.floor(imageConfig.width / 25);

  // markerSize x0.9 to take padding of the icon into consideration
  const top = latToPx(cyclone.lat, mapConfig, imageConfig) - (markerSize * 0.9);
  const left = lonToPx(cyclone.lon, mapConfig, imageConfig) - (markerSize / 2);

  const onClick = useCallback(() => {
    onSelect(cyclone.unique_id);
  }, [onSelect, cyclone.unique_id]);

  return (
    <Box
      style={{
        width: markerSize,
        height: markerSize,
        borderRadius: '50%',
        zIndex: 10,
        position: 'absolute',
        top,
        left,
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <Tooltip title={cyclone.name} placement="right" arrow classes={tooltipClasses}>
        <LocationIcon style={{ height: '100%', width: '100%', color: blue[800] }} />
      </Tooltip>
    </Box>
  );
};

export default Marker;
