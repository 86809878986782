import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 3, 2),
    textAlign: 'center',
    minWidth: 500,

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto'
    }
  },
  icon: {
    fontSize: '3em',
    marginBottom: '0.3em'
  }
});
