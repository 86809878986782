import React, { Component } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Dimension, DimensionSelectionMapping } from '../../containers/Product';
import { TProductDataItem } from '../../reducers/productData';
import decorate from './decorator';
import { isEqual, noop } from 'lodash';
import ProductDataItemDownloadButton from '../ProductDataItemDownloadButton';
import { mapDispatchToProps } from './decorator';
import ProductDataPointMedia from '../ProductDataPointMedia';

export interface IProps extends ReturnType<typeof mapDispatchToProps> {
  packageName: string;
  productName: string;
  dimensions: Array<Dimension>;
  productValues: DimensionSelectionMapping;
  productDataItem: TProductDataItem;
  notebook: boolean;
  script: boolean;
  classes: {
    root: string;
    loadingWrapper: string;
    downloadButton: string;
  }
}

class SinglePointViewer extends Component<IProps> {
  loadData() {
    const {
      productValues,
      packageName,
      productName,
      dimensions,
      fetchProductData
    } = this.props;
    const dimensionName = Object.keys(productValues)[0];
console.log({props: this.props, dimensionName});

    if (dimensionName) {
      const valueToFetch = productValues[dimensionName];

      return fetchProductData(
        packageName,
        productName,
        dimensions,
        dimensionName,
        productValues,
        [valueToFetch]
      ).catch(noop);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (!isEqual(prevProps.productValues, this.props.productValues)) {
      this.loadData();
    }
  }

  componentDidMount() {
    this.loadData();
  }

  renderLoading() {
    const { classes } = this.props;

    return (
      <Box className={classes.loadingWrapper}>
        <CircularProgress />
      </Box>
    );
  }

  render() {
    const { classes, packageName, productName, productValues, productDataItem, notebook, script } = this.props;

    if (!productDataItem) {
      return this.renderLoading();
    }

    return (
      <Box className={classes.root}>
        <ProductDataItemDownloadButton
          className={classes.downloadButton}
          notebook={notebook}
          script={script}
          pointData={{
            packageName,
            productName,
            values: productValues
          }}
        />

        <ProductDataPointMedia
          item={{
            label: '',
            value: '',
            ...productDataItem
          }}
          title=""
          visible
        />
      </Box>
    );
  }
}

export default decorate(SinglePointViewer);
