import decorate from './decorator';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

export interface IProps {
  classes: {
    root: string;
  }
}

const NotFound = (props: IProps) => {
  const { classes } = props;

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      className={classes.root}
    >
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Page not found.
        </Typography>
        <Typography variant="h5">
          Maybe the page you are looking for has been removed, or you typed in the wrong URL.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default decorate(NotFound);
