import { Typography } from '@material-ui/core';
import React from 'react';
import { ILegend } from '../../../../api/axis';

interface IProps {
  item: ILegend<any, {
    title: string;
    units?: string;
  }>
}

const LegendTitle = (props: IProps) => {
  const { item } = props;

  return (
    <Typography variant="body2" align="center">
      {item.data.title}
      {
        item.data.units &&
        <span>&nbsp;({item.data.units})</span>
      }
    </Typography>
  );
};

export default LegendTitle;
