import React, { PureComponent } from 'react';
import decorate from './decorator';
import { Typography } from '@material-ui/core';
import { TOOLTIP_WIDTH } from '../constants';

export interface IProps {
  percent: number | null;
  sliderWidth: number | null;
  text: string;
  classes: {
    root: string;
  };
}

class Tooltip extends PureComponent<IProps> {
  calculatePosition() {
    const { percent, sliderWidth } = this.props;

    const tooltipWidth = TOOLTIP_WIDTH;

    if (!sliderWidth) {
      return percent || 0;
    }

    const positionInPixels = (percent || 0) / 100 * sliderWidth;

    if (positionInPixels <= tooltipWidth / 2) {
      return 0;
    } else if (positionInPixels >= sliderWidth - tooltipWidth / 2) {
      return sliderWidth - tooltipWidth;
    } else {
      return positionInPixels - tooltipWidth / 2;
    }
  }

  render() {
    const { classes, text } = this.props;

    const left = this.calculatePosition();

    return (
      <Typography
        variant="caption"
        style={{ left }}
        className={classes.root}
      >
        {text}
      </Typography>
    );
  }
}

export default decorate(Tooltip);
