import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import decorate from './decorator';
import { SvgIcon } from '@material-ui/core';
import Markdown from '../Markdown';

export type TFlashMessageVariants = 'INFO' | 'ERROR' | 'SUCCESS';

type TVariantsConfig = {
  [key in TFlashMessageVariants]: {
    className: 'info' | 'success' | 'error',
    icon: typeof SvgIcon
  }
}

const variants: TVariantsConfig = {
  ERROR: {
    className: 'error',
    icon: ErrorIcon
  },
  INFO: {
    className: 'info',
    icon: InfoIcon
  },
  SUCCESS: {
    className: 'success',
    icon: CheckCircleIcon
  }
};

export interface IProps {
  flashMessage: {
    type: TFlashMessageVariants;
    text: string;
  };
  flashMessageHide: () => void;
  classes: {
    root: string;
    success: string;
    info: string;
    error: string;
    icon: string;
    iconVariant: string;
    message: string;
  };
}

class FlashMessage extends Component<IProps> {
  onClose = () => {
    this.props.flashMessageHide();
  };

  renderContent() {
    const { flashMessage: { type, text }, classes } = this.props;

    if (!type) {
      return;
    }

    const { icon: Icon, className } = variants[type];

    return (
      <SnackbarContent
        className={classNames(classes.root, classes[className])}
        aria-describedby="flash-message"
        message={
          <span id="flash-message" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            <Markdown source={text} removeParagraphSpacing />
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    );
  }

  render() {
    const { flashMessage: { type } } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={Boolean(type)}
        autoHideDuration={5000}
        onClose={this.onClose}
      >
        {
          this.renderContent()
        }
      </Snackbar>
    );
  }
}

export default decorate(FlashMessage);
