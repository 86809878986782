export type TImageSize = {
  width: number,
  height: number
}

const imageSize = (url: string): Promise<TImageSize> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = (event: Event) => {
      const target = event.target as HTMLImageElement;

      resolve({
        width: target.naturalWidth,
        height: target.naturalHeight
      });
    };

    img.onerror = function(error) {
      reject(error);
    };

    img.src = url;
  });
};

export default imageSize;
