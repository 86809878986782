import { Dialog, DialogContent } from '@material-ui/core';
import React, { Component, ReactNode } from 'react';
import decorate from './decorator';

export type TProps = {
  open: boolean,
  onClose: () => void,
  children: ReactNode,
  classes: {
    dialogContent: string
  }
}

class ProductShareDialog extends Component<TProps> {
  render() {
    const { open, onClose, children, classes } = this.props;

    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

export default decorate(ProductShareDialog);
