import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import decorate from './decorator';
import classNames from 'classnames';
import { TLegendJSONColorbar } from '../../../../../api/axis';
import LegendTitle from '../../LegendTitle';

export interface IProps {
  item: TLegendJSONColorbar,
  classes: {
    root: string;
    legendWrapper: string;
    legendItem: string;
    value: string;
    minValue: string;
    maxValue: string;
  }
}

class LegendJSONColorbar extends Component<IProps> {
  renderItem = (
    item: TLegendJSONColorbar['data']['entries'][0],
    index: number,
    items: TLegendJSONColorbar['data']['entries']
  ) => {
    const { classes } = this.props;
    const nextItem = items[index + 1];
    const key = `${item['max-range']}-${item['min-range']}`;

    return (
      <div key={key} className={classes.legendItem} style={{ background: item.colour }}>
        <div className={classNames(classes.value, classes.minValue)}>{item['min-range']}</div>
        {
          !nextItem &&
          <div className={classNames(classes.value, classes.maxValue)}>{item['max-range']}</div>
        }
      </div>
    );
  };

  render() {
    const { item, classes } = this.props;

    return (
      <Box className={classes.root}>
        <LegendTitle item={item} />

        <Box className={classes.legendWrapper}>
          {
            item.data.entries.map(this.renderItem)
          }
        </Box>
      </Box>
    );
  }
}

export default decorate(LegendJSONColorbar);
