import { createStyles, Theme } from '@material-ui/core';
import { PlayerZIndex } from '../styles';

export default (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    zIndex: PlayerZIndex.CONTROLS,
    top: 0,
    right: 0,
    transform: 'translateX(0%)',
    transition: 'transform 0.3s linear',
    maxWidth: '35%',
    width: '100%',
    height: 'calc(100% - 52px)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%'
    }
  },
  rootHidden: {
    transform: 'translateX(100%)'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.player.background,
    padding: '0 20px 12px 20px',
    color: 'white',
    overflow: 'auto',
    height: '100%',
    textShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 2px'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  buttonsWrapper: {
    position: 'absolute',
    left: -1 * theme.spacing(6),
    pointerEvents: 'auto',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    transition: 'left 0.3s linear',

    '& > *': {
      borderRadius: 0,
      background: theme.player.background,

      '&:hover': {
        color: theme.palette.secondary.main,
        background: theme.player.background
      }
    },
    '& :first-child': {
      borderRadius: '30% 0 0 0'
    },
    '& :last-child': {
      borderRadius: '0 0 0 30%'
    }
  },
  buttonsWrapperHidden: {
    left: 0
  },
  actionButton: {
    color: 'inherit',
    '&:disabled': {
      color: 'inherit',
      background: theme.player.background
    }
  },
  cancelButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.common.white,
    pointerEvents: 'auto'
  },
  title: {
    minHeight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 500,

    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(4)
    }
  },
  divider: {
    background: theme.palette.common.white,
    opacity: 0.5,
    marginBottom: theme.spacing(2)
  },
  licenceButton: {
    marginTop: 'auto',
    textAlign: 'right'
  }
});
