import React, { Fragment } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface IProps<T = any> {
  id: string;
  options: Array<{
    value: T;
    label: string;
  }>;
  value: T;
  onChange: (value: T) => void;
  className?: string;
}

interface IState {
  anchorEl: HTMLButtonElement | null
}

class DropDown extends React.Component<IProps, IState> {
  state = {
    anchorEl: null
  }

  onSelect = (value: IProps['value']) => () => {
    this.props.onChange(value);
    this.onClose();
  }

  onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  onClose = () => {
    this.setState({
      anchorEl: null
    });
  }

  render() {
    const { id, options, value, className } = this.props;
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <IconButton
          onClick={this.onOpen}
          edge="end"
          area-controls={id}
          className={className}
        >
          <ViewColumnIcon />
          <ArrowDropDownIcon />
        </IconButton>

        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.onClose}
        >
          {
            options.map(option => {
              return (
                <MenuItem
                  key={option.value}
                  onClick={this.onSelect(option.value)}
                  selected={option.value === value}
                >
                  {option.label}
                </MenuItem>
              );
            })
          }
        </Menu>
      </Fragment>
    );
  }
}

export default DropDown;
