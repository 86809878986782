import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    width: '100%',
    borderRadius: 0,
    bottom: 0,
    left: 0,
    zIndex: 5
  },
  alert: {
    borderRadius: 0,

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  alertAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      paddingLeft: 0
    }
  },
  actions: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
});
