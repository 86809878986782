import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    marginTop: 0,
    width: 1,
    height: 4,
    backgroundColor: '#bdbdbd'
  }
});
