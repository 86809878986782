import React, { Component, ReactNode } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  WithStyles
} from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import CloseIcon from '@material-ui/icons/Close';

export interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
}

class ProductFeatureDialog extends Component<IProps> {
  renderLoading() {
    const { classes } = this.props;

    return (
      <Box className={classes.loadingWrapper}>
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  render() {
    const { open, loading, onClose, children, title, classes } = this.props;

    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle className={classes.dialogTitle} disableTypography>
          {
            title &&
            <Typography variant="h6">
              {title}
            </Typography>
          }

          {
            onClose &&
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          }
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {
            loading &&
            this.renderLoading()
          }

          <Box>
            {children}
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default decorate(ProductFeatureDialog);
