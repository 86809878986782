import { CSSProperties } from 'react';

const rootSliderCssStyles: CSSProperties = {
  position: 'relative',
  width: '100%',
  top: 0
};


const styles = {
  root: {
    height: 4
  }
};

export default styles;
export {
  rootSliderCssStyles
};
