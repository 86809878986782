import { TProductDetails } from '../api/product';
import {
  PRODUCT_DETAILS_FETCH_STARTED,
  PRODUCT_DETAILS_FETCH_COMPLETED,
  PRODUCT_DETAILS_FETCH_FAILED,
  IProductDetailsFetchStartAction,
  IProductDetailsFetchCompleteAction,
  IProductDetailsFetchFailAction
} from '../actions/product';
import * as StoreIndex from '../utils/storeIndex';

type TAllowedActions =
  IProductDetailsFetchStartAction |
  IProductDetailsFetchCompleteAction |
  IProductDetailsFetchFailAction

type TProductDetailsSubState = {
  loading: boolean,
  loadedAt: null | number,
  error: null | string,
  data: TProductDetails | null
}

type TProductDetailsState = {
  [key: string]: TProductDetailsSubState | undefined
};

const getInitialSubState = (): TProductDetailsSubState => ({
  loading: false,
  loadedAt: null,
  error: null,
  data: null
});

export default (state: TProductDetailsState = {}, action: TAllowedActions): TProductDetailsState => {
  const key = StoreIndex.productDetails(action.packageName, action.productName);

  switch (action.type) {
    case PRODUCT_DETAILS_FETCH_STARTED: {
      const newState = state[key] || getInitialSubState();

      return {
        ...state,
        [key]: newState
      };
    }

    case PRODUCT_DETAILS_FETCH_COMPLETED:
      return {
        ...state,
        [key]: {
          loading: false,
          loadedAt: Date.now(),
          data: action.data,
          error: null
        }
      };

    case PRODUCT_DETAILS_FETCH_FAILED:
      return {
        ...state,
        [key]: {
          ...(state[key] || getInitialSubState()),
          error: action.error.message,
          loading: false
        }
      };

    default:
      return state;
  }
};
