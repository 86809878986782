import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  root: {
    flexShrink: 0,
    flexGrow: 1
  },
  controlIcon: {
    color: theme.palette.common.black,

    '&[disabled]': {
      color: 'inherit',
      opacity: 0.26
    }
  },
  controlIconDark: {
    color: theme.palette.common.white,

    '&[disabled]': {
      color: 'inherit',
      opacity: 0.26
    }
  },
  controlIconActive: {
    color: theme.palette.secondary.main
  },
  additionalContent: {
    padding: `0 ${theme.spacing(2)}px`
  }
});
