import * as StoreIndex from '../utils/storeIndex';
import { uniqBy } from 'lodash';
import { TCycloneOption } from '../api/cyclone';
import { IRecentCycloneAddAction, RECENT_CYCLONE_ADD_COMPLETED } from '../actions/recentCyclones';

type TState = Record<string, Array<TCycloneOption>>

type TAllowedActions = IRecentCycloneAddAction;

const cycloneUniqKey = (cyclone: TCycloneOption) => {
  return cyclone.value;
};

const addCyclone = (state: TState, key: string, cyclone: TCycloneOption) => {
  const cyclones = [...state[key] ?? []];
  cyclones.unshift(cyclone);

  const uniqCyclones = uniqBy(cyclones, cycloneUniqKey).slice(0, 10);

  return {
    ...state,
    [key]: uniqCyclones
  };
};

export default (state: TState = {}, action: TAllowedActions) => {
  const key = StoreIndex.recentCyclone(action.packageName, action.productName);

  switch (action.type) {
    case RECENT_CYCLONE_ADD_COMPLETED: {
      return addCyclone(state, key, action.cyclone);
    }

    default:
      return state;
  }
};
