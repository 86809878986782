import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  code: {
    background: '#f3f3f3',
    overflow: 'auto',
    padding: theme.spacing(2),
    display: 'block'
  },
  copied: {
    color: 'green'
  },
  copyButton: {
    background: 'rgba(255, 255,255, 0.85)',
    position: 'absolute',
    borderRadius: 0,
    padding: 8,
    right: 0,
    bottom: 0
  }
});
