function fetchEnvSetting(setting: string, required: true): string;
function fetchEnvSetting(setting: string, required?: false): string | undefined;
function fetchEnvSetting(setting: string, required: boolean = false): string | undefined {
  const value = process.env[setting];

  if (required && !value) {
    throw new Error(`Missing config variable: ${setting}`);
  }

  return value;
}

const stackName = fetchEnvSetting('REACT_APP_STACK_NAME', true);
const apiUrl = fetchEnvSetting('REACT_APP_API_BASE_URL', true);
const mountPath = fetchEnvSetting('REACT_APP_MOUNT_PATH', true);
const buildEnv = fetchEnvSetting('REACT_APP_BUILD_ENV', true);
const nodeEnv = fetchEnvSetting('NODE_ENV', true);
const mountNamespacePath = fetchEnvSetting('REACT_APP_MOUNT_NAMESPACE_PATH');
const rootPackageId = fetchEnvSetting('REACT_APP_ROOT_PACKAGE_ID');
const packageScope = fetchEnvSetting('REACT_APP_PACKAGE_SCOPE');
const appHomeLink = fetchEnvSetting('REACT_APP_HOME_LINK');
const embedEnabled = Boolean(fetchEnvSetting('REACT_APP_EMBEDDING_ENABLED'));
const overviewEnabled = Boolean(fetchEnvSetting('REACT_APP_OVERVIEW_ENABLED'));
const dashboardSharingEnabled = Boolean(fetchEnvSetting('REACT_APP_DASHBOARD_SHARING_ENABLED'));
const cookieNamespace = `ecmwf-${stackName}`;

export default Object.freeze({
  stackName,
  cookieNamespace,
  buildEnv,
  nodeEnv,
  apiUrl,
  mountPath,
  mountNamespacePath,
  rootPackageId,
  packageScope,
  appHomeLink,
  embedEnabled,
  overviewEnabled,
  dashboardSharingEnabled
});
