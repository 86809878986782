import React, { Component } from 'react';
import Player, { PlayerDimension, PlayerDimensionOption } from '../../components/Player';
import { RouteChildrenProps } from 'react-router';
import { Dimension, DimensionOption, DimensionSelectionMapping, DimensionChoice } from '../Product';
import { TProductDetails } from '../../api/product';
import decorate from './decorator';
import CircularProgress from '@material-ui/core/CircularProgress';
import updateUrlSearchParams from '../../utils/updateUrlSearchParams';
import { isEqual } from 'lodash';
import { Box } from '@material-ui/core';

export type TRouteParams = {
  packageName: string,
  productName: string
};

export interface IProps extends RouteChildrenProps<TRouteParams> {
  product: TProductDetails | null | undefined,
  packageName: string,
  productName: string,
  dashboardShare: boolean,
  dimensions: Array<Dimension>,
  selectedDimensions: DimensionSelectionMapping,
  playerSelectedDimension: DimensionChoice | void,
  playerSelectedDimensionOption: DimensionOption | void,
  controlsOverlay: boolean,
  displayLegend: boolean,
  fetchProduct: (
    packageName: string,
    productName: string,
    axes?: DimensionSelectionMapping
  ) => Promise<TProductDetails>,
}

class Widget extends Component<IProps> {
  componentDidMount(): void {
    const { fetchProduct, packageName, productName } = this.props;

    fetchProduct(packageName, productName);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { selectedDimensions } = this.props;

    if (!isEqual(prevProps.selectedDimensions, selectedDimensions)) {
      updateUrlSearchParams(selectedDimensions);
    }
  }

  onSelectedDimensionsChange = (values: DimensionSelectionMapping) => {
    updateUrlSearchParams(values);
  }

  onPlayerDimensionChange = (dimension: PlayerDimension) => {
    const params = {
      player_dimension: dimension.name
    };

    updateUrlSearchParams(params);
  };

  onPlayerDimensionOptionChange = (option: PlayerDimensionOption) => {
    const { playerSelectedDimension } = this.props;

    if (!playerSelectedDimension) {
      return;
    }

    const params = {
      [playerSelectedDimension.name]: option.value
    };

    updateUrlSearchParams(params);
  };

  render() {
    const {
      product,
      packageName,
      productName,
      dimensions,
      selectedDimensions,
      playerSelectedDimension,
      playerSelectedDimensionOption,
      controlsOverlay,
      displayLegend
    } = this.props;


    if (!product || !playerSelectedDimension || !playerSelectedDimensionOption) {
      return <CircularProgress />;
    }

    const { notebook, script } = product.options;

    return (
      <Box>
        <Player
          embedMode
          controlsOverlay={controlsOverlay}
          displayLegendOnMount={displayLegend}
          packageName={packageName}
          productName={productName}
          productTitle={product.title}
          productValues={selectedDimensions}
          notebook={notebook}
          script={script}
          licence={product.licence}
          onProductValuesChange={this.onSelectedDimensionsChange}
          dimensions={dimensions}
          selectedDimension={playerSelectedDimension}
          onDimensionChange={this.onPlayerDimensionChange}
          selectedDimensionOption={playerSelectedDimensionOption}
          onDimensionOptionChange={this.onPlayerDimensionOptionChange}
        />
      </Box>
    );
  }
}

export default decorate(Widget);
