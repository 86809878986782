import { Box, Divider, FormControl, InputLabel, Tab, Tabs } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import CityLookup from './CityLookup';
import PlaceIcon from '@material-ui/icons/Place';
import SearchIcon from '@material-ui/icons/Search';
import HistoryIcon from '@material-ui/icons/History';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import CoordinatesControl from './CoordinatesControl';
import { TFlashMessageVariants } from '../../../../../components/FlashMessage';
import decorate from './decorator';
import IconLink from '../../../../../components/IconLink';
import RecentLocations from './RecentLocations';
import { TLocation } from '../../../../../reducers/recentLocationsByProduct';
import classNames from 'classnames';

export interface IProps {
  packageName?: string;
  productName?: string;
  location: TLocation;
  onChange: (latitude: string, longitude: string, stationName: string) => void;
  displayFlashMessage: (type: TFlashMessageVariants, message: string) => void;
  addRecentLocation: (packageName: string, productName: string, location: TLocation) => void;
  hideRecentLocations?: boolean;
  variant?: 'compact' | 'normal';
  classes: {
    root: string;
    heading: string;
    label: string;
    tabs: string;
    tab: string;
    compactCoordinatesControl: string;
  }
}

interface IState {
  view: number
}

const currentLocationErrorMessage = (error: GeolocationPositionError): string => {
  const code = error.code;

  switch (code) {
    case 1:
      return 'Sorry! It seems we have no permissions to acquire current location.';
    case 2:
      return 'Sorry! It seems there was an error why trying to acquire current location.';
    case 3:
      return 'Sorry! It seems we were unable to acquire current location.';
    default:
      return 'Sorry! It seems we were unable to acquire current location.';
  }
};

const getCurrentLocation = (): Promise<GeolocationCoordinates> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((position) => {
      resolve(position.coords);
    }, (error) => {
      reject(new Error(currentLocationErrorMessage(error)));
    });
  });
};

class LocationDimensionControl extends React.Component<IProps, IState> {
  static defaultProps = {
    variant: 'normal' as const
  };

  state: IState = {
    view: 0
  };

  get shouldRenderRecentLocations() {
    return !this.props.hideRecentLocations;
  }

  onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      view: newValue
    });
  }

  onLocationChange = (latitude: string, longitude: string, stationName?: string) => {
    const { onChange, packageName, productName, addRecentLocation } = this.props;

    if (packageName && productName) {
      addRecentLocation(packageName, productName, {
        latitude,
        longitude,
        name: stationName ?? ''
      });
    }

    onChange(latitude, longitude, stationName || '');
  }

  onCurrentLocationClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const { displayFlashMessage } = this.props;

    getCurrentLocation()
      .then((coords) => {
        const { latitude, longitude } = coords;

        this.setState({
          view: 1
        }, () => {
          this.onLocationChange(latitude.toFixed(2), longitude.toFixed(2), '');
        });
      })
      .catch(error => {
        displayFlashMessage('ERROR', error.message);
      });
  }

  render() {
    const { classes, packageName, productName, location, variant } = this.props;
    const { latitude, longitude, name: stationName } = location;

    const { view } = this.state;

    return (
      <FormControl className={classNames(classes.root, variant === 'compact' && classes.compactCoordinatesControl)}>
        <div className={classes.heading}>
          <InputLabel disableAnimation className={classes.label}>Location</InputLabel>
          <Tabs
            value={view}
            onChange={this.onTabChange}
            aria-label="Location selection type tabs"
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab icon={<SearchIcon />} className={classes.tab} aria-label="City search" title="City search" />
            <Tab icon={<PlaceIcon />} className={classes.tab} aria-label="Coordinates" title="Coordinates" />
            {
              this.shouldRenderRecentLocations && Boolean(packageName) && Boolean(productName) &&
              <Tab
                icon={<HistoryIcon />}
                className={classes.tab}
                aria-label="Recent locations"
                title="Recent locations"
              />
            }
          </Tabs>
        </div>

        <Box marginBottom={1}>
          <div hidden={view !== 0}>
            <CityLookup
              onChange={this.onLocationChange}
              initial={stationName ? {
                name: stationName,
                latitude: latitude,
                longitude: longitude
              } : undefined}
            />
          </div>

          <div hidden={view !== 1}>
            <CoordinatesControl
              key={[latitude, longitude].join('::')}
              latitude={latitude}
              longitude={longitude}
              onChange={this.onLocationChange}
            />
          </div>

          {
            this.shouldRenderRecentLocations && packageName && productName &&
            <div hidden={view !== 2}>
              <RecentLocations packageName={packageName} productName={productName} onSelect={this.onLocationChange} />
              <Divider />
            </div>
          }
        </Box>

        <Box display="flex" justifyContent="space-between">
          <IconLink onClick={this.onCurrentLocationClick} href="#" icon={MyLocationIcon}>Use current location</IconLink>
        </Box>
      </FormControl>
    );
  }
}

export default decorate(LocationDimensionControl);
