import React, { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Nullable } from '../../../../../../utils/types';
import { Box } from '@material-ui/core';
import { getObjectFitSize, ObjectFitSize } from '../../../../../../components/ProductDataPointMedia';
import TropicalCycloneMap, { TROPICAL_CYCLONE_MAP_CONFIG } from './TropicalCycloneMap';
import { DimensionCyclone } from '../../../../index';
import TargetArea from './TargetArea';
import Marker from './Marker';

interface Props {
  dimension: DimensionCyclone;
  onSelect: (cycloneId: string) => void;
}

const useImageLoaded = (): [MutableRefObject<Nullable<HTMLImageElement>>, boolean, () => void] => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<Nullable<HTMLImageElement>>(null);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  return [ref, loaded, onLoad];
};


const RecentCycloneSelect = (props: Props) => {
  const { dimension, onSelect } = props;
  const [imageConfig, setImageConfig] = useState<Nullable<ObjectFitSize>>(null);
  const [$image, imageLoaded, onImageLoad] = useImageLoaded();

  const { cyclones, target_areas } = dimension.overview;

  useEffect(() => {
    setImageConfig(getObjectFitSize($image.current!));

    const onResize = () => {
      setImageConfig(getObjectFitSize($image.current!));
    };

    window.addEventListener('resize', onResize, false);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [imageLoaded]);

  return (
    <Box style={{ position: 'relative', height: '100%' }}>
      {
        imageLoaded && $image.current && imageConfig &&
        <Fragment>
          {
            target_areas.map(targetArea => (
              <TargetArea
                key={targetArea.join('::')}
                geoBoundary={{
                  left: targetArea[1],
                  top: targetArea[0],
                  right: targetArea[3],
                  bottom: targetArea[2]
                }}
                mapConfig={TROPICAL_CYCLONE_MAP_CONFIG}
                imageConfig={imageConfig}
              />
            ))
          }

          {
            cyclones.map(cyclone =>
              <Marker
                key={cyclone.unique_id}
                cyclone={cyclone}
                mapConfig={TROPICAL_CYCLONE_MAP_CONFIG}
                imageConfig={imageConfig}
                onSelect={onSelect}
              />
            )
          }
        </Fragment>
      }

      <TropicalCycloneMap ref={$image} onLoad={onImageLoad} />
    </Box>
  );
};

export default RecentCycloneSelect;
