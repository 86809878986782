import { Box, IconButton, Input, InputAdornment, InputProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import decorate from './decorator';

export interface IProps extends InputProps {
  onReset?: () => void;
  classes: {
    root: string;
  }
}

class SearchInput extends React.Component<IProps> {
  onReset = () => {
    const { onReset } = this.props;

    onReset && onReset();
  };

  render() {
    const { classes, value } = this.props;

    return (
      <Box className={classes.root}>
        <Input
          {...this.props}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          }
          endAdornment={
            value ?
              <InputAdornment position="end">
                <IconButton size="small" onClick={this.onReset}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
              : null
          }
        />
      </Box>
    );
  }
}

export default decorate(SearchInput);
