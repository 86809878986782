import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    flexWrap: 'nowrap'
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center',

    '& * a': {
      textDecoration: 'underline'
    }
  }
});
