import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import { TLegendJSONDoubleLine } from '../../../../../api/axis';
import decorate from './decorator';
import LegendTitle from '../../LegendTitle';

export interface IProps {
  item: TLegendJSONDoubleLine;
  classes: {
    root: string;
  }
}

const LINE_STYLES = {
  'solid': 'solid',
  'dash': 'dashed',
  'dot': 'dotted'
};

const deriveBorderFromEntry = (entry: TLegendJSONDoubleLine['data']['entries'][0]) => {
  return {
    borderTop: `${entry.line1_thickness}px ${LINE_STYLES[entry.line1_style]} ${entry.line1_colour}`,
    borderBottom: `${entry.line2_thickness}px ${LINE_STYLES[entry.line2_style]} ${entry.line2_colour}`
  };
};

class LegendJSONDoubleLine extends Component<IProps> {
  render() {
    const { item, classes } = this.props;
    const entry = item.data.entries[0];

    if (!entry) {
      return null;
    }

    return (
      <Box className={classes.root}>

        <Box display="flex" alignItems="center" style={{ padding: '1em 0' }}>
          <div style={{
            width: '5em',
            height: '1em',
            ...deriveBorderFromEntry(entry)
          }} />

          <div style={{ marginLeft: '1em' }}>
            <LegendTitle item={item} />
          </div>
        </Box>
      </Box>
    );
  }
}

export default decorate(LegendJSONDoubleLine);
