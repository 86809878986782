import { Action } from 'redux';
import { TLocation } from '../reducers/recentLocationsByProduct';

export const RECENT_LOCATION_ADD_COMPLETED: 'RECENT_LOCATION_ADD_COMPLETED' = 'RECENT_LOCATION_ADD_COMPLETED';

export interface IRecentLocationAddAction extends Action<typeof RECENT_LOCATION_ADD_COMPLETED> {
  packageName: string;
  productName: string;
  location: TLocation;
}

const addRecentLocation = (packageName: string, productName: string, location: TLocation): IRecentLocationAddAction => {
  return {
    type: RECENT_LOCATION_ADD_COMPLETED,
    packageName,
    productName,
    location
  };
};

export {
  addRecentLocation
};
