import client from './client';

export type TCity = {
  altitude: number;
  country: string;
  state: string;
  latitude: string;
  longitude: string;
  name: string;
}

interface ICityListResponse extends Array<TCity> {
}

type TCityListParams = {
  name: string;
  limit: number
}

const list = (params: TCityListParams): Promise<ICityListResponse> => {
  return client.post('/cities', params)
    .then(response => response.data);
};

export {
  list
};
