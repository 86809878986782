import { formatCoordinates } from '../CoordinatesFormatter';
import { find, split } from 'lodash';
import { Dimension, DimensionSelectionMapping, DimensionType } from '../../containers/Product';

const withValues = (dimensions: Array<Dimension>, selectedDimensions: DimensionSelectionMapping) => {
  return dimensions.map(dimension => {
    const { label, name } = dimension;
    let value;

    if (dimension.type === DimensionType.LOCATION) {
      const { lat, lon } = selectedDimensions;

      value = formatCoordinates(lat as string, lon as string);
    } else if (dimension.type === DimensionType.CHOICE) {
      value = find(
        dimension.values,
        v => v.value.toString() === selectedDimensions[name].toString()
      )?.label;
    } else if (dimension.type === DimensionType.CYCLONE) {
      value = find(
        dimension.values,
        v => v.value.toString() === selectedDimensions[name].toString()
      )?.label;
    } else if (dimension.type === DimensionType.MULTIPLE_CHOICE) {
      const values = (selectedDimensions[name] as string).split(',');
      value = dimension.values.filter(
        v => values.includes(v.value.toString())
      ).map(i => i.label).join(', ');
    }

    return {
      name,
      label,
      value
    };
  });
};

export {
  withValues
};
