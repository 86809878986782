import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const SkipBackwardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20 5v14l-7-7M6 5v14H4V5m9 0v14l-7-7" />
    </SvgIcon>
  );
};

export default SkipBackwardIcon;
