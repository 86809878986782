import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    top: '0.5em',
    left: '0.5em',
    zIndex: 10,
    background: theme.player.background,
    color: theme.palette.common.white,
    padding: '0.3em 0.8em',
    borderRadius: '0.8em',
    display: 'flex',
    alignItems: 'center',
    transition: 'opacity 1s ease-out',
    opacity: 0
  },
  visible: {
    opacity: 1
  },
  icon: {
    marginRight: '0.5em'
  }
});
