import { TAppState } from '../../store';
import { fetchProductsByPackage } from '../../actions/product';
import { connect } from 'react-redux';
import { ComponentType } from 'react';
import { IProps } from './index';
import config from '../../config';

const mapStateToProps = (state: TAppState, props: IProps) => {
  const match = props.match;
  const packageName = config.rootPackageId ? config.rootPackageId : match!.params.name;

  const productsByPackageState = packageName && state.productsByPackage;
  const productsState = packageName && productsByPackageState && productsByPackageState[packageName];
  const packageItem = (productsState && productsState.data) || null;
  const selectedProducts = state.productCollectionsByPackage[packageName] || [];

  return {
    selectedProducts,
    packageName,
    packageItem
  };
};

const mapDispatchToProps = {
  fetchProductsByPackage
};

export default (component: ComponentType<IProps>) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
