import { Box, Typography, IconButton, Divider } from '@material-ui/core';
import React, { PureComponent, ReactNode } from 'react';
import classNames from 'classnames';
import ProductDataItemDownloadButton from '../../ProductDataItemDownloadButton';
import { PlayerDimension } from '../index';
import decorate from './decorator';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import ShareButton from '../ShareButton';
import { IPointDataDescription } from '../index';
import Licence from '../../Licence';
import config from '../../../config';
import { TProductLicence } from '../../../api/product';
import { Nullable } from '../../../utils/types';

export interface IProps {
  title: string;
  visible: boolean;
  embedMode?: boolean;
  dashboardShare?: boolean;
  children: ReactNode;
  selectedDimension: PlayerDimension;
  pointData: IPointDataDescription;
  notebook: boolean;
  script: boolean;
  licence: Nullable<TProductLicence>;
  classes: {
    root: string;
    rootHidden: string;
    box: string;
    closeButton: string;
    buttonsWrapper: string;
    buttonsWrapperHidden: string;
    actionButton: string;
    cancelButton: string;
    title: string;
    divider: string;
    licenceButton: string;
  };
}

interface IState {
  open: boolean;
}

class PlayerInfo extends PureComponent<IProps, IState> {
  state = {
    open: false
  };

  hide = () => {
    this.setState({
      open: false
    });
  };

  open = () => {
    this.setState({
      open: true
    });
  };

  render() {
    const {
      title,
      children,
      embedMode,
      dashboardShare,
      classes,
      pointData,
      selectedDimension,
      visible,
      notebook,
      script,
      licence
    } = this.props;
    const { open } = this.state;
    const { embedEnabled, dashboardSharingEnabled } = config;

    return (
      <Box className={classNames(classes.root, (!open || !visible) && classes.rootHidden)}>
        <Box className={classNames(classes.buttonsWrapper, !visible && classes.buttonsWrapperHidden)}>
          {
            open ?
              <IconButton onClick={this.hide} className={classes.actionButton}>
                <ChevronRightIcon />
              </IconButton>
              :
              <IconButton onClick={this.open} className={classes.actionButton}>
                <InfoIcon />
              </IconButton>
          }

          <ShareButton
            disableEmbed={!embedEnabled || embedMode}
            disableDashboard={!dashboardSharingEnabled || !dashboardShare}
            className={classes.actionButton}
            selectedDimension={selectedDimension}
            pointData={pointData}
          />

          <ProductDataItemDownloadButton
            className={classes.actionButton}
            pointData={pointData}
            notebook={notebook}
            script={script}
          />
        </Box>

        <Box className={classes.box}>
          <IconButton onClick={this.hide} className={classes.closeButton} color="inherit">
            <CloseIcon />
          </IconButton>

          <Typography color="inherit" variant="h5" className={classes.title}>
            {title}
          </Typography>

          <Divider className={classes.divider} />

          {children}

          {
            licence &&
            <Box className={classes.licenceButton}>
              <Licence type={licence.type} url={licence.url} />
            </Box>
          }
        </Box>
      </Box>
    );
  }
}

export default decorate(PlayerInfo);
