import { Action } from 'redux';
import { TSettingId, TSettingValue } from '../reducers/settings';

export const SETTING_SET_COMPLETED: 'SETTING_SET_COMPLETED' = 'SETTING_SET_COMPLETED';

export interface ISettingSetCompleteAction<T = string> extends Action<typeof SETTING_SET_COMPLETED> {
  setting: TSettingId;
  value: TSettingValue<T>;
}

const setSettingCompleted =
  <T = string>(setting: TSettingId, value: TSettingValue<T>): ISettingSetCompleteAction<T> => {
    return {
      type: SETTING_SET_COMPLETED,
      setting,
      value
    };
  };

const setSetting = <T = string>(settingId: TSettingId, value: TSettingValue<T>) => {
  return setSettingCompleted(settingId, value);
};

export {
  setSetting
};
