import { Provider } from 'react-redux';
import React from 'react';
import App from './containers/App';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { persistor, store } from './store';
import history from './history';
import { PersistGate } from 'redux-persist/integration/react';

import './iframe-resizer.content-window.js';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

interface ApplicationProps {
  theme: Theme;
  disableDefaultRouter?: boolean;
}

const Application = (props: ApplicationProps) => {
  const { theme, disableDefaultRouter } = props;

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          {
            disableDefaultRouter ?
              <App />
              :
              <Router history={history}>
                <App />
              </Router>
          }
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
};


export {
  history
};
export default Application;
