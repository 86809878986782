import { ThunkDispatch } from 'redux-thunk';
import Api from '../api';
import { Action } from 'redux';
import { TAppState } from '../store';
import { TPackage } from '../api/package';
import ErrorHelpers from '../utils/ErrorHelpers';
import config from '../config';

export const PACKAGE_LIST_FETCH_STARTED: 'PACKAGE_LIST_FETCH_STARTED' = 'PACKAGE_LIST_FETCH_STARTED';
export const PACKAGE_LIST_FETCH_COMPLETED: 'PACKAGE_LIST_FETCH_COMPLETED' = 'PACKAGE_LIST_FETCH_COMPLETED';
export const PACKAGE_LIST_FETCH_FAILED: 'PACKAGE_LIST_FETCH_FAILED' = 'PACKAGE_LIST_FETCH_FAILED';

export interface IPackageListFetchStartAction<T = typeof PACKAGE_LIST_FETCH_STARTED> extends Action<T> {
  type: T
}

export interface IPackageListFetchCompleteAction<T = typeof PACKAGE_LIST_FETCH_COMPLETED> extends Action<T> {
  type: T,
  data: Array<TPackage>
}

export interface IPackageListFetchFailAction<T = typeof PACKAGE_LIST_FETCH_FAILED> extends Action<T> {
  type: T,
  error: Error
}

const packageListFetchStart: IPackageListFetchStartAction = {
  type: PACKAGE_LIST_FETCH_STARTED
};

const packageListFetchComplete = (data: Array<TPackage>): IPackageListFetchCompleteAction => ({
  type: PACKAGE_LIST_FETCH_COMPLETED,
  data
});

const packageListFetchFail = (error: Error): IPackageListFetchFailAction => ({
  type: PACKAGE_LIST_FETCH_FAILED,
  error
});


const fetchPackageList = () => {
  return async (dispatch: ThunkDispatch<TAppState, void, Action>) => {
    try {
      dispatch(packageListFetchStart);

      const data = await Api.Package.list({
        ...(config.packageScope && { scope: config.packageScope })
      });

      dispatch(packageListFetchComplete(data.packages));

      return data;
    } catch (error) {
      if (ErrorHelpers.isError(error)) {
        return dispatch(packageListFetchFail(error));
      }
    }
  };
};

export {
  fetchPackageList
};
