import React, { Component } from 'react';
import { IProductListResponse, TProduct } from '../../api/product';
import { RouteChildrenProps } from 'react-router';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageWrapper from '../../components/PageWrapper';
import PageError from '../../components/PageError';
import decorate from './decorator';
import PageLoading from '../../components/PageLoading';
import FacetedView from './FacetedView';
import PackageTreeView from './TreeView';
import { uniq } from 'lodash';
import config from '../../config';
import paths from '../../router/paths';

export interface IProps extends RouteChildrenProps<{ name: string }> {
  packageName: string | null,
  packageItem: IProductListResponse | null,
  selectedProducts: Array<string>,
  fetchProductsByPackage: (packageName: string) => Promise<IProductListResponse>
}

type TState = {
  error: string | null;
}

const deriveProductGroupsFromSelectedProducts = (
  products: Array<TProduct>,
  selectedProducts: Array<string>
): Array<string> => {
  const collectedGroups = products.reduce(
    (acc, product) => {
      if (selectedProducts.includes(product.name)) {
        acc = acc.concat(product.groups);
      }

      return acc;
    },
    [] as Array<string>
  );

  return uniq(collectedGroups);
};

class Package extends Component<IProps, TState> {
  state: TState = {
    error: null
  };

  componentDidMount() {
    const { packageName, fetchProductsByPackage } = this.props;

    if (packageName) {
      fetchProductsByPackage(packageName).catch(() => {
        this.setState({
          error: 'We\'re sorry. Package has not been found.'
        });
      });
    }
  }

  renderBreadcrumbs() {
    const { packageItem } = this.props;

    return (
      <Breadcrumbs
        paths={{
          ...(!config.rootPackageId && config.appHomeLink && { Packages: paths.root }),
          [packageItem!.title]: null
        }}
      />
    );
  }

  render() {
    const { packageItem, selectedProducts } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <PageError title={error} />
      );
    }

    if (!packageItem) {
      return <PageLoading />;
    }

    const ViewComponent = packageItem.subpackages ? PackageTreeView : FacetedView;
    const selectedProductGroups = deriveProductGroupsFromSelectedProducts(packageItem.products, selectedProducts);

    return (
      <PageWrapper>
        <Grid container>
          <Grid item>
            {this.renderBreadcrumbs()}
          </Grid>
        </Grid>

        {
          <ViewComponent
            packageItem={packageItem}
            selectedProductGroups={selectedProductGroups}
            selectedProducts={selectedProducts}
          />
        }
      </PageWrapper>
    );
  }
}

export default decorate(Package);
