import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { Announcement } from "../../api/announcement";

const VARIANTS = {
  error: {
    className: 'error',
    icon: ErrorIcon
  },
  warning: {
    className: 'warning',
    icon: WarningIcon
  },
  info: {
    className: 'info',
    icon: InfoIcon
  },
  success: {
    className: 'success',
    icon: CheckCircleIcon
  }
} as const;

const deriveVariant = (announcement: Announcement) => {
  return VARIANTS[announcement.severity];
};

export {
  deriveVariant
};
