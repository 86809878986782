import { FormControl } from '@material-ui/core';
import React, { Component } from 'react';
import { find } from 'lodash';
import Select from '../Select';
import { IDimensionControls } from '../index';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { PlayerDimension } from '../../index';

export interface IProps extends IDimensionControls {
  label?: string;
  native?: boolean
}

class DimensionSelect extends Component<IProps> {
  private onDimensionChange = (event: React.ChangeEvent<unknown>) => {
    const inputValue = (event.target as HTMLSelectElement).value;
    const { dimensions, onDimensionChange } = this.props;

    const dimension = find(dimensions, { name: inputValue });

    dimension && onDimensionChange(dimension);
  };

  private renderOptions(dimensions: Array<PlayerDimension>) {
    const { native } = this.props;

    const Component = native ? 'option' : MenuItem;

    return dimensions.map(option =>
      <Component
        key={option.name}
        value={option.name}
      >
        {option.label}
      </Component>
    );
  }

  render() {
    const { selectedDimension, dimensions, native, label } = this.props;

    return (
      <FormControl style={{ width: '100%' }}>
        {
          label &&
          <InputLabel htmlFor="animated-dimensions-label">{label}</InputLabel>
        }

        <Select
          label={label}
          id="animated-dimensions"
          labelId="animated-dimensions-label"
          native={native}
          value={selectedDimension.name}
          onChange={this.onDimensionChange}
        >
          {
            this.renderOptions(dimensions)
          }
        </Select>
      </FormControl>
    );
  }
}

export default DimensionSelect;
