import { createStyles } from '@material-ui/core';
import { PlayerZIndex } from '../styles';

export default () => createStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    opacity: 0,
    zIndex: PlayerZIndex.VISIBLE_CONTENT,
    minWidth: '40%',
    pointerEvents: 'none'
  },
  controlsOverlay: {
    bottom: 50
  },
  visible: {
    opacity: 1,
    pointerEvents: 'auto'
  }
});
