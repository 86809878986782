import React, { Component } from 'react';
import { FormControl } from '@material-ui/core';
import { find } from 'lodash';
import Select from '../Select';
import { IDimensionOptionControls } from '../index';
import { PlayerDimensionOption } from '../../index';
import MenuItem from '@material-ui/core/MenuItem';

export interface IProps extends IDimensionOptionControls {
  native?: boolean
}

class DimensionOptionSelect extends Component<IProps> {
  onOptionChange = (event: React.ChangeEvent<unknown>) => {
    const inputValue = (event.target as HTMLSelectElement).value;
    const { dimensionOptions, onDimensionOptionChange } = this.props;

    const option = find(dimensionOptions, { value: inputValue });

    option && onDimensionOptionChange(option);
  };

  private renderOptions(dimensionOptions: Array<PlayerDimensionOption>) {
    const { native } = this.props;

    const Component = native ? 'option' : MenuItem;

    return dimensionOptions.map(option =>
      <Component
        key={option.value}
        value={option.value}
      >
        {option.label}
      </Component>
    );
  }

  render() {
    const {
      dimensionOptions,
      selectedDimensionOption,
      native
    } = this.props;

    return (
      <FormControl>
        <Select
          native={native}
          value={selectedDimensionOption!.value}
          onChange={this.onOptionChange}
        >
          {
            this.renderOptions(dimensionOptions)
          }
        </Select>
      </FormControl>
    );
  }
}

export default DimensionOptionSelect;
