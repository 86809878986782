import React, { PureComponent } from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import decorate from './decorator';
import classNames from 'classnames';

export interface IProps {
  text: string;
  classes: {
    root: string;
    visible: string;
    icon: string
  }
}

interface IState {
  show: boolean;
}

const DURATION = 10_000;

class HelpInfo extends PureComponent<IProps, IState> {
  timeout: ReturnType<typeof setTimeout> | undefined;
  state = {
    show: true
  };

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        show: false
      });
    }, DURATION);
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  render() {
    const { classes, text } = this.props;
    const { show } = this.state;

    return (
      <div className={classNames(classes.root, show && classes.visible)}>
        <InfoIcon fontSize="small" className={classes.icon} />
        { text }
      </div>
    );
  }
}

export default decorate(HelpInfo);
