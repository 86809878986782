import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  dialogTitle: {
    boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.2)',
    zIndex: 1
  },
  dialogContent: {
    position: 'relative',
    paddingTop: theme.spacing(1)
  },
  loadingWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.8)'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
