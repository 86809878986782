import React, { ReactNode, HTMLProps } from 'react';
import classNames from 'classnames';
import decorate from './decorator';

export interface IProps extends HTMLProps<HTMLAnchorElement> {
  children: ReactNode;
  classes: {
    root: string;
  }
}

const ExternalLink = (props: IProps) => {
  const { children, classes, className, ...rest } = props;

  return (
    <a target="_blank" rel="noreferrer" {...rest} className={classNames(classes.root, className)}>{props.children}</a>
  );
};

export default decorate(ExternalLink);
