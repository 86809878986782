import { createStyles } from '@material-ui/core';

import { TRACK_HEIGHT, THUMB_HEIGHT } from '../constants';

export default () => createStyles({
  rail: {
    position: 'absolute',
    width: '100%',
    height: THUMB_HEIGHT * 2,
    top: -1 * THUMB_HEIGHT,
    cursor: 'pointer',
    opacity: 0.3,
    zIndex: 300
  },
  baseRail: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    width: '100%',
    height: TRACK_HEIGHT,
    position: 'absolute',
    pointerEvents: 'none'
  }
});
