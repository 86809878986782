import {
  GROUP_FETCH_COMPLETED,
  GROUP_FETCH_FAILED,
  GROUP_FETCH_STARTED,
  IGroupFetchCompleteAction,
  IGroupFetchFailAction,
  IGroupFetchStartAction
} from '../actions/group';
import { TGroup } from '../api/group';
import * as StoreIndex from '../utils/storeIndex';

type TGroupState = Record<string, TGroupSubState>;

export type TGroupSubState = {
  loading: boolean,
  loadedAt: null | number,
  error: null | string,
  data: TGroup | null
}

type TAllowedActions = IGroupFetchStartAction | IGroupFetchCompleteAction | IGroupFetchFailAction

const getInitialSubState = (): TGroupSubState => ({
  loading: false,
  loadedAt: null,
  error: null,
  data: null
});

export default (state: TGroupState = {}, action: TAllowedActions): TGroupState => {
  const key = StoreIndex.group(action.packageName, action.groupName);

  switch (action.type) {
    case GROUP_FETCH_STARTED: {
      const newState = state[key] || getInitialSubState();

      return {
        ...state,
        [key]: { ...newState, loading: true }
      };
    }

    case GROUP_FETCH_COMPLETED: {
      return {
        ...state,
        [key]: {
          loading: false,
          loadedAt: Date.now(),
          data: action.group,
          error: null
        }
      };
    }

    case GROUP_FETCH_FAILED: {
      return {
        ...state,
        [key]: {
          ...(state[key] || getInitialSubState()),
          error: action.error.message,
          loading: false
        }
      };
    }

    default:
      return state;
  }
};
