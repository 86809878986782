import React, { Component, Fragment } from 'react';
import { WithStyles, ThemeProvider, IconButton, Box } from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import DimensionSelectorForm, { IDimensionSelectorForm } from '../../../containers/Product/DimensionSelector/DimensionSelectorForm';
import theme from '../../../theme';
import CloseIcon from '@material-ui/icons/Close';
import { PlayerDimension } from '../index';
import DimensionSelect from '../WidgetControls/DimensionSelect';
import classNames from 'classnames';

export interface IProps extends IDimensionSelectorForm, WithStyles<typeof styles> {
  open: boolean;
  onClose?: () => void;
  animatableDimensions: Array<PlayerDimension>
  selectedAnimatedDimension: PlayerDimension;
  onAnimatedDimensionChange: (option: PlayerDimension) => void;
  controlsOverlay?: boolean;
}

class PlayerDimensionsControl extends Component<IProps> {
  render() {
    const {
      classes,
      open,
      controlsOverlay,
      packageName,
      productName,
      dimensions,
      selectedDimensions,
      onChange,
      nativeSelect,
      onClose,
      animatableDimensions,
      selectedAnimatedDimension,
      onAnimatedDimensionChange
    } = this.props;

    const rootClassName = classNames(
      classes.root,
      controlsOverlay && classes.rootOverlayMode,
      !open && classes.rootHidden
    );

    return (
      <Box className={rootClassName}>
        <ThemeProvider theme={theme(false, 'dark')}>
          <Box className={classNames(classes.content, controlsOverlay && classes.contentOverlayMode)}>
            {
              onClose &&
              <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }

            <Box>
              <DimensionSelectorForm
                packageName={packageName}
                productName={productName}
                dimensions={dimensions}
                selectedDimensions={selectedDimensions}
                onChange={onChange}
                nativeSelect={nativeSelect}
                classes={{
                  root: classes.dimensionsForm
                }}
              />
            </Box>

            {
              animatableDimensions.length > 1 &&
              <Fragment>
                <Box marginTop={3}>
                  <DimensionSelect
                    label="Select animated dimension"
                    native={nativeSelect}
                    dimensions={animatableDimensions}
                    selectedDimension={selectedAnimatedDimension}
                    onDimensionChange={onAnimatedDimensionChange}
                  />
                </Box>
              </Fragment>
            }
          </Box>
        </ThemeProvider>
      </Box>
    );
  }
}

export default decorate(PlayerDimensionsControl);
