import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
  key: 'webplots:root',
  storage: sessionStorage,
  blacklist: ['recentLocationsByProduct']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk
    )
  )
);

const persistor = persistStore(store);

export type TAppState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;

export {
  store,
  persistor
};
