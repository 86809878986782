import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connectThunkAction } from '../../utils/ReduxHelpers';
import { fetchProduct } from '../../actions/product';
import { IProps } from './index';
import { TAppState } from '../../store';
import * as StoreIndex from '../../utils/storeIndex';
import { TProductDetails } from '../../api/product';
import * as QueryString from '../../utils/queryString';
import { castArray, isEmpty, compact } from 'lodash';
import { RouteChildrenProps } from 'react-router';
import deriveDimensionsFromProducts from '../../utils/deriveDimensionsFromProducts';
import { fetchGroup } from '../../actions/group';
import config from '../../config';
import { selectGroup } from '../../selectors/groupSelector';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { selectGridSizeSetting } from '../../selectors/settingsSelector';
import { Dimension, DimensionSelectionMapping } from '../Product';

type DimensionSetSplit = {
  common: Array<Dimension>
  specific: Array<Dimension>
  selected: DimensionSelectionMapping
}

const deriveParamsFromLocation = (location: RouteChildrenProps['location']) => {
  const searchParams = QueryString.parse(location.search);
  const { productNames, productGroup, ...dimensions } = searchParams;

  return {
    productNames: compact(castArray(searchParams.productNames)),
    dimensions
  };
};


const mapStateToProps = (state: TAppState, props: IProps) => {
  const match = props.match;
  const packageName = config.rootPackageId ? config.rootPackageId : match!.params.packageName;
  const groupName = match!.params.groupName;
  const { productNames, dimensions } = deriveParamsFromLocation(props.location);

  let group = null;
  let productsToFetch: Array<{ name: string, package: string}> = [];
  let products: Array<TProductDetails> = [];
  let dimensionSet: DimensionSetSplit = {
    common: [],
    specific: [],
    selected: {}
  };

  const groupState = selectGroup(state, packageName, groupName);
  const groupError = groupState?.error ?? null;

  if (groupState && groupState.data) {
    group = groupState.data;

    productsToFetch = group.products.filter(
      product => isEmpty(productNames) || productNames.includes(product.name)
    );

    products = productsToFetch
      .reduce((acc, product) => {
        const productState = state.productDetails[StoreIndex.productDetails(product.package, product.name)];

        if (productState && productState.data) {
          acc.push(productState.data);
        }

        return acc;
      }, [] as Array<TProductDetails>);

    dimensionSet = deriveDimensionsFromProducts(products, dimensions as Record<string, string>);
  }

  const gridSize = selectGridSizeSetting(state, 'PRESET_GRID_SIZE');

  return {
    packageName,
    groupName,
    group,
    groupError,
    productsToFetch,
    products,
    commonDimensions: dimensionSet.common,
    specificDimensions: dimensionSet.specific,
    selectedDimensions: dimensionSet.selected,
    gridSize
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchProduct: connectThunkAction(dispatch, fetchProduct),
  fetchGroup: connectThunkAction(dispatch, fetchGroup)
});

export default (component: ComponentType<IProps>) =>
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(component)
  );

