import { withStyles } from '@material-ui/core';
import { ComponentType } from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import styles from './styles';
import { IProps } from './index';
import { TAppState } from '../../store';
import { fetchProductData } from '../../actions/productData';
import { DimensionSelectionMapping } from '../../containers/Product';
import * as StoreIndex from '../../utils/storeIndex';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connectThunkAction } from '../../utils/ReduxHelpers';

const derivePlayerDataFromProductData = (
  productData: TAppState['productData'],
  packageName: string,
  productName: string,
  productValues: DimensionSelectionMapping
) => {
  const key = StoreIndex.productData(packageName, productName, productValues);

  return productData[key];
};

const mapStateToProps = (state: TAppState, props: IProps) => {
  const { productValues, packageName, productName } = props;

  const data = derivePlayerDataFromProductData(
    state.productData,
    packageName,
    productName,
    productValues
  );

  return {
    ...props,
    productDataItem: data
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchProductData: connectThunkAction(dispatch, fetchProductData)
});

export default (component: ComponentType<IProps>) =>
  flow(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(component);
