import { makeStyles, Paper, Theme } from '@material-ui/core';
import React, { useRef } from 'react';
import { TProductDetails } from '../../../api/product';
import SinglePointViewer from '../../../components/SinglePointViewer';
import { DimensionSelectionMapping } from '../index';
import { Dimension, usePlayerWrapperHeight } from '../index';

interface Props {
  product: TProductDetails;
  selectedDimensions: DimensionSelectionMapping;
  dimensions: Array<Dimension>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  playerWrapper: {
    height: 500,
    padding: 0,

    ':fullscreen &': {
      height: '100vh'
    }
  },
  singlePointViewerWrapper: {
    padding: theme.spacing(1)
  },
  description: {
    marginTop: theme.spacing(4)
  }
}));

const SinglePointViewerWrapper = (props: Props) => {
  const { product, selectedDimensions, dimensions } = props;
  const classes = useStyles();
  const $playerWrapper = useRef<HTMLElement>(null);
  const playerWrapperHeight = usePlayerWrapperHeight(product, selectedDimensions, $playerWrapper);
  const { notebook } = product.options;

  return (
    <Paper
      className={classes.playerWrapper}
      ref={$playerWrapper}
      style={{ height: playerWrapperHeight }}
    >
      <SinglePointViewer
        packageName={product.package.name}
        productName={product.name}
        productValues={selectedDimensions}
        dimensions={dimensions}
        notebook={notebook}
      />
    </Paper>
  );
};

export default SinglePointViewerWrapper;
