const content = {
  content: "We're almost halfway through the Open Data Survey, and we'd like to hear more! So far, we've learned that" +
    " the Total Cloud Cover is the most requested missing parameter! ⛅\n\nWhat else would you like to tell us? How can " +
    "we do better?\n\nFor all [European Centre for Medium-Range Weather Forecasts](https://www.linkedin.com/company/ecmwf/) " +
    "and [Copernicus ECMWF](https://www.linkedin.com/company/copernicus-ecmwf/) open data users, please complete our survey!" +
    " [https://ec.europa.eu/eusurvey/runner/ECMWF-open-data-2024](https://ec.europa.eu/eusurvey/runner/ECMWF-open-data-2024)\n"
}

export default content;


