import React, { Fragment } from 'react';
import decorate from './decorator';
import { SliderItem } from 'react-compound-slider';

export interface IProps {
  source: SliderItem;
  target: SliderItem;
  disabled?: boolean;
  getTrackProps: () => object;
  classes: {
    track: string;
    trackHotspot: string;
  };
}

function Track(props: IProps) {
  const { classes, source, target, getTrackProps } = props;

  const left = `${source.percent}%`;
  const width = `${target.percent - source.percent}%`;

  return (
    <Fragment>
      <div className={classes.track} style={{ left, width }} />
      <div
        className={classes.trackHotspot}
        style={{ left, width }}
        {...getTrackProps()}
      />
    </Fragment>
  );
}

export default decorate(Track);
