import { ComponentType } from 'react';
import { IProps } from './index';
import { displayFlashMessage } from '../../../actions/flashMessage';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const mapDispatchToProps = {
  displayFlashMessage
};

export default (component: ComponentType<IProps>) =>
  connect(null, mapDispatchToProps)(
    withStyles(styles)(component)
  );
