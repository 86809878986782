import { createStyles, Theme } from '@material-ui/core';
import { THUMB_HEIGHT, TOOLTIP_WIDTH } from '../constants';

export default (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    bottom: THUMB_HEIGHT,
    background: theme.player.background,
    padding: '0.3em 0.5em',
    borderRadius: '0.5em',
    textAlign: 'center',
    width: TOOLTIP_WIDTH
  }
});
