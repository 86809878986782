import * as Announcement from './announcement';
import * as Axis from './axis';
import * as City from './city';
import * as Cyclone from './cyclone';
import * as Feature from './feature';
import * as Group from './group';
import * as Package from './package';
import * as Product from './product';

const Api = {
  Announcement,
  Axis,
  City,
  Cyclone,
  Feature,
  Group,
  Package,
  Product
};

export default Api;
