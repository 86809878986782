import Player, { PlayerDimension, PlayerDimensionOption } from '../../../components/Player';
import PageError from '../../../components/PageError';
import React, { Fragment, useRef } from 'react';
import Link from '../../../components/Link';
import paths from '../../../router/paths';
import { makeStyles, Paper, Theme } from '@material-ui/core';
import { TProductDetails } from '../../../api/product';
import { Dimension, DimensionChoice, DimensionLocation, DimensionSelectionMapping, DimensionType } from '../.././Product';
import { find } from 'lodash';
import { usePlayerWrapperHeight } from '../index';

interface Props {
  product: TProductDetails;
  dimensions: Array<Dimension>;
  selectedDimensions: DimensionSelectionMapping;
  playerSelectedDimension?: PlayerDimension;
  playerSelectedDimensionOption?: PlayerDimensionOption;
  onSelectedDimensionsChange?: (values: DimensionSelectionMapping) => void;
  onPlayerDimensionChange: (dimension: PlayerDimension) => void;
  onPlayerDimensionOptionChange?: (option: PlayerDimensionOption) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  playerWrapper: {
    height: 500,
    padding: 0,
    transition: 'height 0.3s',

    ':fullscreen &': {
      height: '100vh'
    }
  }
}));

const isBetween = (value: number, from: number, to: number): boolean => value >= from && value <= to;

const validateChoiceValue = (dimension: DimensionChoice, selectedDimensions: DimensionSelectionMapping) => {
  const isNotFound = !find(
    dimension.values,
    item => item.value.toString() === selectedDimensions[dimension.name].toString()
  );

  if (isNotFound) {
    throw new Error(`${dimension.name}:${selectedDimensions[dimension.name]} not found`);
  }
};

const validateLocationValue = (dimension: DimensionLocation, selectedDimensions: DimensionSelectionMapping) => {
  const { latitude, longitude } = selectedDimensions;

  if (
    (latitude && isBetween(parseFloat(latitude.toString()), 0, 90)) ||
    (longitude && isBetween(parseFloat(longitude.toString()), 0, 180))
  ) {
    throw new Error(`${dimension.name} is out of boundaries.`);
  }
};

const validateProductValues = (dimensions?: Array<Dimension>, selectedDimensions?: DimensionSelectionMapping) => {
  if (!dimensions || !selectedDimensions) {
    return true;
  }

  try {
    dimensions.forEach(dimension => {
      switch (dimension.type) {
        case DimensionType.CHOICE:
          validateChoiceValue(dimension, selectedDimensions);
          break;
        case DimensionType.LOCATION:
          validateLocationValue(dimension, selectedDimensions);
          break;
      }
    });
  } catch (error) {
    return false;
  }

  return true;
};

const PlayerWrapper = (props: Props) => {
  const {
    product,
    dimensions,
    selectedDimensions,
    playerSelectedDimension,
    playerSelectedDimensionOption,
    onSelectedDimensionsChange,
    onPlayerDimensionChange,
    onPlayerDimensionOptionChange
  } = props;
  const classes = useStyles();
  const $playerWrapper = useRef<HTMLElement>(null);
  const playerWrapperHeight = usePlayerWrapperHeight(product, selectedDimensions, $playerWrapper);
  const { notebook, script, legend_open } = product.options;

  if (
    !playerSelectedDimension ||
    !playerSelectedDimensionOption
  ) {
    return null;
  }

  return (
    <Paper className={classes.playerWrapper} ref={$playerWrapper}
      style={{ height: playerWrapperHeight }}>
      {
        validateProductValues(dimensions, selectedDimensions) ?
          (
            <Player
              packageName={product.package.name}
              productName={product.name}
              productTitle={product.title}
              dashboardShare={Boolean(product.dashboard)}
              notebook={notebook}
              script={script}
              licence={product.licence}
              productValues={selectedDimensions}
              onProductValuesChange={onSelectedDimensionsChange}
              dimensions={dimensions}
              selectedDimension={playerSelectedDimension}
              onDimensionChange={onPlayerDimensionChange}
              selectedDimensionOption={playerSelectedDimensionOption}
              onDimensionOptionChange={onPlayerDimensionOptionChange}
              displayLegendOnMount={legend_open}
            />
          ) : (
            <PageError
              title="We're sorry. It seems we do not provide data for selected parameters."
              description={
                <Fragment>
                  Please select parameters or click&nbsp;
                  <Link to={paths.productDetails(product.package.name, product.name)} replace>here</Link>
                  &nbsp;to load most recent plot.
                </Fragment>
              }
            />
          )
      }
    </Paper>
  );
};

export default PlayerWrapper;
