import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {},
  listSubheader: {
    padding: 0,
    lineHeight: 2
  },
  listItem: {
    padding: 0
  },
  listItemIcon: {
    minWidth: theme.spacing(4)
  }
});
