import { Theme, createStyles } from '@material-ui/core';
import { TRACK_HEIGHT, THUMB_HEIGHT } from '../constants';

export default (theme: Theme) => createStyles({
  track: {
    backgroundColor: theme.palette.secondary.main,
    height: TRACK_HEIGHT,
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none'
  },
  trackHotspot: {
    height: THUMB_HEIGHT,
    top: THUMB_HEIGHT * -0.5,
    position: 'absolute',
    cursor: 'pointer'
  }
});
