import React, { ChangeEvent, Component } from 'react';
import { Box, Checkbox, TextField, FormControl, FormGroup, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import decorate from './decorator';

export type TEmbedSize = { width: number, height: number };
export type TEmbedOptionValues = {
  width: number,
  height: number,
  controls_overlay: boolean,
  display_legend: boolean,
  most_recent: boolean,
  aspectRatioLocked: boolean
};

export type TProps = {
  values: TEmbedOptionValues
  onChange: (values: TEmbedOptionValues) => void,
  classes: {
    root: string,
    sizeFieldsGroup: string
  }
}

class EmbedOptionsForm extends Component<TProps> {
  onSizeChange = (param: 'width' | 'height') => (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange, values } = this.props;

    onChange({
      ...values,
      [param]: parseInt(e.target.value, 10)
    });
  };

  onCheckboxChange = (param: 'controls_overlay' | 'most_recent' | 'display_legend') => (e: ChangeEvent<{}>, checked: boolean) => {
    const { onChange, values } = this.props;

    onChange({
      ...values,
      [param]: checked
    });
  };

  onAspectRatioLockChange = (locked: boolean) => () => {
    const { onChange, values } = this.props;

    onChange({
      ...values,
      aspectRatioLocked: locked
    });
  };

  render() {
    const { values, classes } = this.props;
    const { width, height, controls_overlay, most_recent, display_legend, aspectRatioLocked } = values;

    return (
      <Box className={classes.root}>
        <FormControl component="fieldset">
          <FormGroup aria-label="embed-size" row className={classes.sizeFieldsGroup}>
            <TextField
              id="embed-width"
              type="number"
              label="Width"
              value={width || 0}
              onChange={this.onSizeChange('width')}
              variant="outlined"
            />

            <Box display="flex" alignContent="center" margin={1}>
              <ClearIcon />
            </Box>

            <TextField
              id="embed-height"
              type="number"
              label="Height"
              value={height || 0}
              onChange={this.onSizeChange('height')}
              variant="outlined"
            />

            <Box flexShrink={0}>
              {
                aspectRatioLocked ?
                  <IconButton
                    title="Unlock aspect ratio"
                    onClick={this.onAspectRatioLockChange(false)}
                  >
                    <LockIcon />
                  </IconButton>
                  :
                  <IconButton
                    title="Lock aspect ratio"
                    onClick={this.onAspectRatioLockChange(true)}
                  >
                    <LockOpenIcon />
                  </IconButton>
              }
            </Box>
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              value="controls_overlay"
              control={<Checkbox color="primary" />}
              label="Control Overlay"
              labelPlacement="end"
              checked={controls_overlay}
              onChange={this.onCheckboxChange('controls_overlay')}
            />

            <FormControlLabel
              value="display_legend"
              control={<Checkbox color="primary" />}
              label="Display legend"
              labelPlacement="end"
              checked={display_legend}
              onChange={this.onCheckboxChange('display_legend')}
            />

            <FormControlLabel
              value="most_recent"
              control={<Checkbox color="primary" />}
              label="Show most recent"
              labelPlacement="end"
              checked={most_recent}
              onChange={this.onCheckboxChange('most_recent')}
            />
          </FormGroup>
        </FormControl>
      </Box>
    );
  }
}

export default decorate(EmbedOptionsForm);
