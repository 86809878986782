import { createStyles, Theme } from '@material-ui/core';
import { PlayerZIndex } from '../styles';

export default (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    width: 'auto',
    height: 'calc(100% - 52px)',
    maxHeight: '100%',
    bottom: 52,
    right: 0,
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: PlayerZIndex.CONTROLS - 1,
    transition: 'bottom, height 0.3s linear',
    overflowY: 'auto',

    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },

  rootOverlayMode: {
    bottom: 0,
    height: '100%'
  },
  rootHidden: {
    height: 0
  },
  content: {
    padding: theme.spacing(2, 6, 2, 3)
  },
  contentOverlayMode: {
    paddingBottom: theme.spacing(2) + 52
  },
  dimensionsForm: {
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});
