import { connect } from 'react-redux';
import { ComponentType } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connectThunkAction } from '../../../utils/ReduxHelpers';
import { fetchProductData } from '../../../actions/productData';
import { IProps } from './index';
import * as StoreIndex from '../../../utils/storeIndex';
import { TAppState } from '../../../store';
import deriveDimensionsFromAxis from '../../../utils/deriveDimensionsFromAxis';

const mapStateToProps = (state: TAppState, props: IProps) => {
  const { product, selectedDimensions } = props;

  const packageName = product.package.name;
  const productName = product.name;

  const { selected } = deriveDimensionsFromAxis(product.axis, selectedDimensions as Record<string, string>);

  const key = StoreIndex.productData(packageName, productName, {
    ...selected
  });

  const item = state.productData[key];

  return {
    item
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
  fetchProductData: connectThunkAction(dispatch, fetchProductData)
});

// @ts-ignore
export default (component: ComponentType<IProps>): ComponentType<Omit<IProps, 'fetchProductData'>> => connect(mapStateToProps, mapDispatchToProps)(component);
