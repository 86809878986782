import { Link, LinkProps, SvgIcon } from '@material-ui/core';
import React from 'react';

interface IProps extends LinkProps {
  icon: typeof SvgIcon
}

const IconLink = (props: IProps) => {
  const { icon: Icon, children, ...otherLinkProps } = props;

  return (
    <Link {...otherLinkProps} style={{ display: 'inline-flex', alignItems: 'center' }}>
      <Icon fontSize="inherit" style={{ marginRight: '0.4em' }} />
      {children}
    </Link>
  );
};

export default IconLink;
