import { TChoiceAxis, TCycloneAxis, TLocationAxis, TMultipleChoiceAxis, TProductAxis } from '../api/axis';
import { find, pick } from 'lodash';
import { Dimension, DimensionChoice, DimensionSelectionMapping, DimensionType } from '../containers/Product';

export type DimensionSet = {
  dimensions: Array<Dimension>,
  selected: DimensionSelectionMapping
}

export type TSearchParams = {
  [key: string]: string
}

const getLinkedAxis = (axis: TChoiceAxis, parentAxisValue: string | number): Dimension | void => {
  const currentAxisValue = find(axis.values, obj => obj.value === parentAxisValue);

  if (!currentAxisValue || !currentAxisValue.linked_axis) {
    return;
  }

  return {
    name: currentAxisValue.linked_axis,
    animation: currentAxisValue.animation ?? false,
    label: axis.linked_axis_title!,
    type: axis.type,
    dynamic: axis.dynamic ?? false,
    values: currentAxisValue.linked_values!
  };
};

// backend hack workaround: skip base time axis if there is only one base time with one valid time
const shouldSkipBaseTimeAxis = (axis: TChoiceAxis) => {
  return axis.name === 'base_time' &&
    axis.values.length <= 1 &&
    axis.values[0].linked_values &&
    axis.values[0].linked_values.length <= 1;
};

const deriveChoiceAxis = (
  axis: TChoiceAxis,
  defaults: TSearchParams
): [Array<Dimension>, DimensionSelectionMapping] => {
  const dimensions: Array<Dimension> = [];
  const selected: DimensionSelectionMapping = {};

  if (shouldSkipBaseTimeAxis(axis)) {
    return [dimensions, selected];
  }

  dimensions.push({
    type: DimensionType.CHOICE,
    name: axis.name,
    label: axis.title,
    animation: axis.animation,
    dynamic: axis.dynamic ?? false,
    values: axis.values.map(v => ({ value: v.value, label: v.label }))
  });

  const value = axis.values.find(v => v.value === defaults[axis.name])?.value;

  selected[axis.name] = value ?? axis.values[0].value;

  const linkedAxis = getLinkedAxis(axis, selected[axis.name]) as DimensionChoice;

  if (linkedAxis) {
    dimensions.push(linkedAxis);

    const selectedValue = find(linkedAxis.values, (v) => v.value === defaults[linkedAxis.name]);

    selected[linkedAxis.name] = (selectedValue && selectedValue.value) || linkedAxis.values[0].value;
  }

  return [dimensions, selected];
};

const deriveLocationAxis = (
  axis: TLocationAxis,
  defaults: TSearchParams
): [Array<Dimension>, DimensionSelectionMapping] => {
  const dimensions: Array<Dimension> = [];
  let selected: DimensionSelectionMapping = {};

  dimensions.push({
    type: DimensionType.LOCATION,
    name: axis.name,
    label: axis.title,
    dynamic: axis.dynamic ?? false
  });

  if (defaults.lat && defaults.lon) {
    selected = pick(defaults, 'lat', 'lon', 'station_name');
  } else {
    selected = {
      lat: '51.4333',
      lon: '-1.0',
      station_name: 'Reading'
    };
  }

  return [dimensions, selected];
};

const deriveCycloneAxis = (
  axis: TCycloneAxis,
  defaults: TSearchParams
): [Array<Dimension>, DimensionSelectionMapping] => {
  const dimensions: Array<Dimension> = [];
  const selected: DimensionSelectionMapping = {};

  dimensions.push({
    type: DimensionType.CYCLONE,
    name: axis.name,
    label: axis.title,
    dynamic: axis.dynamic ?? false,
    values: axis.values.map(v => ({ value: v.value, label: v.label })),
    overview: axis.overview
  });

  selected[axis.name] = defaults[axis.name] || axis.values[0].value;

  return [dimensions, selected];
};

const deriveMultipleChoiceAxis = (
  axis: TMultipleChoiceAxis,
  defaults: TSearchParams
): [Array<Dimension>, DimensionSelectionMapping] => {
  const dimensions: Array<Dimension> = [];
  const selected: DimensionSelectionMapping = {};

  dimensions.push({
    type: DimensionType.MULTIPLE_CHOICE,
    name: axis.name,
    label: axis.title,
    dynamic: axis.dynamic ?? false,
    values: axis.values
  });

  const allValues = axis.values.map(v => v.value);

  selected[axis.name] = defaults[axis.name] || allValues.join(',');

  return [dimensions, selected];
};

const deriveDimensionsFromAxis = (
  axes: Array<TProductAxis>,
  defaults: TSearchParams,
  includeDynamic: boolean = true
): DimensionSet => {
  let selected: DimensionSelectionMapping = {};

  const dimensions = axes.reduce((acc: Array<Dimension>, axis) => {
    if (axis.dynamic && !includeDynamic) {
      return acc;
    }

    if (axis.type === 'choice') {
      const [derivedDimensions, derivedSelected] = deriveChoiceAxis(axis, defaults);

      acc.push(...derivedDimensions);

      selected = {
        ...selected,
        ...derivedSelected
      };
    } else if (axis.type === 'location') {
      const [derivedDimensions, derivedSelected] = deriveLocationAxis(axis, defaults);

      acc.push(...derivedDimensions);

      selected = {
        ...selected,
        ...derivedSelected
      };
    } else if (axis.type === 'cyclone') {
      const [derivedDimensions, derivedSelected] = deriveCycloneAxis(axis, defaults);

      acc.push(...derivedDimensions);

      selected = {
        ...selected,
        ...derivedSelected
      };
    } else if (axis.type === 'multiple-choice') {
      const [derivedDimensions, derivedSelected] = deriveMultipleChoiceAxis(axis, defaults);

      acc.push(...derivedDimensions);

      selected = {
        ...selected,
        ...derivedSelected
      };
    }

    return acc;
  }, []);

  return {
    dimensions,
    selected
  };
};

export default deriveDimensionsFromAxis;
