import { TProduct } from '../../../../api/product';
import { Box, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import GithubIcon from '../../../../components/Icons/Github';
import ExternalLink from '../../../../components/ExternalLink';
import MyBinderIcon from '../../../../components/Icons/MyBinder';
import ColabIcon from '../../../../components/Icons/Colab';
import JupyterColoredIcon from '../../../../components/Icons/JupyterColored';

interface Props {
  product: TProduct
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1
  },
  jupiterIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  notebook: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  notebookTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  notebookLink: {
    margin: '0 0.3em',
    display: 'inline-flex'
  },
  notebookIcon: {
    width: 'auto',
    height: '0.7em'
  }
}));

const ProductFeatures = (props: Props) => {
  const { product } = props;
  const classes = useStyles();
  const { notebooks_urls } = product.options;

  return (
    <Box className={classes.root}>
      <Box className={classes.notebook}>
        <JupyterColoredIcon fontSize="large" className={classes.jupiterIcon} />

        {
          notebooks_urls &&
          <Fragment>
            {
              notebooks_urls.github &&
              <ExternalLink href={notebooks_urls.github} className={classes.notebookLink} title="Open in GitHub">
                <GithubIcon className={classes.notebookIcon} />
              </ExternalLink>
            }

            {
              notebooks_urls.binder &&
              <ExternalLink href={notebooks_urls.binder} className={classes.notebookLink} title="Open in Binder">
                <MyBinderIcon className={classes.notebookIcon} />
              </ExternalLink>
            }

            {
              notebooks_urls.colab &&
              <ExternalLink href={notebooks_urls.colab} className={classes.notebookLink} title="Open in Colab">
                <ColabIcon className={classes.notebookIcon} />
              </ExternalLink>
            }
          </Fragment>
        }
      </Box>
    </Box>
  );
};

export default ProductFeatures;
