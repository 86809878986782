import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { IProductListResponse, TProduct, TSubpackage } from '../../../api/product';
import { TreeView } from '@material-ui/lab';
import * as QueryString from '../../../utils/queryString';
import { compact } from 'lodash';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import ProductCard from '../ProductCard';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import decorate from './decorator';
import TreeItem from './TreeItem';

type TNodeIds = Array<string>;

export interface IProps extends RouteComponentProps<{ name: string }> {
  packageItem: IProductListResponse;
  classes: {
    root: string;
    treeWrapper: string;
    content: string;
  }
}

interface IState {
  expanded: TNodeIds;
  selected: string | undefined;
  tree: Array<TRenderTree>;
}

interface TRenderTree {
  title: string;
  name: string;
  children?: Array<TRenderTree>;
}

const belongsToPackage = (product: TProduct, subpackageName: TSubpackage['name']) => {
  const path = [...product.path];

  while (path.length) {
    if (path.join('_') === subpackageName) {
      return true;
    }

    path.pop();
  }

  return false;
};

const nest = (items: Array<TSubpackage>, currentItemName?: string): Array<TRenderTree> =>
  items
    .filter(item => {
      return item.path.join('_') === currentItemName;
    })
    .map(item => ({ ...item, children: nest(items, item.name) }));


const renderTreeItem = (node: TRenderTree) => {
  // const labelInfo = node.children ? node.children.length.toString() : undefined;

  return (
    <TreeItem key={node.name} nodeId={node.name} labelText={node.title}>
      {Array.isArray(node.children) ? node.children.map((node) => renderTreeItem(node)) : null}
    </TreeItem>
  );
};

class PackageTreeView extends Component<IProps, IState> {
  state: IState = {
    expanded: [],
    selected: '',
    tree: nest(this.props.packageItem.subpackages, this.props.packageItem.name)
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { location } = props;
    const queryParams = QueryString.parse(location.search);

    const expanded = Array.isArray(queryParams.expanded) ? queryParams.expanded : compact([queryParams.expanded]);

    return {
      ...state,
      selected: queryParams.selected ?? '',
      expanded: expanded
    };
  }

  handleToggle = (event: React.ChangeEvent<{}>, nodeIds: TNodeIds) => {
    setTimeout(() => {
      this.updateUrl({
        expanded: nodeIds
      });
    });
  }

  handleSelect = (event: React.ChangeEvent<{}>, nodeId?: string) => {
    this.updateUrl({
      selected: nodeId ?? ''
    });
  }

  updateUrl(queryParams: {
    selected?: string;
    expanded?: TNodeIds;
  }) {
    const { history, location } = this.props;
    const currentQueryParams = QueryString.parse(location.search);
    const { selected, expanded } = queryParams;

    const newParams = {
      ...currentQueryParams,
      ...(selected && { selected }),
      ...(expanded && { expanded })
    };

    history.replace(location.pathname + '?' + QueryString.stringify(newParams));
  }

  renderTree() {
    const { tree } = this.state;

    return tree.map(node => renderTreeItem(node));
  }

  renderProduct(product: TProduct) {
    const { packageItem } = this.props;
    const packageName = packageItem!.name;

    return (
      <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={`${packageName}-${product.name}`}>
        <ProductCard packageName={packageName} product={product} />
      </Grid>
    );
  }

  renderProducts() {
    const { selected } = this.state;
    const { packageItem } = this.props;
    const { products } = packageItem;

    if (!selected) {
      return (
        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
          <Typography component="div">
            Please select a package to browse associated products.
          </Typography>
        </Box>
      );
    }

    const filtered = selected ? products.filter(product => belongsToPackage(product, selected)) : [];

    if (filtered.length === 0) {
      return (
        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
          <Typography component="div">
            No products found for selected criteria.
          </Typography>
        </Box>
      );
    }

    return filtered.map(product => this.renderProduct(product));
  }

  render() {
    const { classes } = this.props;
    const { expanded, selected } = this.state;

    return (
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Paper className={classes.treeWrapper}>
            <Typography variant="h4" gutterBottom style={{ marginBottom: 24 }}>Subpackages</Typography>

            <TreeView
              multiSelect={false}
              className={classes.root}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={this.handleToggle}
              onNodeSelect={this.handleSelect}
            >
              {
                this.renderTree()
              }
            </TreeView>
          </Paper>
        </Grid>

        <Grid item xs={9}>
          <Grid container spacing={2}>
            {
              this.renderProducts()
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default decorate(PackageTreeView);
