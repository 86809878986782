import React, { Component, Fragment } from 'react';
import {
  Box, Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  Typography,
  WithStyles
} from '@material-ui/core';
import decorate from './decorator';
import styles from './styles';
import { DimensionSelectionMapping } from '../../../containers/Product';
import { TProductExportSpec } from '../../../api/product';
import Api from '../../../api';
import ExternalLink from '../../ExternalLink';
import JupyterIcon from '../../Icons/Jupyter';
import GithubIcon from '../../Icons/Github';
import MyBinderIcon from '../../Icons/MyBinder';
import ColabIcon from '../../Icons/Colab';
import IconMenuItem from '../../IconMenuItem';
import theme from '../../../theme';
import JupyterColoredIcon from '../../Icons/JupyterColored';

export interface IProps extends WithStyles<typeof styles> {
  packageName: string;
  productName: string;
  dimensionsSelection: DimensionSelectionMapping;
  onStart?: () => void;
  onError?: () => void;
  onComplete?: () => void;
  onDialogClose?: () => void;
}

interface IState {
  isDialogOpen: boolean;
  exportSpec: {
    url: string;
    swaggerUrl: string;
    notebook?: {
      binder: string;
      github: string;
      colab: string;
    };
  } | null;
}

class NotebookButton extends Component<IProps, IState> {
  state: IState = {
    isDialogOpen: false,
    exportSpec: null
  };

  getExportSpec = async (): Promise<TProductExportSpec> => {
    const { packageName, productName, dimensionsSelection } = this.props;

    return Api.Product.exportSpec(packageName, productName, dimensionsSelection);
  }

  onOpen = () => {
    const { onStart, onError, onComplete } = this.props;

    onStart && onStart();

    this.getExportSpec()
      .then((exportSpec) => {
        this.setState({
          exportSpec: {
            url: exportSpec.wget,
            swaggerUrl: exportSpec.swagger,
            notebook: exportSpec.notebook
          },
          isDialogOpen: true
        }, () => {
          onComplete && onComplete();
        });
      })
      .catch(() => {
        onError && onError();
      });
  }

  onClose = () => {
    const { onDialogClose } = this.props;

    this.setState({
      isDialogOpen: false
    }, () => {
      onDialogClose && onDialogClose();
    });
  }

  renderDialogContent() {
    const { classes } = this.props;
    const { exportSpec } = this.state;

    if (!exportSpec?.notebook) {
      return null;
    }

    return (
      <Box>
        <Typography variant="body2" className={classes.notebookContent}>
          A notebook describing processing and visualisation is available for this chart.
          You can find the source on &nbsp;
          <ExternalLink href={exportSpec.notebook.github} title="Open in GitHub"><GithubIcon
            className={classes.notebookServiceIcon} /></ExternalLink>,
          interact using&nbsp;
          <ExternalLink href={exportSpec.notebook.binder} title="Open in Binder"><MyBinderIcon
            className={classes.notebookServiceIcon} /></ExternalLink>
          &nbsp;or <ExternalLink href={exportSpec.notebook.colab} title="Open in Colab"><ColabIcon
            className={classes.notebookServiceIcon} /></ExternalLink>.
        </Typography>
      </Box>
    );
  }


  render() {
    const { isDialogOpen } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <IconMenuItem onClick={this.onOpen} icon={<JupyterIcon />}>
          Notebook
        </IconMenuItem>

        <ThemeProvider theme={theme(false, 'light')}>
          <Dialog open={isDialogOpen} onClose={this.onClose}>
            <DialogTitle disableTypography>
              <Typography component="h2" variant="h6" className={classes.notebookHeader}>
                <JupyterColoredIcon className={classes.jupiterIcon} />
                Notebook
              </Typography>
            </DialogTitle>

            <DialogContent>
              {
                this.renderDialogContent()
              }
            </DialogContent>

            <DialogActions>
              <Button onClick={this.onClose} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default decorate(NotebookButton);
