import SearchInput from '../../../../components/SearchInput';
import React, { ChangeEvent } from 'react';
import { includes, map } from 'lodash';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Paper, WithStyles } from '@material-ui/core';
import { IPackageFiltersForm } from '../index';
import decorate from './decorator';
import styles from './styles';

export interface IProps extends IPackageFiltersForm, WithStyles<typeof styles> {
}

class FiltersLargeScreen extends React.Component<IProps> {
  handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  };

  handleSearchValueReset = () => {
    this.props.onChange('');
  };

  handleFacetChange = (facet: string, value: string) => (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onFacetChange(facet, value, event.target.checked);
  };

  render() {
    const { searchValue, facets, selectedFacets, classes } = this.props;

    return (
      <Paper className={classes.root}>
        <SearchInput
          name="search"
          value={searchValue}
          onChange={this.handleSearchValueChange}
          onReset={this.handleSearchValueReset}
          placeholder="Search products..."
          fullWidth
        />

        {
          map(facets, (values, facetKey) => {
            return (
              <FormGroup key={facetKey} className={classes.filter}>
                <FormLabel component="legend" className={classes.facetLabel}>{facetKey}</FormLabel>

                <FormGroup>
                  {
                    map(values, value => {
                      const isSelected = includes(selectedFacets[facetKey], value);

                      return (
                        <FormControlLabel
                          key={value}
                          control={
                            <Checkbox
                              checked={isSelected}
                              onChange={this.handleFacetChange(facetKey, value)}
                              value={value}
                            />
                          }
                          label={value}
                        />
                      );
                    })
                  }
                </FormGroup>
              </FormGroup>
            );
          })
        }

      </Paper>
    );
  }
}

export default decorate(FiltersLargeScreen);
