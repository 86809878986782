import { TProductDetails } from '../../api/product';
import deriveDimensionsFromAxis, { DimensionSet } from '../deriveDimensionsFromAxis';
import { uniqBy, partition } from 'lodash';

const deriveDimensionsFromProducts = (
  products: Array<TProductDetails>,
  dimensions: Record<string, string>
) => {
  const counters: Record<string, number> = {};

  const set = products.reduce((acc, product) => {
    const set = deriveDimensionsFromAxis(product.axis, dimensions);

    set.dimensions.forEach(dimension => {
      counters[dimension.name] = (counters[dimension.name] || 0) + 1;
    });

    acc = {
      dimensions: uniqBy(acc.dimensions.concat(set.dimensions), 'name'),
      selected: {
        ...acc.selected,
        ...set.selected
      }
    };

    return acc;
  }, { dimensions: [], selected: {} } as DimensionSet);

  const [common, specific] = partition(set.dimensions, (dimension => counters[dimension.name] === products.length));

  return {
    selected: set.selected,
    common,
    specific
  };
};

export default deriveDimensionsFromProducts;
