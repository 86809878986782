import { DimensionSelectionMapping } from '../containers/Product';
import { TProductDataItem } from "../reducers/productData";
import { store } from '../store';
import * as StoreIndex from './storeIndex';

const getProductDataItemFromStore = (
  packageName: string,
  productName: string,
  selectedDimensions: DimensionSelectionMapping
): TProductDataItem | undefined => {
  const { productData } = store.getState();

  const index = StoreIndex.productData(packageName, productName, selectedDimensions);

  return productData[index];
};

export default getProductDataItemFromStore;
