import client from './client';

export type TGroup = {
  name: string,
  products: Array<{
    thumbnail: string;
    title: string;
    subtitle: string;
    name: string;
    package: string;
  }>
}

const fetch = (packageName: string, groupName: string): Promise<TGroup> =>
  client.get(`/packages/${packageName}/groups/${groupName}/`).then(response => response.data);

export {
  fetch
};
