import React, { Component, ComponentType } from 'react';
import decorate from './decorator';
import FiltersLargeScreen from './FiltersLargeScreen';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import FiltersSmallScreen from './FiltersSmallScreen';

type TFacets = {
  [id: string]: Array<string>
}

export interface IPackageFiltersForm {
  searchValue: string,
  onChange: (value: string) => void,
  facets: TFacets,
  selectedFacets: TFacets,
  onFacetChange: (facetId: string, changedValue: string, isSelected: boolean) => void,
}

export interface IProps extends IPackageFiltersForm {
  width: Breakpoint
}

const components: Record<Breakpoint, ComponentType<IPackageFiltersForm>> = {
  xs: FiltersSmallScreen,
  sm: FiltersSmallScreen,
  md: FiltersLargeScreen,
  lg: FiltersLargeScreen,
  xl: FiltersLargeScreen
};

class Filters extends Component<IProps> {
  render() {
    const { searchValue, facets, selectedFacets, onChange, onFacetChange, width } = this.props;

    const Component = components[width];

    return (
      <Component
        searchValue={searchValue}
        facets={facets}
        selectedFacets={selectedFacets}
        onChange={onChange}
        onFacetChange={onFacetChange}
      />
    );
  }
}

export default decorate(Filters);
