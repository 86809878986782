import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {
    color: theme.palette.link.main,
    textDecoration: 'none'
  }
});

export default styles;
