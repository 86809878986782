import { IProductListResponse } from '../api/product';
import {
  PRODUCT_LIST_FETCH_STARTED,
  PRODUCT_LIST_FETCH_COMPLETED,
  PRODUCT_LIST_FETCH_FAILED,
  IProductListFetchStartAction,
  IProductListFetchCompleteAction,
  IProductListFetchFailAction
} from '../actions/product';

type TProductsByPackageSubState = {
  loading: boolean,
  loadedAt: null | number,
  error: null | string,
  data: IProductListResponse | null
}

type TProductsByPackageState = {
  [packageName: string]: TProductsByPackageSubState | undefined
};

type TAllowedActions =
  IProductListFetchStartAction |
  IProductListFetchCompleteAction |
  IProductListFetchFailAction;

const getInitialSubState = (): TProductsByPackageSubState => ({
  loading: false,
  loadedAt: null,
  error: null,
  data: null
});

const initialState = {};

export default (state: TProductsByPackageState = initialState, action: TAllowedActions): TProductsByPackageState => {
  switch (action.type) {
    case PRODUCT_LIST_FETCH_STARTED: {
      const newState = state[action.packageName] || getInitialSubState();

      return {
        ...state,
        [action.packageName]: { ...newState, loading: true }
      };
    }

    case PRODUCT_LIST_FETCH_COMPLETED:
      return {
        ...state,
        [action.packageName]: {
          ...state[action.packageName],
          data: action.data,
          loading: false,
          loadedAt: Date.now(),
          error: null
        }
      };

    case PRODUCT_LIST_FETCH_FAILED:
      return {
        ...state,
        [action.packageName]: {
          ...(state[action.packageName] || getInitialSubState()),
          error: action.error.message,
          loading: false
        }
      };

    default:
      return state;
  }
};
