import copy from 'clipboard-copy';


const copyToClipboard = (value: string) => {
  return copy(value);
};

export {
  copyToClipboard
};
