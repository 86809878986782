import { Dimension } from "../containers/Product";
import objectToIndex from './objectToIndex';
import { kebabCase } from 'lodash';


const productByPackageKey = (packageName: string, productName: string) => [packageName, productName].join('::');

const productDetails = (packageName: string, productName: string) => {
  return productByPackageKey(packageName, productName);
};

const productData = (
  packageName: string,
  productName: string,
  params: { [key: string]: string | number }
) => {
  return [productByPackageKey(packageName, productName), objectToIndex(params)].join('::');
};

const recentLocation = (packageName: string, productName: string) => {
  return productByPackageKey(packageName, productName);
};

const recentCyclone = (packageName: string, productName: string) => {
  return productByPackageKey(packageName, productName);
};

const group = (packageName: string, groupName: string) => [packageName, kebabCase(groupName)].join('::');

export {
  group,
  productData,
  productDetails,
  recentCyclone,
  recentLocation
};
